import React from "react";
import { Grid, Typography, withStyles } from "@material-ui/core";
import styles from "../../consent/styles/ConsentStyles";
import * as constants from "../../../constants";
import PropTypes from "prop-types";
import LogoutButton from "../../LogoutButton";

const UnverifiedUpload = ({ classes }) => {

  return (
    <Grid
      data-testid="consent-verify-in-person"
      container
      direction="column"
      alignItems="flex-start"
    >
      <Grid item>
        <Typography variant="body2" data-testid="verify-in-person-description">
          {constants.IDENTITY_UNVERIFIED}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={constants.CONTACT_US_LINK}>
            {constants.CONTACT_US}
          </a>
          {constants.FOR_FURTHER_ASSISTANCE}
          {constants.VISIT_FREQUENTLY_ASKED_QUESTIONS}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={constants.FREQUENTLY_ASKED_QUESTIONS_LINK}>
            {constants.FREQUENTLY_ASKED_QUESTIONS}
          </a>
        </Typography>
      </Grid>
      <br />
      <LogoutButton />
    </Grid>
  );
};

UnverifiedUpload.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(UnverifiedUpload);
