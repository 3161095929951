export const HELP_UPLOADING_PHOTOS_DESCRIPTION =
  "When prompted on the screen, upload a photo by dragging and dropping into the dotted area or select “upload”.";
export const UPLOAD_MEDIUM_TEXT =
  "You can use a phone or a computer to upload a photo.";
export const PHONE_OR_COMPUTER_UPLOAD_HELP_INSTRUCTIONS = [
  "If you took the photo on your phone, but are completing this form on a computer, email the photo to yourself and then download the photo from your email on your computer.",
  "If you took the photo on your phone and are completing this form on your phone, use the browse window to locate the photo you wish to upload.",
  "If you took the photo on computer, use the browse window to locate the photo you wish to upload.",
];
export const WHEN_UPLOAD_PHOTO_ID = "How to take a photo of your ID: ";
export const UPLOAD_PHOTO_ID_INSTRUCTIONS = [
  "Place the ID in your hand or on a contrasting background to take the photo",
  "Ensure the photo is not blurry and is clearly visible",
  "Eliminate any glare, smudges, or shadow on the ID",
  "Ensure all four (4) corners are visible",
  "Take the photo in a well-lit area",
];
export const WHEN_UPLOAD_PHOTO_OF_FACE = "How to take a photo of your face:";
export const UPLOAD_PHOTO_OF_FACE_INSTRUCTIONS = [
  "Have a plain, single-colored wall as a background",
  "Face the camera directly",
  "Have the camera at eye level",
  "Ensure the area is well lit with no backlight",
  "Avoid obstructing your face with hair, hats, or sunglasses",
];
export const DIFFERENT_WEB_BROWSER_LINK = "https://www.whatismybrowser.com/guides/how-to-try-a-different-web-browser";
export const DIFFERENT_WEB_BROWSER = "different web browser. ";

export const IF_UPLOAD_FAILS =
  "If your upload fails, try taking the photos again or upload using a ";

export const CONTACT_US_LINK = "https://oklahoma.gov/oesc/about/contact-oesc.html";
export const TROUBLESHOOTING_UPLOAD_ID = 
  "If you still have issues uploading your ID or photo after these options, ";