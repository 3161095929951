import * as constants from "../../constants";
import {postRequest} from "./api";

export function logMessage (source, logLevel, message) {
  return async () => {
    const requestBody = {
      source: source,
      logLevel: logLevel,
      logMessage: message,
    };
    return await postRequest(constants.API_LOG_URL, requestBody, constants.API_CONTENT_APPLICATION_JSON).catch((error) => {//NOSONAR
      console.log("Failed to log message: ", error);
    });
  }
}
