import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import PreviewPhoto from "./PreviewPhoto";
import UploadPhoto from "./UploadPhoto";

const PhotoSection = ({
  sectionInstructions,
  uploadHeader,
  uploadIcon,
  photoStateSelector,
  photoErrorStateSelector,
  setPhotosAction,
  setPhotoUploadErrorAction,
  idType,
  showLivenessSelection,
  showPhotoSelection
}) => {
  const photos = useSelector(photoStateSelector);

  return (
    <Grid item container>
      <Grid item xs={12} md={7} lg={8}>
        {sectionInstructions}
      </Grid>
      <Grid item xs={12} md={5} lg={4} data-testid="photo-upload-front-section">
        {photos.file ? (
          <PreviewPhoto
            uploadHeader={uploadHeader}
            photoStateSelector={photoStateSelector}
            setPhotosAction={setPhotosAction}
            idType={idType}
          />
        ) : (
          <UploadPhoto
            photoErrorStateSelector={photoErrorStateSelector}
            setPhotosAction={setPhotosAction}
            setPhotoUploadErrorAction={setPhotoUploadErrorAction}
            uploadIcon={uploadIcon}
            uploadHeader={uploadHeader}
            idType={idType}
            showLivenessSelection={showLivenessSelection}
            showPhotoSelection={showPhotoSelection}
          />
        )}
      </Grid>
    </Grid>
  );
};

PhotoSection.propTypes = {
  sectionInstructions: PropTypes.element.isRequired,
  uploadHeader: PropTypes.string.isRequired,
  uploadIcon: PropTypes.string.isRequired,
  photoStateSelector: PropTypes.func.isRequired,
  photoErrorStateSelector: PropTypes.func.isRequired,
  setPhotosAction: PropTypes.func.isRequired,
  setPhotoUploadErrorAction: PropTypes.func.isRequired,
  showLivenessSelection: PropTypes.bool,
  showPhotoSelection: PropTypes.bool.isRequired,
};

export default PhotoSection;
