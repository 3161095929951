export const API_CONSENT_URL = "/consent";
export const API_CONFIGURATION_URL = "/configuration";
export const API_EVIDENCE_URL = "/evidence";
export const API_INIT_LIVE_SESSION_URL = "/init-live-session";
export const API_GET_LIVE_CAPTURE_RESULT = "/live-capture-result";
export const API_PROCESS_LIVE_CAPTURE_BEST_PORTRAIT = "/live-capture/best-portrait";
export const API_DOCUMENT_UPLOAD_URL = "/documents";
export const API_PORTRAIT_UPLOAD_URL = "/portrait";
export const API_POLLING_URL = `documents/${1}/status/${2}`;
export const API_CONTENT_APPLICATION_JSON = "application/json";
export const API_CONTENT_MULTIPART_FORM_DATA = "multipart/form-data";
export const API_LOG_URL = "/log";
