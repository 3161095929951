import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { PORTRAIT_UPLOAD_INSTRUCTIONS_HEADER, PORTRAIT_UPLOAD_INSTRUCTIONS_CONTENT } from "../../constants";
import PortraitExample from "./assets/PortraitExample.svg";
import styles from "./styles/PortraitUploadInstructionsStyles";

const PortraitUploadInstructions = () => {
  const classes = styles();

  return (
    <Grid item container spacing={2} direction="column">
      <Grid item>
        <Typography>
          <b>{PORTRAIT_UPLOAD_INSTRUCTIONS_HEADER}</b>
        </Typography>
      </Grid>
      <Grid item container>
        <Grid item xs={5} md={3} lg={2}>
          <img src={PortraitExample} alt="portrait example" />
        </Grid>
        <Grid item xs={7} md={9} lg={10}>
          <Typography className={classes.instructions}>
            {PORTRAIT_UPLOAD_INSTRUCTIONS_CONTENT}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PortraitUploadInstructions;
