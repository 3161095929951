import React from "react";
import { useSelector } from "react-redux";
import {
  POLLING_LIVE_CAPTURE_CHALLENGE_FAILED,
  POLLING_LIVE_CAPTURE_CHALLENGE_SUCCESSFUL,
  POLLING_LIVE_CAPTURE_PORTRAIT,
  GET_LIVE_CAPTURE_CHALLENGE_RESULT,
  PROCESS_LIVE_CAPTURE_BEST_PORTRAIT
} from "../../store/reducers/StoreConstant";
import {
  SUCCESS_LIVE_CAPTURE_CHALLENGE_FINISHED,
  POLLING_FAILED_LIVE_CAPTURE_CHALLENGE_RESULT,
  POLLING_RETRY_LIVE_CAPTURE_CHALLENGE_RESULT,
  PORTRAIT,
  POLLING_SUCCESSFUL_DESCRIPTION_PORTRAIT_MESSAGE
} from "../../constants";
import {
  decrementActiveStepLiveCapturePortrait,
  incrementActiveStepLiveCapturePortrait,
} from "../../store/reducers/userProgress";
import { logMessage } from "../../store/api/logMessage";
import FileUploadVerification from "../commons/FileUploadVerification";
import {
  liveCaptureBeginAfterFailure,
  pollingLiveCaptureChallengeFailure,
  pollingLiveCaptureChallengeSuccess,
  setPollingStatus,
  unableToReachIdentityServerLiveCapture,
} from "../../store/reducers/userDetails";
import { getLiveCaptureChallengeResult } from "../../store/api/getLiveCaptureChallengeResult";
import config from "../../config/config";
import { pollLiveCapturePortraitStatus } from "../../store/api/pollLiveCapturePortraitStatus";
import { liveCapturePollingFailure, liveCapturePollingSuccessful } from "../commons/Validators";
import { processLiveCaptureBestPortrait } from "../../store/api/processLiveCaptureBestPortrait";

const LiveCaptureVerification = () => {
  const isLiveCaptureAttemptPending = useSelector((state)=>state.userDetails.apiData.liveCapture.isAttemptPending);

  const liveCaptureVerificationProps = {
    uploadStages: {
      uploadingFile: {
        name: "",
        title: "",
      },
      getLiveCaptureChallengeResult: {
        name: GET_LIVE_CAPTURE_CHALLENGE_RESULT,
        title: SUCCESS_LIVE_CAPTURE_CHALLENGE_FINISHED
      },
      processLiveCaptureBestPortrait: {
        name: PROCESS_LIVE_CAPTURE_BEST_PORTRAIT,
        title: SUCCESS_LIVE_CAPTURE_CHALLENGE_FINISHED
      },
      pollingUploadedFile: {
        name: POLLING_LIVE_CAPTURE_PORTRAIT,
        title: SUCCESS_LIVE_CAPTURE_CHALLENGE_FINISHED,
      },
      uploadingFileFailed: {
        name: "",
        title: "",
      },
      fileUploadVerified: {
        name: POLLING_LIVE_CAPTURE_CHALLENGE_SUCCESSFUL,
        description : POLLING_SUCCESSFUL_DESCRIPTION_PORTRAIT_MESSAGE,
      },
      pollingFailed: {
        name: POLLING_LIVE_CAPTURE_CHALLENGE_FAILED,
        title: isLiveCaptureAttemptPending
            ? POLLING_RETRY_LIVE_CAPTURE_CHALLENGE_RESULT
            : POLLING_FAILED_LIVE_CAPTURE_CHALLENGE_RESULT,
      },
    },
    uploadThunks: {
      getLiveCaptureChallengeResult: getLiveCaptureChallengeResult(),
      pollUploadedFile: pollLiveCapturePortraitStatus(PORTRAIT),
      processLiveCaptureBestPortrait: processLiveCaptureBestPortrait(),
    },
    uploadStateSelectors: {
      polling: (state) => state.userDetails.apiStatus.polling,
      posting: (state) =>
        state.userDetails.apiStatus.postingLiveCaptureChallengeResult,
      postingLiveCaptureBestPortrait: (state) =>
        state.userDetails.apiStatus.postingLiveCaptureBestPortrait,
      documentId: (state) => state.userDetails.apiData.liveCapture.portraitId,
      isCurrentUploadVerified: (state) =>
        state.userDetails.apiData.isCurrentUploadVerified,
      uploadProgress: (state) =>
        state.userDetails.apiData.liveCapture.verificationProgress,
      invalidOrNotVerifiedDocument:(state)=>state.userDetails.apiData.invalidOrNotVerifiedDocument
    },
    uploadActions: {
      setPolling: setPollingStatus(false),
      nextAction: incrementActiveStepLiveCapturePortrait,
      pollingSuccessAction: pollingLiveCaptureChallengeSuccess,
      pollingFailureAction: pollingLiveCaptureChallengeFailure,
      pollingSuccessfulValidator: liveCapturePollingSuccessful,
      pollingFailureValidator: liveCapturePollingFailure,
      setPollingTimedOut: unableToReachIdentityServerLiveCapture,
      decrementActiveStepFileAction: decrementActiveStepLiveCapturePortrait(),
      fileUploadBeginAfterFailure: liveCaptureBeginAfterFailure,
    },
    logger: {
      log: logMessage,
    },
    config:{
      pollingTimeout: Number(config.httpRequestParams.pollingTimeout)
    },
  };

  return <FileUploadVerification {...liveCaptureVerificationProps} />;
};

export default LiveCaptureVerification;
