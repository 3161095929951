import { makeStyles } from "@material-ui/core";

const FooterStyles = makeStyles((theme) => ({ //NOSONAR
    footer: {
        position: "sticky",
        left: "0",
        bottom: "0",
        marginBottom: "-50px",
        backgroundColor: "#464646",
        minHeight: "128px",
        lineHeight: "1.42857143",
        [theme.breakpoints.down("md")]: {
            paddingLeft: "15px",
            paddingRight: "15px"
        }
    },
    container: {
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: "0px",
        paddingRight: "0px"
    },
    sokContainer: {
        width: "100%"
    },
    footerRow: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between"
    },
    footerContent: {
        paddingTop: "15px",
        fontSize: "14px",
        fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif"
    },
    footerLink: {
        color: "#E8E8E8",
        padding: "7px",
        fontSize: "16px",
        textDecoration: "none",
        '&:visited': {
            color: "#E8E8E8",
             
        },
        '&:hover': {
            color: "#E8E8E8",
            textDecoration: "underline"
        }
    },
    footerCopyright: {
        margin: "0px",
        paddingLeft: "7px",
        paddingTop: "15px",
        color: "#E8E8E8",
        fontSize: "14px",
        fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "flex-start",
            width: "100%",
        }
    },
    browserCompatibility: {
        margin: "0px",
        padding: "10px",
        fontSize: "14px",
        textAlign: "center",
        color: "#E8E8E8",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif"
    },
    items: {
        color: "#E8E8E8",
    },
    anchorOptions: {
        textDecoration: "none",
        color: theme.palette.text.primary,
        '&:visited': {
            color: theme.palette.text.primary,
             
        },
        '&:hover': {
            color: theme.palette.text.primary,
        },
    },
    browserSuggestions: {
        marginTop: theme.spacing(1),
    }
}));

export default FooterStyles;