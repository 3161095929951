import React from "react";
import { Button, Grid, withStyles } from "@material-ui/core";
import styles from "./styles/unsupportedBrowser";
import OKLogo from '../commons/assets/OKLogo.png';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { GetOperatingSystem } from "./OsDetection";
import SupportedBrowsers from "./SupportedBrowserList";
import Typography from "@material-ui/core/Typography";
import Browser from "./Browser";

const UnsupportedBrowser = ({ classes }) => {

  const copiedUrl = window.location.href + "?return_url=" + window.sessionStorage.returnURL;
  const osDetected = GetOperatingSystem();
  const supportedBrowsers = SupportedBrowsers[osDetected];


  return (
    <Grid container direction="column" justifyContent="space-between" className={classes.dimensions}>

      <Grid item container direction="column">
        <Grid item container direction="row" justifyContent="center" alignItems="center"
              className={classes.logoContainer}>
          <Grid item className={classes.logoContainerItem}>
            <img width="250" height="50" src={OKLogo} title="Oklahoma Employment Security Commission"
                 alt="Oklahoma Employment Security Commission" data-testid="OKLogo"/>
          </Grid>
        </Grid>
        <Grid container direction="column" justifyContent="center" alignItems="center"
              className={classes.unsupportedBrowserDetailsContainer}>
          <Grid item className={classes.unsupportedBrowserGridItem}>
            <Typography className={classes.unsupportedBrowserTypography} data-testid="unsupported-browser-header">
              Unsupported Browser
            </Typography>
          </Grid>
          <Grid item className={classes.browserNotSupportedOnDeviceGridItem}>
            <Typography>
              Your browser is not supported on this device for use with this application.
            </Typography>
          </Grid>
          <Grid item className={classes.useCompatibleBrowsersGridItem}>
            <Typography>
              Please use one of the other compatible browsers below.
            </Typography>
          </Grid>
        </Grid>

        <Grid item container justifyContent="center" spacing={2} className={classes.browserContainer}>
          {supportedBrowsers.map((browser) => <Browser key={browser.name} logo={browser.logo} name={browser.name}
                                                       version={browser.version}/>)}
        </Grid>
      </Grid>


      <Grid item container justifyContent="center" alignItems="center" spacing={2}
            className={classes.copyLinkGridContainer}>
        <Grid item>
          <Button
            variant="outlined"
            aria-label="copy text"
            startIcon={<ContentCopyIcon/>}
            data-testid="copy-link-button"
            component="span"
            onClick={() => {
              navigator.clipboard.writeText(copiedUrl)
            }}>
            Copy Link
          </Button>
        </Grid>
        <Grid item className={classes.copyLinkGridItemText}>
          Paste the copied link into one of the supported browsers to open this site
        </Grid>
      </Grid>


    </Grid>
  );
}

export default withStyles(styles, { withTheme: true })(UnsupportedBrowser);
