import React from "react";

export const EmbeddedHelpVideo = () => {
  return (
    <>
        <iframe title="embedded-video-for-smaller-screens" width="100%" height="315"
                src="https://www.youtube.com/embed/3-qERfqED0k" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen/>
    </>
  );
};