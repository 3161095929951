import theme from "../../../theme";

const unsupportedBrowser = () => ({
  dimensions: {
    height: window.innerHeight,
  },
  logoContainer: {
    justifyContent: "center"
  },
  logoContainerItem: {
    padding: theme.spacing(4),
  },
  unsupportedBrowserDetailsContainer: {
    justifyContent: "center"
  },
  unsupportedBrowserGridItem: {
    padding: theme.spacing(2),
    textAlign:"center"
  },
  browserNotSupportedOnDeviceGridItem: {
    padding: theme.spacing(2),
    textAlign:"center"
  },
  browserContainer:{
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  useCompatibleBrowsersGridItem: {
    padding: theme.spacing(4),
    textAlign:"center"
  },
  unsupportedBrowserTypography:{
    fontWeight:"bold",
    fontSize:theme.spacing(3)
  },
  copyLinkGridContainer: {
    justifyContent: "center",
    margin:theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: "rgba(0, 113, 188, 0.05)",
    width:"auto"
  },
  copyLinkGridItemText:{
    textAlign:"center",
    opacity: "50%"
  }

});

export default unsupportedBrowser();
