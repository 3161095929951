const UploadStyles = ((theme) => ({
  header: {
    marginBottom: "40px",
  },
  required: {
    color: "#F00",
  },
  form: {
    marginLeft: "27px",
    color: "#676767",
  },
  boldForm: {
    fontWeight: "550",
    marginLeft: "26px",
    marginTop: "5px",
    marginBottom: "5px",
    color: "#676767",
  },
  radioTextColored: {
    color: "#0071BC",
  },
  radioTextUncolored: {
    color: "#676767",
  },
  responsiveText: {
    [theme.breakpoints.only("xs")]: {
      marginTop: theme.spacing(3),
    },
  },
  responsiveTextID: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
    },
  },
  radioLabelDL: {
      marginBottom: theme.spacing(0),
  }
}));

export default UploadStyles;
