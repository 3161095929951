import React from "react";
import { Grid, Typography, withStyles } from "@material-ui/core";
import * as constants from "../../constants";
import styles from "../consent/styles/ConsentStyles";
import PropTypes from "prop-types";
import { EmbeddedHelpVideo } from "./EmbeddedHelpVideo";

const HelpUploadingPhotos = ({ classes }) => {

  const PhoneOrComputerUploadInstructions = () => {
    return (
      <>
        <Typography variant="body2" data-testid="upload-medium-text">
          {constants.UPLOAD_MEDIUM_TEXT}
        </Typography>
        <ul
          data-testid="phone-or-computer-upload-help-instructions"
          className={classes.ul}
        >
          {constants.PHONE_OR_COMPUTER_UPLOAD_HELP_INSTRUCTIONS.map(
            (item, index) => {
              return (
                <Typography variant="body2" key={index}>
                  <li key={index} className={classes.ul}>
                    {item}
                  </li>
                </Typography>
              );
            }
          )}
        </ul>
        <br />
        <br />
      </>
    );
  };

  const PhotoIDUploadInstructions = () => {
    return (
      <>
        <Typography
          variant="body2"
          data-testid="photo-id-upload-help-description"
        >
          {constants.WHEN_UPLOAD_PHOTO_ID}
        </Typography>
        {CreateList(constants.UPLOAD_PHOTO_ID_INSTRUCTIONS, "photo-id-upload-help-instructions")}
        <br/>
        <br/>
      </>
    );
  };

  const CreateList = (dataSource, testId) => {
    return <ul
      data-testid={testId}
      className={classes.ul}
    >
      {dataSource.map((item, index) => {
        return (
          <Typography variant="body2" key={index}>
            <li key={index} className={classes.ul}>
              {item}
            </li>
          </Typography>
        );
      })}
    </ul>
  }

  const PhotoOfFaceUploadInstructions = () => {
    return (
      <>
        <Typography
          variant="body2"
          data-testid="photo-face-upload-help-description"
        >
          {constants.WHEN_UPLOAD_PHOTO_OF_FACE}
        </Typography>
        {CreateList(constants.UPLOAD_PHOTO_OF_FACE_INSTRUCTIONS, "photo-face-upload-help-instructions")}
        <br/>
      </>
    );
  };

  return (
    <Grid
      data-testid="consent-help-uploading-photos"
      container
      direction="column"
      alignItems="flex-start"
      spacing={1}
      className={classes.content}
    >
      <Grid item container justifyContent="center" xs={12} >
        <Grid item className={classes.embeddedVideo} xs={12} sm={6}>
          <EmbeddedHelpVideo/>
        </Grid>
      </Grid>
      <Grid item>
        <Typography
          variant="body2"
          data-testid="help-uploading-photos-description"
        >
          {constants.HELP_UPLOADING_PHOTOS_DESCRIPTION}
        </Typography>
        <br/>
        <br/>
      </Grid>
      <Grid data-testid="phone-or-computer-upload-help-section" item>
        <PhoneOrComputerUploadInstructions/>
      </Grid>
      <Grid data-testid="photo-id-upload-help-section" item>
        <PhotoIDUploadInstructions/>
      </Grid>
      <Grid data-testid="photo-face-upload-help-section" item>
        <PhotoOfFaceUploadInstructions/>
      </Grid>
      <Grid data-testid="upload-failure-help-section" item>
        <Typography variant="body2" data-testid="if-upload-fails-help">
        {constants.IF_UPLOAD_FAILS}
        <a
            target="_blank"
            rel="noopener noreferrer"
            href={constants.DIFFERENT_WEB_BROWSER_LINK}
        >
          {constants.DIFFERENT_WEB_BROWSER}
        </a>
        {constants.TROUBLESHOOTING_UPLOAD_ID}
        <a
            target="_blank"
            rel="noopener noreferrer"
            href={constants.CONTACT_US_LINK}
        >
            {constants.CONTACT_US}
        </a>
        {constants.FOR_FURTHER_ASSISTANCE}
        {constants.VISIT_FREQUENTLY_ASKED_QUESTIONS}
        <a
            target="_blank"
            rel="noopener noreferrer"
            href={constants.FREQUENTLY_ASKED_QUESTIONS_LINK}
        >
            {constants.FREQUENTLY_ASKED_QUESTIONS}
        </a>
        </Typography>
        <br/>
        <br/>
      </Grid>
    </Grid>
  );
};

HelpUploadingPhotos.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(HelpUploadingPhotos);
