import React, {useEffect} from "react";
import store from "../store/store";
import { setProtected } from "../store/reducers/routes";
import {  Route } from "react-router-dom";
const UnprotectedRoute = ({ component, path, exact }) => {
  useEffect(() => {
    store.dispatch(setProtected(false))
  }, []);

  return <Route path={path} component={component} exact={exact}/>
};

export default UnprotectedRoute;
