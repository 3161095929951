import { getRequest } from "./api";
import * as constants from "../../constants";
import {
  liveCaptureChallengeFailure,
  setGettingLiveCaptureChallengeResult, setLiveCaptureChallengeResultFailure,
  setLiveCaptureChallengeResultSuccess,
  setPostingLiveCaptureChallengeResult,
} from "../reducers/userDetails";
import { logMessage } from "./logMessage";
import { SOURCE_USER_FLOW } from "../../logger/logConstants";
import LogUtil from "../../logger/logUtil";
import { checkAttemptsAndRestartLiveCaptureVideoSession } from "../../components/liveCapture/RestartLiveCaptureVideoSession";

const restartLiveCaptureProps = {
  attemptsFailureReducer: setLiveCaptureChallengeResultFailure({
    status: constants.NOT_VERIFIED_UPLOAD_STATUS,
  }),
  setPostingFlagReducer: setPostingLiveCaptureChallengeResult(false),
  pendingAttemptLogMessage: constants.LIVE_CAPTURE_CHALLENGE_RESULT_ERROR_WITH_PENDING_RETRY_ATTEMPT,
  attemptsOverLogMessage: constants.LIVE_CAPTURE_CHALLENGE_RESULT_ERROR_AFTER_RETRY_ATTEMPTS
};

export const getLiveCaptureChallengeResult = () => () => {
  return async (dispatch, getState) => {
    dispatch(setGettingLiveCaptureChallengeResult(true));
    dispatch(setPostingLiveCaptureChallengeResult(true));

    const sessionId = getState().userDetails.apiData.liveCapture.sessionId;

    const url = `${constants.API_GET_LIVE_CAPTURE_RESULT}/${sessionId}`;

    return getRequest(url)
      .then((response) => {
        dispatch(setGettingLiveCaptureChallengeResult(false));
        if (response.data.status === constants.VERIFIED_UPLOAD_STATUS) {
          dispatch(setLiveCaptureChallengeResultSuccess());
          dispatch(setPostingLiveCaptureChallengeResult(false));
        } else
          checkAttemptsAndRestartLiveCaptureVideoSession(dispatch, getState, restartLiveCaptureProps);
        return response;
      })
      .catch((error) => {
        let errorMessage = {
          msg: error.message,
          method: "Get Live Capture Challenge Result",
        };
        dispatch(
          liveCaptureChallengeFailure(
            "Live Capture Challenge Result failed to fetch. Please try again"
          )
        );
        dispatch(
          logMessage(
            SOURCE_USER_FLOW,
            LogUtil.ERROR,
            JSON.stringify(errorMessage)
          )
        );

        checkAttemptsAndRestartLiveCaptureVideoSession(dispatch, getState, restartLiveCaptureProps);
      });
  };
}
