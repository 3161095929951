import { LiveCaptureConfig } from "./LiveCaptureConfig";
import {
  incrementActiveStepLiveCapturePortrait,
  setLiveCaptureChallengeFinished,
  setLiveCaptureInstruction,
  setLiveCaptureRecenterFaceOutline,
  setLiveCaptureVideoSession,
  setLiveCaptureIsStreaming,
  setPreLiveCaptureInstruction,
} from "../../store/reducers/userProgress";
import {
  incrementLiveCaptureAttempts,
  setIsLiveCaptureAttemptPending,
  liveCaptureSessionFailure,
} from "../../store/reducers/userDetails";
import store from "../../store/store";
import * as constants from "../../constants";
import { logMessage } from "../../store/api/logMessage";
import { SOURCE_USER_FLOW } from "../../logger/logConstants";
import LogUtil from "../../logger/logUtil";

let client = undefined;
let videoStream = undefined;
let bioSessionTimerId = undefined;

export let isSupportedEnvironment = () => {
  const environmentDetection = LiveCaptureConfig.bioServerEnvironment.detection();
  return (
    environmentDetection.envDetected.browser.isSupported &&
    environmentDetection.envDetected.os.isSupported &&
    environmentDetection.envDetected.os.isMobile
  );
};

export const StartLiveCapture = async (sessionId) => {
  const faceCaptureOptions = createFaceCaptureOptions(sessionId);

  videoStream = await bioServerVideoGetMediaStream();

  if (videoStream) {
    setTimeout(() => {
      renderVideoStream(videoStream);
    }, constants.LIVE_CAPTURE_RENDER_STREAM_AFTER_MS);

    client = await LiveCaptureConfig.bioServerVideoApi.initFaceCaptureClient(
      faceCaptureOptions
    );

    if (client) {
      client.start(videoStream);

      store.dispatch(setLiveCaptureRecenterFaceOutline(true));

      bioSessionTimerId = setTimeout(() => {
        onBioServerConnectionSevered();
      }, constants.LIVE_CAPTURE_BIO_SERVER_TIMEOUT);

      return true;
    }
  }

  return false;
};

const createFaceCaptureOptions = (sessionId) => {
  return {
    bioSessionId: sessionId,
    showChallengeInstruction: (challengeInstruction) => {},//NOSONAR
    showChallengeResult: async () => {
      if (client) {
        disconnectClientAndStopStreaming(client);
      }
    },
    trackingFn: (trackingInfo) => {
      displayInstructionsToUser(trackingInfo);
    },
    errorFn: (error) => {
      if (error.code === constants.LIVE_CAPTURE_SESSION_NOT_FOUND_ERROR_CODE) {
        onBioServerConnectionSevered();
      }
      if (client) {
        disconnectClientAndStopStreaming(client);
      }
    },
    wspath: "/video-server/engine.io",
    bioserverVideoUrl: LiveCaptureConfig.bioServerVideoUrl,
    rtcConfigurationPath: `${
      LiveCaptureConfig.bioServerVideoUrl
    }/video-server/coturnService?bioSessionId=
      ${encodeURIComponent(sessionId)}`,
  };
};

const disconnectClientAndStopStreaming = (captureClient) => {
  captureClient.disconnect(true);
  closeVideoStream();

  store.dispatch(setLiveCaptureIsStreaming(false));
  store.dispatch(setLiveCaptureRecenterFaceOutline(false));
  store.dispatch(setLiveCaptureInstruction(null));
  store.dispatch(setLiveCaptureChallengeFinished(true));

  renderVideoStream(null);

  store.dispatch(incrementLiveCaptureAttempts());

  const liveCaptureAttempts = store.getState().userDetails.apiData.liveCapture
    .numberOfAttempts;
  store.dispatch(
    setIsLiveCaptureAttemptPending(
      liveCaptureAttempts < constants.LIVE_CAPTURE_ATTEMPTS_THRESHOLD
    )
  );

  store.dispatch(setLiveCaptureVideoSession(false));
  store.dispatch(incrementActiveStepLiveCapturePortrait());
  clearTimeout(bioSessionTimerId);
};

const bioServerVideoGetMediaStream = async (options = {}) => {
  videoStream = await LiveCaptureConfig.bioServerVideoApi
    .getMediaStream({
      videoId: "user-video",
      video: { deviceId: options.deviceId },
    })
    .catch(async (error) => {
      store.dispatch(
        logMessage(SOURCE_USER_FLOW, LogUtil.ERROR, JSON.stringify(error))
      );
      if (error.name === constants.LIVE_CAPTURE_VIDEO_PERMISSION_DENIED_ERROR) {
        onCameraAccessDenied();
      }
    });

  if (!videoStream) {
    return;
  }

  return videoStream;
};

const onCameraAccessDenied = () => {
  store.dispatch(setLiveCaptureVideoSession(false));
  store.dispatch(setPreLiveCaptureInstruction(true));
  store.dispatch(
    liveCaptureSessionFailure(
      constants.LIVE_CAPTURE_VIDEO_PERMISSION_DENIED_MESSAGE
    )
  );
};

const onBioServerConnectionSevered = () => {
  if (videoStream && videoStream.active) {
    store.dispatch(
      liveCaptureSessionFailure(
        constants.LIVE_CAPTURE_BIO_SERVER_CONNECTION_SEVERED_MESSAGE
      )
    );

    if (client) {
      client.cancel();
      client.disconnect(true);
    }

    closeVideoStream();
    store.dispatch(setLiveCaptureIsStreaming(false));
    store.dispatch(setLiveCaptureVideoSession(false));
    store.dispatch(setLiveCaptureRecenterFaceOutline(false));
    store.dispatch(setLiveCaptureInstruction(null));
    store.dispatch(setPreLiveCaptureInstruction(true));
  }
};

const renderVideoStream = (captureVideoStream) => {
  const videoObject = document.querySelector('#user-video');

  if (videoObject) {
    videoObject.srcObject = captureVideoStream;
  }
  if (captureVideoStream) {
    store.dispatch(setLiveCaptureIsStreaming(true));
  }
};

const closeVideoStream = () => {
  videoStream.getTracks().forEach((track) => {
    if (track.kind === "video") {
      track.stop();
      track.enabled = false;
    }
  });
};

const displayInstructionsToUser = (trackingInformation) => {
  if (trackingInformation.phoneNotVertical) {
    store.dispatch(
      setLiveCaptureInstruction(
        LiveCaptureConfig.liveCaptureInstructions.PHONE_NOT_VERTICAL
      )
    );
  } else if (trackingInformation.tooFar) {
    store.dispatch(
      setLiveCaptureInstruction(
        LiveCaptureConfig.liveCaptureInstructions.PHONE_TOO_FAR
      )
    );
  } else if (trackingInformation.tooClose) {
    store.dispatch(
      setLiveCaptureInstruction(
        LiveCaptureConfig.liveCaptureInstructions.PHONE_TOO_CLOSE
      )
    );
  } else {
    const livenessHighData = trackingInformation.livenessHigh;

    if (trackingInformation.faceh === 0 && trackingInformation.facew === 0) {
      store.dispatch(
        setLiveCaptureInstruction(
          LiveCaptureConfig.liveCaptureInstructions.FACE_NOT_DETECTED
        )
      );
    } else if (livenessHighData && livenessHighData.movingPhone) {
      store.dispatch(
        setLiveCaptureInstruction(
          LiveCaptureConfig.liveCaptureInstructions
            .TRACKER_POSITION_INFO_STAND_STILL
        )
      );
    } else if (trackingInformation.positionInfo) {
      store.dispatch(
        setLiveCaptureInstruction(
          LiveCaptureConfig.liveCaptureInstructions[
            trackingInformation.positionInfo
          ]
        )
      );
    }
  }
};
