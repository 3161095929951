import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { logoutRequest } from "./../authConfig"

export const b2cLogout = async (pca, request=logoutRequest) => {
  return await pca.logoutRedirect(request);
}

const Logout = () => {
  const { instance, inProgress } = useMsal();

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      b2cLogout(instance);
    }
  }, [instance, inProgress]);

  return null;
};

export default Logout;
