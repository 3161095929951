import React, { useEffect } from "react";
import InternalStepper from "../commons/InternalStepper";
import { Grid } from "@material-ui/core";
import IDSelection from "./IDSelection";
import DocumentPictureSelection from "./DocumentPictureSelection";
import DocumentUploadVerification from "./DocumentUploadVerification";
import { setIdType } from "../../store/reducers/userProgress";
import { useDispatch } from "react-redux";

const UploadDocument = ({ idType }) => {
  const dispatch = useDispatch();
  const steps = [
    {
      label: "Primary ID Select",
      content: <IDSelection idType={idType}/>,
    },
    {
      label: "Upload Front and Back",
      content: <DocumentPictureSelection idType={idType}/>,
    },
    {
      label: "Uploading",
      content: <DocumentUploadVerification idType={idType}/>,
    },
    {
      label: "Done",
      content: "Done",
    },
  ];

  useEffect(() => {
    dispatch(setIdType(idType));
  });

  return (
    <Grid item container>
      <InternalStepper steps={steps}
                       activeStepStateSelector={state => state.userProgress.uploadDocument[idType].activeStep}/>
    </Grid>
  );
};

export default UploadDocument;
