import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#004E9A",
        },
        text: {
            primary: "#004E9A",
        },
        background: "#F5F5F5",
    },
    overrides: {
        MuiStepIcon: {
            root: {
                "&$completed": {
                    color: "green",
                },
                "&$active": {
                    color: "#004E9A",
                },
            },
            //  active: {},
            //  completed: {},
        },
        MuiMenu: {
            list: {
                padding: "0px",
            }
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
            bootstrapLg: 992
        },
    },
});

theme.typography.h2 = {
    fontSize: "3.2rem",
    fontWeight: "400",
};

theme.typography.h4 = {
    fontSize: "2.2rem",
    fontWeight: "500",
};

theme.typography.h5 = {
    fontSize: "1.6rem",
    fontWeight: "450",
    color: "#444",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
        fontSize: "1.5rem",
    },
};

theme.typography.h6 = {
    fontSize: "1rem",
    fontWeight: "550",
    color: "#000",
};

theme.typography.subtitle1 = {
    fontSize: "1.3rem",
    fontWeight: "500",
    color: "#444",
};

theme.typography.subtitle3 = {
    fontSize: "0.7rem",
    fontWeight: "normal",
    color: "#999999",
};

export default theme;
