import { makeStyles } from "@material-ui/core";

const PhotosSelectionStyles = makeStyles(() => ({
  header: {
    marginBottom: "20px",
  },
  required: {
    color: "#F00",
  },
}));

export default PhotosSelectionStyles;
