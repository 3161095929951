import { getRequest } from "./api";
import store from "../store";
import { logMessage } from "./logMessage";
import { SOURCE_USER_FLOW } from "../../logger/logConstants";
import LogUtil from "../../logger/logUtil";

export function pollingLOAStatus() {
  return async (idType) => {
    const url = `${idType}/status`;
    return await getRequest(url)//NOSONAR
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errorMessage = {
          msg: error.message,
          method: "Poll LOA status"
        }
        store.dispatch(logMessage(SOURCE_USER_FLOW, LogUtil.ERROR, JSON.stringify(errorMessage)));
        return { error: error };
      });
  }
}
