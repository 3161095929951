import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import { logger } from "redux-logger";

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: false,
  }),
];

if (process.env.NODE_ENV === `development`) {
  middleware.push(logger);
}

const store = configureStore({
  reducer: rootReducer,
  middleware,
});

export default store;
