export const PORTRAIT_SELECTION_HEADER = "Take a photo of your face";
export const PORTRAIT_SELECTION_UPLOAD_HEADER = "Photo of your face";
export const PORTRAIT_UPLOAD_INSTRUCTIONS_HEADER = "Why do I have to take a photo of my face?";
export const PORTRAIT_UPLOAD_INSTRUCTIONS_CONTENT =
  "To verify your identity, a comparison will be made between the photo of your face and the photo of your ID to ensure both match.";
export const UPLOADING_YOUR_PORTRAIT = "Uploading your photo...";
export const SUCCESS_PORTRAIT_UPLOAD = "Thank you for uploading your photo.";
export const PORTRAIT_UPLOAD_FAILED = "Your photo failed to upload. Please try again.";
export const POLLING_SUCCESSFUL_DESCRIPTION_PORTRAIT_MESSAGE = "Your photo is valid.";
export const POLLING_PORTRAIT_FAILURE_MESSAGE = "We were not able to authenticate your photo.";
