export const STEPPER_STEPS = [
  "Start",
  "Upload Primary ID",
  "Upload Photo",
  "Finish",
];

export const STEPPER_STEPS_WITH_SECONDARY = [
  "Start",
  "Upload Primary ID",
  "Upload Photo",
  "Upload Additional ID",
  "Finish",
];

export const SELECT_PORTRAIT_STEPPER_LABEL = "Portrait Select";
export const UPLOAD_PORTRAIT_STEPPER_LABEL = "Portrait Upload";
export const FETCH_LOA_STEPPER_LABEL = "Identity Status";

export const LIVE_CAPTURE_INSTRUCTION_STEPPER_LABEL = "Live Capture Instruction";
export const LIVE_CAPTURE_VERIFICATION_STEPPER_LABEL = "Live Capture Verification";
