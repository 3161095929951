import FileUploadVerification from "../commons/FileUploadVerification";
import React from "react";
import {
  DOCUMENT_UPLOAD_VERIFIED,
  POLLING_UPLOADED_DOCUMENT,
  POLLING_UPLOADED_DOCUMENT_FAILED, UPLOADING_DOCUMENT,
  UPLOADING_DOCUMENT_FAILED,
} from "../../store/reducers/StoreConstant";
import {
  DOCUMENT_UPLOAD_FAILED,
  POLLING_DOCUMENT_FAILURE_MESSAGE,
  POLLING_SUCCESSFUL_DESCRIPTION_DOCUMENT_MESSAGE, SECONDARY_DOCUMENT_UPLOAD_FAILED, SECONDARY_ID,
  SUCCESS_DOCUMENTS_UPLOAD, SUCCESS_SECONDARY_DOCUMENTS_UPLOAD,
  UPLOADING_YOUR_DOCUMENTS,
} from "../../constants";
import {
  documentUploadBeginAfterFailure,
  pollingFailure,
  pollingSuccessFul,
  setPollingStatus,
  unableToReachIdentityServer
} from "../../store/reducers/userDetails";
import { decrementActiveStepDocumentUpload, incrementActiveStep } from "../../store/reducers/userProgress";
import { postDocument } from "../../store/api/postDocument";
import { pollUploadedDocument } from "../../store/api/pollUploadedDocument";
import {logMessage} from "../../store/api/logMessage";
import config from "../../config/config";
import {
  filePollingFailure,
  filePollingSuccessful
} from "../commons/Validators";

const DocumentUploadVerification = ({ idType }) => {

  const uploadFailureTitle = idType === SECONDARY_ID ? SECONDARY_DOCUMENT_UPLOAD_FAILED : DOCUMENT_UPLOAD_FAILED;
  const uploadSuccessTitle = idType === SECONDARY_ID ? SUCCESS_SECONDARY_DOCUMENTS_UPLOAD : SUCCESS_DOCUMENTS_UPLOAD;

  const documentUploadVerificationProps = {
    uploadStages: {
      uploadingFile: {
        name: UPLOADING_DOCUMENT,
        title: UPLOADING_YOUR_DOCUMENTS,
      },
      getLiveCaptureChallengeResult: {
        name: '',
        title:''
      },
      processLiveCaptureBestPortrait: {
        name: '',
        title: ''
      },
      pollingUploadedFile: {
        name: POLLING_UPLOADED_DOCUMENT,
        title: uploadSuccessTitle,
      },
      uploadingFileFailed: {
        name: UPLOADING_DOCUMENT_FAILED,
        title: uploadFailureTitle,
      },
      fileUploadVerified: {
        name: DOCUMENT_UPLOAD_VERIFIED,
        description: POLLING_SUCCESSFUL_DESCRIPTION_DOCUMENT_MESSAGE,
      },
      pollingFailed: {
        name: POLLING_UPLOADED_DOCUMENT_FAILED,
        title: POLLING_DOCUMENT_FAILURE_MESSAGE,
      },
    },
    uploadThunks: {
      uploadFile: postDocument(idType),
      pollUploadedFile: pollUploadedDocument(idType),
    },
    uploadStateSelectors: {
      posting: state => state.userDetails.apiStatus.postingDocument,
      polling: state => state.userDetails.apiStatus.polling,
      postingLiveCaptureBestPortrait: (state) =>
        state.userDetails.apiStatus.postingLiveCaptureBestPortrait,
      uploadProgress: state => state.userDetails.apiData.documentUpload.uploadProgress,
      isCurrentUploadVerified: state => state.userDetails.apiData.isCurrentUploadVerified,
      documentId: state => state.userDetails.apiData.documentUpload.id,
      invalidOrNotVerifiedDocument:(state)=>state.userDetails.apiData.invalidOrNotVerifiedDocument
    },
    uploadActions: {
      setPolling: setPollingStatus(false),
      setPollingTimedOut: unableToReachIdentityServer,
      decrementActiveStepFileAction: decrementActiveStepDocumentUpload({ idType }),
      fileUploadBeginAfterFailure: documentUploadBeginAfterFailure,
      nextAction: incrementActiveStep,
      pollingSuccessAction: pollingSuccessFul,
      pollingFailureAction: pollingFailure,
      pollingSuccessfulValidator: filePollingSuccessful,
      pollingFailureValidator: filePollingFailure,
    },
    logger:{
      log: logMessage
    },
    config:{
      pollingTimeout: Number(config.httpRequestParams.documentUploadStatusPollingTimeout)
    },
  };

  return (
    <FileUploadVerification {...documentUploadVerificationProps} />
  );
};

export default DocumentUploadVerification;
