import React from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const QontoConnector = withStyles((theme) => ({
    root: {
        flex: 0,
    },
    alternativeLabel: {
        top: 10,
        left: "calc(-110%)",
        right: "calc(130% + 5px)",
    },
    active: {
        "& $line": {
            borderColor: "#004E9A",
        },
    },
    completed: {
        "& $line": {
            borderColor: "#004E9A",
        },
    },
    line: {
        borderColor: "#eaeaf0",
        marginLeft: -20,
        margin: 0,
        borderTopWidth: 3,
        borderRadius: 1,
        width: 75,
        [theme.breakpoints.down("sm")]: {
            width: 60,
        },
    },
}))(StepConnector);

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginLeft: theme.spacing(0),
    },
    stepper: {
        paddingLeft: "0px",
        marginLeft: "auto",
    },
    button: {
        marginRight: theme.spacing(1),
    },
}));

const useQontoStepIconStyles = makeStyles({
    root: {
        color: "#eaeaf0",
        display: "flex",
        height: 22,
        alignItems: "center",
    },
    active: {
        color: "#784af4",
    },
    completed: {
        color: "white",
        zIndex: 0,
        fontSize: 18,
    },
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const {active} = props;

  return (
    <div
    className={clsx(classes.root, {
        [classes.active]: active,
    })}
    />
  );
}

const InternalStepper = ({steps, activeStepStateSelector }) => {

  const classes = useStyles();
  const activeStep = useSelector(activeStepStateSelector);
  const activeStepContent = steps[activeStep - 1] ?  steps[activeStep - 1].content: "Coming soon...";

  return (
    <div className={classes.root}>
      <Grid item>
        <Stepper
          activeStep={activeStep}
          connector={<QontoConnector />}
          className={classes.stepper}
        >
          {steps.map(({ label }) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}/>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid item>
        <div>{activeStepContent}</div>
      </Grid>
    </div>
  );
}

InternalStepper.propTypes = {
    steps: PropTypes.array.isRequired,
    activeStepStateSelector: PropTypes.func.isRequired,
};

export default InternalStepper;
