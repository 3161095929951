import makeStyles from "@material-ui/core/styles/makeStyles";

const PortraitUploadInstructionsStyles = makeStyles(theme => ({
  instructions: {
    width: "279px",
    [theme.breakpoints.down("xs")]: {
      width: "50vw",
    },
  },
}));

export default PortraitUploadInstructionsStyles;
