import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isDesktop } from 'react-device-detect';
import { useMsal } from '@azure/msal-react';
import { Container, Typography } from "@material-ui/core";
import styles from "./styles/VerifyStyles";
import { Header } from "../commons/Header";
import { Footer } from "../commons/Footer";
import LiveCaptureVideoSession from "../liveCapture/LiveCaptureVideoSession";
import { Spinner, Stepper } from "../commons";
import Desktop from "../desktop/Desktop";
import config from "../../config/config";
import UnsupportedBrowser from "../unsupportedBrowser/UnsupportedBrowser";
import { isSupportedEnvironment } from "../liveCapture/LiveCaptureHandler";
import { GetOperatingSystem } from "../unsupportedBrowser/OsDetection";
import UnsupportedOS from "../unsupportedBrowser/UnsupportedOS";
import { b2cLogout } from "../Logout";
import { timeoutRequest } from "../../authConfig";

const Verify = () => {
  const classes = styles();
  const isLiveCaptureVideoSessionEnabled = useSelector((state) => state.userProgress.liveCapture.startLiveCaptureVideoSession);
  const isLiveChallengeFinished = useSelector((state) => state.userProgress.liveCapture.isLiveCaptureChallengeFinished);
  const isPreLiveCaptureInstructionShown = useSelector((state) => state.userProgress.liveCapture.showPreLiveCaptureInstruction);
  const isStreaming = useSelector((state) => state.userProgress.liveCapture.isStreaming);
  const isLiveCaptureStarted = useSelector((state) => state.userProgress.liveCapture.start);
  const isUnauthorized = useSelector((state) => state.userDetails.unauthorized);

  const isPreLiveCaptureVideoSpinnerNeeded = () => {
    return !isLiveChallengeFinished && isLiveCaptureStarted && !isPreLiveCaptureInstructionShown && !isStreaming
  };

  const { instance } = useMsal();

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      b2cLogout(instance, timeoutRequest);
    }, config.auth.sessionTimeout);

    return () => { clearTimeout(timeoutID) };
  }, [instance]);

  useEffect(() => {
    if (isUnauthorized) {
      b2cLogout(instance);
    }
  }, [instance, isUnauthorized]);

  const renderMobileFlow = () => {
    if (isSupportedEnvironment())
      return MobileFlow();
    else {
      if (GetOperatingSystem() === undefined)
        return <UnsupportedOS />;
      else
        return <UnsupportedBrowser />;
    }
  }

  const MobileFlow = () => {
    return <div>
      {isPreLiveCaptureVideoSpinnerNeeded() && <Spinner data-testid="render-video-session" />}
      {isLiveCaptureVideoSessionEnabled
        ? <LiveCaptureVideoSession />
        : !isPreLiveCaptureVideoSpinnerNeeded() &&
        <>
          <Header />
          <Container className={classes.container}>
            <Typography
              data-testid="verify-header"
              variant={"h2"}
              className={classes.header}
            >
              
              VerifyOK
            </Typography>
            <Stepper />
          </Container>
          <Footer />
        </>
      }
    </div>
  }

  return (
    <>
      {isDesktop && JSON.parse(config.flags.isStaticDesktopEnabled.toLowerCase())
        ? <Desktop />
        : <>{renderMobileFlow()}</>
      }
    </>
  );
};

export default Verify;
