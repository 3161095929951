import React from "react";
import Lottie from "react-lottie";
import * as animationData from "../animations/polling.json";

export const PollingAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div data-testid="polling-animation">
      <Lottie options={defaultOptions} height={50} width={100} />
    </div>
  );
};
