export const UPLOADING_DOCUMENT = "UPLOADING_DOCUMENT";
export const POLLING_UPLOADED_DOCUMENT = "POLLING_UPLOADED_DOCUMENT";
export const UPLOADING_DOCUMENT_FAILED = "UPLOADING_DOCUMENT_FAILED";
export const POLLING_UPLOADED_DOCUMENT_FAILED = "POLLING_UPLOADED_DOCUMENT_FAILED";
export const DOCUMENT_UPLOAD_VERIFIED = "DOCUMENT_UPLOAD_VERIFIED";
export const UNABLE_TO_REACH_IDENTITY_SERVER = "UNABLE_TO_REACH_IDENTITY_SERVER";
export const UPLOADING_PORTRAIT = "UPLOADING_PORTRAIT";
export const POLLING_UPLOADED_PORTRAIT = "POLLING_UPLOADED_PORTRAIT";
export const UPLOADING_PORTRAIT_FAILED = "UPLOADING_PORTRAIT_FAILED";
export const POLLING_PORTRAIT_FAILED = "POLLING_PORTRAIT_FAILED";
export const PORTRAIT_UPLOAD_VERIFIED = "PORTRAIT_UPLOAD_VERIFIED";
export const POLLING_LOA_STATUS = "POLLING_LOA_STATUS";
export const POLLING_LOA_STATUS_FAILED = "POLLING_LOA_STATUS_FAILED";
export const INADEQUATE_LOA = "INADEQUATE_LOA";
export const ADEQUATE_LOA = "ADEQUATE_LOA";
export const POLLING_LOA_STATUS_UNKNOWN_FAILURE = "UNKNOWN_FAILURE";
export const POLLING_TIMED_OUT = "POLLING_TIMED_OUT";
export const LOA_STATUS_VERIFIED = "VERIFIED";
export const LOA_STATUS_NOT_VERIFIED = "NOT_VERIFIED";
export const LOA_STATUS_PROCESSING= "PROCESSING";
export const LOA_STATUS_TIMEOUT= "TIMEOUT";

//Live Capture
export const POLLING_LIVE_CAPTURE_PORTRAIT = "POLLING_LIVE_CAPTURE_PORTRAIT";
export const POLLING_LIVE_CAPTURE_CHALLENGE_SUCCESSFUL = "POLLING_LIVE_CAPTURE_CHALLENGE_SUCCESSFUL";
export const POLLING_SUCCESS_LIVE_CAPTURE_CHALLENGE_RESULT = "POLLING_SUCCESS_LIVE_CAPTURE_CHALLENGE_RESULT";
export const POLLING_LIVE_CAPTURE_CHALLENGE_FAILED = "POLLING_LIVE_CAPTURE_CHALLENGE_FAILED";
export const GET_LIVE_CAPTURE_CHALLENGE_RESULT = "GET_LIVE_CAPTURE_CHALLENGE_RESULT";
export const PROCESS_LIVE_CAPTURE_BEST_PORTRAIT = "PROCESS_LIVE_CAPTURE_BEST_PORTRAIT";
