import * as constants from "../../constants";
import { getRequest } from "./api";
import { logMessage } from "./logMessage";
import { SOURCE_USER_FLOW } from "../../logger/logConstants";
import LogUtil from "../../logger/logUtil";
import { consentTextFailure, setConsentText,setUploadConfig } from "../reducers/userDetails";
import { setEnableDocumentUpload } from "../reducers/userProgress";

export function getWebUiExternalConfig() {
  return async (dispatch) => {
    return getRequest(constants.API_CONFIGURATION_URL)
      .then((response) => {
        dispatch(setConsentText(response.data.consentVerbiage));
        dispatch(setUploadConfig(response.data.uploadConfig));
      })
      .catch((error) => {
        let errorMessage = {
          msg: error.message,
          method: "Get Consent Text"
        }
        dispatch(setEnableDocumentUpload(false));
        dispatch(consentTextFailure("Consent text failed to fetch. Please try again"));
        dispatch(logMessage(SOURCE_USER_FLOW, LogUtil.ERROR, JSON.stringify(errorMessage)));
        return { error: error };
      });
  };
}