const config = {
  auth: {
    client_id: window.externalConfiguration.REACT_APP_AUTH_CLIENT_ID,
    tenant: window.externalConfiguration.REACT_APP_AUTH_TENANT_ID,
    instance: window.externalConfiguration.REACT_APP_AUTH_INSTANCE,
    audience: window.externalConfiguration.REACT_APP_AUTH_AUDIENCE,
    scope: window.externalConfiguration.REACT_APP_AUTH_SCOPE,
    redirect_uri: window.externalConfiguration.REACT_APP_AUTH_REDIRECT_URI,
    logout_redirect_uri: window.externalConfiguration.REACT_APP_AUTH_LOGOUT_REDIRECT_URI,
    timeout_redirect_uri: window.externalConfiguration.REACT_APP_AUTH_TIMEOUT_REDIRECT_URI,
    sessionTimeout: window.externalConfiguration.REACT_APP_AUTH_SESSION_TIMEOUT || 900000,
  },
  api: {
    baseUrl: window.externalConfiguration.REACT_APP_VERIFY_OK_BASE_URL,
  },
  httpRequestParams: {
    documentUploadStatusPollingTimeout: window.externalConfiguration.REACT_APP_DOCUMENT_STATUS_POLLING_TIMEOUT,
    pollingTimeout: window.externalConfiguration.REACT_APP_POLLING_TIMEOUT,
    pollingInterval: window.externalConfiguration.REACT_APP_POLLING_INTERVAL,
  },
  livesnessDetection: {
    bioServerEnvironment: window.BioserverEnvironment,
    docServerVideoApi: window.DocserverVideo,
    bioServerVideoApi: window.BioserverVideo,
    bioServerVideoUrl: window.externalConfiguration.REACT_APP_BIO_SERVER_VIDEO_URL,
    liveCaptureInstructions: window.externalConfiguration.REACT_APP_LIVE_CAPTURE_INSTRUCTIONS,
  },
  flags:{
    isStaticDesktopEnabled: window.externalConfiguration.IS_STATIC_DESKTOP_ENABLED
  }
};

export default config;
