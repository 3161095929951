import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@mui/material";
import { withStyles } from "@material-ui/core";
import styles from "./styles/BrowserStyles";

const Browser = ({
                   name,
                   version,
                   logo,
                   classes
                 }) => {
  const browserDataTestId = `${name}-browser-test-id`;
  return (
    <Grid item container direction="column" alignItems="center" className={classes.browserContainer} xs={6}>
      <Grid item className={classes.browserLogoGridItem}>
        <img width="50" height="50" src={logo} alt={name} title={name} data-testid={browserDataTestId} />
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" className={classes.browserName}>
          {name}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle3" className={classes.versionText}>
          version {version}
        </Typography>
      </Grid>
    </Grid>
  );
};

Browser.propTypes = {
  name: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired
}
export default withStyles(styles, { withTheme: true })(Browser);
