import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./styles/SpinnerStyles";

const Spinner = (props) => {
  const classes = styles();

  return (
    <CircularProgress className={classes.spinner} size={100} {...props} />
  );
};

export default Spinner;
