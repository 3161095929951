import { makeStyles } from "@material-ui/core";

const SpinnerStyles = makeStyles(() => ({
  spinner: {
    position: "fixed",
    top: "-100%",
    right: "-100%",
    left: "-100%",
    bottom: "-100%",
    margin: "auto",
    zIndex: "0"
  }
}));

export default SpinnerStyles;
