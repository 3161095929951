import FileUploadStyles from "./styles/FileUploadStyles";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { useDropzone } from "react-dropzone";
import { Button, Grid, Typography, withStyles } from "@material-ui/core";
import { PhotoCamera } from "@material-ui/icons";
import {
  MAX_FILE_SIZE_ERROR_MESSAGE,
  SUPPORTED_FILE_FORMATS,
  MEGA_BYTES_ABBREVIATION,
} from "../../constants";
import PropTypes from "prop-types";
import _ from "lodash";
import HideOnMobile from "../commons/HideOnMobile";
import LivenessSelection from "../liveCapture/LiveCaptureSelection";

export const UploadPhoto = ({
  photoErrorStateSelector,
  setPhotosAction,
  setPhotoUploadErrorAction,
  uploadIcon,
  uploadHeader,
  idType,
  classes,
  showLivenessSelection,
  showPhotoSelection,
}) => {
  const photoError = useSelector(photoErrorStateSelector);
  const dispatch = useDispatch();
  const fileInputId = `input-${setPhotosAction.type}`;

  const maxUploadSizeInBytes = useSelector(
    (state) =>
      state.userDetails.apiData.externalConfig.maxImageUploadSizeInBytes
  );
  const maxImageUploadSizeInMb = useSelector(
    (state) => state.userDetails.apiData.externalConfig.maxImageUploadSizeInMb
  );
  const maxUploadSizeErrorMessage = `${MAX_FILE_SIZE_ERROR_MESSAGE} ${maxImageUploadSizeInMb} ${MEGA_BYTES_ABBREVIATION}`;

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/png, image/jpeg, image/jpg",
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        if (
          _.find(fileRejections, (fileRejection) =>
            _.find(
              fileRejection.errors,
              (error) => error.code === "file-too-large"
            )
          )
        ) {
          dispatch(
            setPhotoUploadErrorAction({
              idType: idType,
              uploadError: maxUploadSizeErrorMessage,
            })
          );
        }
        return;
      }
      setPreview(acceptedFiles[0]);
    },
    maxFiles: 1,
    minSize: 0,
    maxSize: maxUploadSizeInBytes,
  });
  const handlePhotoUpload = (event) => {
    const files = [...event.target.files];
    const uploadedFile = files[0];
    const size = uploadedFile.size;
    if (size > maxUploadSizeInBytes) {
      dispatch(
        setPhotoUploadErrorAction({
          idType: idType,
          uploadError: maxUploadSizeErrorMessage,
        })
      );
      return;
    }
    setPreview(uploadedFile);
  };
  const setPreview = async (file) => {
    const preview = URL.createObjectURL(file);
    const fileResult = await fetch(preview);
    const blob = await fileResult.blob();
    const fileName = file.name;
    dispatch(
      setPhotosAction({
        idType: idType,
        fileData: { file: blob, preview, fileName },
      })
    );
    dispatch(setPhotoUploadErrorAction({ idType: idType, uploadError: null }));
  };
  return (
    <Grid
      item
      container
      spacing={2}
      direction="column"
      className={classes.content}
      justifyContent="center"
      alignItems="center">
      <Grid
        item
        container
        style={{ paddingLeft: "0px" }}
        className={classes.photoHeader}>
        <Typography
          data-testid="upload-photo-header"
          className={classes.uploadHeader}>
          <b>{uploadHeader}</b>
        </Typography>
      </Grid>
      <Grid
        item
        style={
          !showLivenessSelection
            ? { display: "none" }
            : { paddingBottom: "4px", paddingLeft: "0px" }
        }>
        {showLivenessSelection && (
          <>
            <Grid item container justifyContent="center">
              <LivenessSelection />
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              style={{ padding: "inherit" }}>
              <Typography data-testid="or">
                {showPhotoSelection ? "Or" : ""}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
      {showPhotoSelection ? (
        <Grid
          item
          container
          direction="column"
          className={classes.upload}
          justifyContent="space-between"
          data-testid="photo-upload-dropzone"
          style={{ padding: "25px 8px" }}
          {...getRootProps({ onClick: (event) => event.stopPropagation() })}>
          <HideOnMobile>
            <input
              accept="image/png, image/jpg, image/jpeg"
              id="contained-button-file-drag-and-drop"
              type="file"
              className={classes.input}
              data-testid="photo-drop-input"
              {...getInputProps()}
            />
            <Grid item container justifyContent="center">
              <img
                src={uploadIcon}
                alt="upload icon"
                data-testid="upload-photo-image"
              />
            </Grid>
            <Grid item container justifyContent="center">
              <Typography variant="body2" data-testid="drag-and-drop">
                Drag & Drop
              </Typography>
            </Grid>
            <Grid item container justifyContent="center">
              <Typography variant="body2" data-testid="or">
                - or -
              </Typography>
            </Grid>
          </HideOnMobile>
          <input
            accept="image/png, image/jpg, image/jpeg"
            className={classes.input}
            id={fileInputId}
            type="file"
            data-testid="upload-photo"
            multiple={false}
            onChange={handlePhotoUpload}
          />
          <label htmlFor={fileInputId}>
            <Grid item container justifyContent="center">
              <Button
                variant="outlined"
                className={classes.imageUploadButton}
                aria-label="upload picture"
                startIcon={<PhotoCamera />}
                data-testid="upload-photo-button"
                component="span">
                Upload photo
              </Button>
            </Grid>
          </label>
          <Grid item container className={classes.imageFormatInfo}>
            <Typography variant="body2" data-testid="upload-formats">
              {SUPPORTED_FILE_FORMATS}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <div />
      )}
      <div>
        {photoError && (
          <Typography
            data-testid="photo-upload-error"
            >
            {photoError}
          </Typography>
        )}
      </div>
    </Grid>
  );
};
UploadPhoto.propTypes = {
  photoErrorStateSelector: PropTypes.func.isRequired,
  setPhotosAction: PropTypes.func.isRequired,
  setPhotoUploadErrorAction: PropTypes.func.isRequired,
  uploadIcon: PropTypes.string.isRequired,
  uploadHeader: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  showLivenessSelection: PropTypes.bool,
  showPhotoSelection: PropTypes.bool.isRequired,
};
export default withStyles(FileUploadStyles, { withTheme: true })(UploadPhoto);
