import { makeStyles } from "@material-ui/core";

const HeaderStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: "#f8f9fa",
        minHeight: "70px",
        zIndex: "9999",
        background: "#f8f9fa",
        marginBottom: "2px",
        boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
    container: {
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: "0px",
        paddingRight: "0px"
    },
    navbar: {
        position: "relative",
        padding: "10px 0px 0px 0px"
    },
    navbarBrand: {
        height: "60px !important",
        padding: "0px 15px 0px 15px",
        margin: "0px 0 0 0",
    },
    navbarNav: {
        paddingTop: "5px",
        marginLeft: "5px",
        [theme.breakpoints.down(992)]: {
            marginLeft: "0px"
        }

    },
    navLink: {
        color: "#004e9a !important",
        fontWeight: "600",
        fontFamily: "'Montserrat', Arial, sans-serif",
        fontSize: "14px",
        [theme.breakpoints.up(992)]: {
            padding: "5px 15px 15px 15px !important",
        },
        [theme.breakpoints.down(992)]: {
            backgroundColor: "#004e9a !important",
            color: "#f6f6f9 !important",
            padding: "10px 15px !important",
            marginTop: "1px"
        },
        '&:hover': {
            color: "#df933a !important",
            borderBottom: "0.2rem solid #004E9A",
            padding: "0px"
        },
        '&:visited': {
            color: theme.palette.text.primary,
        }
    },
    navbarToggler: {
        margin: "5px 15px 5px 5px",
    },
    containerFluid: {
        marginRight: "0px",
        marginLeft: "0px",
        paddingLeft: "15px",
        paddingRight: "15px"
    },
    options: {
        color: theme.palette.text.primary,
        paddingLeft: theme.spacing(4),
    },
    anchorOptions: {
        textDecoration: "none",
        color: theme.palette.text.primary,


    },
    logout: {
        padding: "5px 15px 15px",
        justifyContent: "flex-end",
        marginLeft: "auto",
        width: "10%",
        '&:focus': {
            outline: "none"
        },
        '&:hover': {
            backgroundColor: "#f8f9fa"
        },
        [theme.breakpoints.down(992)]: {
            display: "none"
        }
    },
    logoutLink: {
        [theme.breakpoints.up(992)]: {
            display: "none"
        }
    },
    containerWidth: {
        width: "50%",
    },
    mobileMenuItem: {
        backgroundColor: "white",
        paddingBottom: "0px",
        paddingLeft: "0px",
        paddingTop: "0px",
        '&:hover': {
            backgroundColor: "lightGrey",
        },
    },
    menuList: {
        paddingBottom: "0px !important",
    }
}));

export default HeaderStyles;