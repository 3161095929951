import React from "react";
import { useSelector } from "react-redux";
import  PreLiveCaptureInstruction  from "./PreLiveCaptureInstruction";

export const LiveCapture = () => {
  const isLiveCaptureInstructionEnabled = useSelector((state) => state.userProgress.liveCapture.showPreLiveCaptureInstruction);

  return (
    <>
      {isLiveCaptureInstructionEnabled && <PreLiveCaptureInstruction/>}
    </>
  );
};
