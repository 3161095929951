import config from "./config/config";

const authority = `${config.auth.instance}/tfp/${config.auth.tenant}`;

export const msalConfig = {
  auth: {
    clientId: config.auth.client_id,
    authority: `${authority}/${config.auth.audience}`,
    redirectUri: config.auth.redirect_uri,
    knownAuthorities: [ authority ],
    navigateToLoginRequestUrl: false,
  }
}

export const loginRequest = {
  scopes: [ config.auth.scope ]
}

export const logoutRequest = {
  postLogoutRedirectUri: config.auth.logout_redirect_uri
}

export const timeoutRequest = {
  postLogoutRedirectUri: config.auth.timeout_redirect_uri
}
