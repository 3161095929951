const liveCaptureInstruction = () => ({
  dimensions: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  videoOverlay: {
    position: "absolute",
    top: "50%",
    zIndex: "99",
    display: "flex",
    justifyContent: "center",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    transition: "opacity 1s ease-in",
    transform: "translateY(-50%)",
    maxWidth: window.innerWidth,
  },
  videoMessageCenter: {
    color: "white",
    fontWeight: "bold",
    alignSelf: "center",
    fontSize: "30px",
    lineHeight: "1.36",
    textAlign: "center",
    wordSpacing: "100vw" /* to force words wrap*/,
  },
  displayNoneFadeout: {
    opacity: "0!important",
    transition: "opacity 500ms ease-out",
  },
});

export default liveCaptureInstruction;
