import { makeStyles } from "@material-ui/core";

const desktopStyles = makeStyles((theme) => ({
    header: {
        color: theme.palette.text.primary,
    },
    container: {
        padding: theme.spacing(3),
        [theme.breakpoints.up("sm")]: {
            minHeight: "calc(92vh - 122px)"
        },
        minHeight: "calc(100vh - 152px)",
    },
    vokContainerWhite: {
        background: 'white',
        marginTop: '10px',
        marginBottom: '10px',
        padding: '10px',
    },
    verificationProcessGrid: {
        background: '#FFFFFF',
        alignItems: 'center'
    },
    scanQRGrid: {
        width: '35%',
        background: '#f2f2f2',
        margin: '20px',
        padding: '20px 0',
        borderRadius: '15px',
        lineHeight: '20.7px',
        color: '#676767'
    },
    DesktopWindowsIcon: {
        color: '#BDBDBD',
        width: '100px',
        height: '100px'
    },
    TrendingFlatIcon: {
        color: '#BDBDBD',
        marginLeft: '5px',
        width: '50px',
        height: '50px'
    },
    PhoneIphoneIcon: {
        color: '#BDBDBD',
        width: '60px',
        height: '60px',
    },
    CheckCircleIcon: {
        color: '#27AE60',
        width: '20px',
        height: '20px',
        marginLeft: '-41px'
    },
    scanQRTextGrid: {
        width: '60%',
    },
    scanQRHeader: {
        color: '#676767',
    },
    postVerificationGrid: {
        width: '35%',
        margin: '20px',
    },
    QRCode: {
        width: '30px',
        height: '30px'
    },
    vokWebLink: {
        color: '#2F80ED'
    },
    onlineVerificationDescriptionGrid: {
        margin: '10px',
    },
    onlineVerificationDescriptionHeader: {
        margin: '10px',
    },
    goToDashboardButton: {
        marginTop: '20px',
    }
}));

export default desktopStyles;