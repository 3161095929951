import theme from "../../../theme";

const BrowserStyles = () => ({
  browserContainer: {
    width: "auto",
  },
  browserName: {
    textAlign: "center"
  },
  browserLogoGridItem:{
    paddingBottom: theme.spacing(1)
  },
  versionText: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.7rem",
    lineHeight: "13px",
    textAlign: "center",
    color: "#999999"
  }
});

export default BrowserStyles();
