import React, { useEffect, useState } from "react";
import * as constants from "../../constants";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  withStyles
} from "@material-ui/core";
import HelpUploadingPhotos from "./HelpUploadingPhotos";
import { setConsentChecked, setCountry, setEnableDocumentUpload, } from "../../store/reducers/userProgress";
import store from "../../store/store";
import { useSelector } from "react-redux";
import { postConsent } from "../../store/api/postConsent";
import { IdAnimation } from "./IdAnimation";
import FooterComponent from "../commons/components/FooterComponent";
import styles from "./styles/ConsentStyles";
import PropTypes from "prop-types";
import { logMessage } from "../../store/api/logMessage";
import LogUtil from "../../logger/logUtil";
import { SOURCE_ANALYTICS } from "../../logger/logConstants";
import { getWebUiExternalConfig } from "../../store/api/getWebUiExternalConfig";
import { Spinner } from "../commons";

const Consent = ({ classes }) => {
    const [countrySelected, setCountrySelected] = useState(
      useSelector((state) => state.userProgress.consent.country)
    );
    const [consent, setConsent] = useState(
      useSelector((state) => state.userProgress.consent.consentChecked)
    );
    const [verifyInPerson, setVerifyInPerson] = useState(false);
    const [helpUploadingPhotos, setHelpUploadButton] = useState(false);
    const enableNext = useSelector(
      (state) => state.userProgress.enableDocumentUpload
    );
    const postConsentError = useSelector(
      (state) => state.userDetails.errors.consent
    );
    const getConsentTextError = useSelector(
      (state) => state.userDetails.errors.consentText
    );

    const displayConsentText = useSelector(
      (state) => state.userDetails.apiData.externalConfig.consentText
    );

    const enableConsentCheckbox = () => {
      return getConsentTextError == null;
    }

    useEffect(() => {
      store.dispatch(getWebUiExternalConfig());
    }, []);

    const onConsentChange = (event) => {
      const value = event.target.checked;
      setConsent(value);
      store.dispatch(setConsentChecked(value));
      store.dispatch(setEnableDocumentUpload(value));
    };

    const onCountrySelected = (event) => {
      const value = event.target.value;
      setCountrySelected(value);
      if (value === constants.CONSENT_COUTRY_OTHER) {
        store.dispatch(setConsentChecked(false));
        store.dispatch(setEnableDocumentUpload(false));
        setConsent(false);
      }
      store.dispatch(setCountry(value));
    };

    const handleNext = () => {
      store.dispatch(postConsent());
      store.dispatch(logMessage(SOURCE_ANALYTICS, LogUtil.INFORMATION, LogUtil.getConsentNextLog()));
      store.dispatch(setEnableDocumentUpload(false));
    };

    const HowToVerifyIdentity = () => {
      return constants.CONSENT_VERIFY_IDENTITY.map((item, index) => {
        return (
          <Typography variant="body2" key={index}>
            <li key={index} className={classes.ol}>
              {item}
            </li>
          </Typography>
        );
      });
    };

    const SoftwareUpdateReminder = () => {
      return (
        <Typography variant={"body2"} data-testid="consent-software-update-reminder-header" className={classes.idFormsHeader}>
            <b>Important: </b>{constants.CONSENT_SOFTWARE_UPDATE_REMINDER}
        </Typography>
      );
    };

    const AcceptedForms = () => {
      return (
        <>
          <Typography variant={"h5"} data-testid="consent-accepted-ids-header" className={classes.idFormsHeader}>
            {constants.CONSENT_ACCEPTED_FORMS_HEADER}
          </Typography>
          {CreateList(constants.CONSENT_ACCEPTED_IDS, "consent-accepted-ids")}
        </>
      );
    };

    const CreateList = (dataSource, testId) => {
      return <ul data-testid={testId} className={classes.ul}>
        {dataSource.map((item, index) => {
          return (
            <Typography variant="body2" key={index}>
              <li key={index} className={classes.ul}>
                {item}
              </li>
            </Typography>
          );
        })}
      </ul>
    }

    const UnsupportedForms = () => {
      return (
        <>
          <Typography variant={"h5"} data-testid="consent-invalid-ids-header" className={classes.idFormsHeader}>
            {constants.CONSENT_UNSUPPORTED_FORMS_HEADER}
          </Typography>
          {CreateList(constants.CONSENT_UNSUPPORTED_IDS, "consent-invalid-ids")}
        </>
      );
    };

    const CountrySelection = () => {
      return (
        <FormControl>
          <FormLabel data-testid="consent-country-selection-header">
            <Typography variant="h6" className={classes.selectCountryHeader}>
              {constants.CONSENT_SELECT_COUNTRY}
              {<b className={classes.required}>{"  *"}</b>}
            </Typography>
          </FormLabel>
          <RadioGroup
            data-testid="consent-country-options"
            value={countrySelected}
            onChange={onCountrySelected}>
            {constants.CONSENT_COUNTRY_OPTIONS.map((option, index) => {
              return (
                <FormControlLabel
                  key={"consent-country-option-" + index}
                  data-testid={"consent-country-option-" + index}
                  value={option}
                  control={<Radio color="primary"/>}
                  label={option}
                  className={classes.countryOptions}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      );
    };

    const ConsentContent = () => {
      return (
        <Grid item container direction="column" spacing={2}>
          <Grid item>
            <Typography
              variant={"subtitle1"}
              data-testid="consent-verbose-header">
              {constants.CONSENT_VERBOSE_HEADER}
            </Typography>
          </Grid>
          {
            getConsentTextError &&
            <Grid item>
              <Typography
                data-testid="consent-text-get-error"
                className={classes.required}>
                Unable to connect. Please try again.
              </Typography>
            </Grid>
          }
          <Grid item>
            {/* Using dangerouslySetInnerHTML property here as an exception,
             since rendered consent text, which is configured, in server side has HTML in it.
             Therefore, there is no scope of XSS */}
            <Typography variant={"body2"} data-testid="consent-verbose-terms"
                        dangerouslySetInnerHTML={{__html: displayConsentText}}/>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">
              <FormControlLabel
                data-testid="consent-checkbox"
                control={
                  <Checkbox
                    disabled={!enableConsentCheckbox()}
                    checked={consent}
                    inputProps={{ "aria-label": "primary checkbox" }}
                    onChange={onConsentChange}
                  />
                }
                label="I consent."
              />
            </Typography>
          </Grid>
        </Grid>
      );
    };

    const ShowButtons = () => {
      const toggleVerifyButton = () => {
        setVerifyInPerson(!verifyInPerson);
        helpUploadingPhotos === true && setHelpUploadButton(false);
      };
      const toggleHelpButton = () => {
        setHelpUploadButton(!helpUploadingPhotos);
        verifyInPerson === true && setVerifyInPerson(false);
      };
      return (
        <Grid item container direction="column" spacing={2}>
          <Grid item>
            <Button
              data-testid="consent-button-verify-in-person"
              variant="outlined"
              fullWidth
              onClick={toggleVerifyButton}>
              {constants.CONSENT_BUTTON_VERIFY_IN_PERSON}
            </Button>
          </Grid>
          {
            verifyInPerson && (
              <Grid item>
              <Typography
              variant="body2"
              data-testid="visit-webpage-for-help"
              className={classes.note}>
                  {constants.PLEASE}
                  <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={constants.CONTACT_US_LINK}
                  >
                  {constants.CONTACT_US}
                  </a>
                  {constants.FOR_FURTHER_ASSISTANCE}
                  {constants.VISIT_FREQUENTLY_ASKED_QUESTIONS}
                  <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={constants.FREQUENTLY_ASKED_QUESTIONS_LINK}
                  >
                  {constants.FREQUENTLY_ASKED_QUESTIONS}
                  </a>
                  </Typography>
              </Grid>
            )
          }
          <Grid item>
            <Button
              data-testid="consent-button-help-uploading-photos"
              variant="outlined"
              fullWidth
              onClick={toggleHelpButton}>
              {constants.CONSENT_BUTTON_HELP_UPLOADING_PHOTOS}
            </Button>
          </Grid>
          {
            helpUploadingPhotos && (
              <Grid item>
                <HelpUploadingPhotos/>
              </Grid>
            )
          }
        </Grid>
      );
    };

    return (
      <Grid container direction="column" alignItems="flex-start">
        {!displayConsentText && !getConsentTextError &&
        <Spinner data-testid="consentSpinner"/>
        }
        {
          (displayConsentText || getConsentTextError) &&
          <>
            <Grid item>
              <Typography
                variant={"h4"}
                data-testid="consent-header"
                className={classes.header}>
                {constants.CONSENT_HEADER}
              </Typography>
            </Grid>
            <Grid item container>
              <Grid sm={12} md={6} item>
                <ol data-testid="consent-verify-identity" className={classes.ol}>
                  <HowToVerifyIdentity/>
                </ol>
              </Grid>
              <Grid data-testid="consent-software-update-reminder-section">
                <SoftwareUpdateReminder/>
              </Grid>
              <Grid data-testid="consent-hands-animation" item md={6} sm={12}>
                <IdAnimation/>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid data-testid="consent-accepted-forms-section" item sm={12} md={4}>
                <AcceptedForms/>
              </Grid>
              <Grid data-testid="consent-invalid-forms-section" sm={12} md={4} item>
                <UnsupportedForms/>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  data-testid="consent-documents-note"
                  className={classes.note}>
                  <b>{constants.CONSENT_NOTE}</b>
                  {constants.CONSENT_DOCUMENTS_NOTE}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <CountrySelection/>
            </Grid>
            {countrySelected === constants.CONSENT_COUTRY_OTHER && (
              <Grid item>
               
                <Typography
                  data-testid="consent-non-us-document"
                  variant="body2"
                  className={classes.note}
                  >
                  {constants.CONSENT_NON_US_ID}
                  <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={constants.CONTACT_US_LINK}
                  >
                  {constants.CONTACT_US}
                  </a>
                  {constants.FOR_FURTHER_ASSISTANCE}
                  {constants.VISIT_FREQUENTLY_ASKED_QUESTIONS}
                  <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={constants.FREQUENTLY_ASKED_QUESTIONS_LINK}
                  >
                  {constants.FREQUENTLY_ASKED_QUESTIONS}
                  </a>
                </Typography>
              
            </Grid>
            )}
            <br />    
            <Grid item container direction="column" spacing={2}>
              {countrySelected === constants.CONSENT_COUTRY_USA && <ConsentContent/>}
              {
                postConsentError &&
                <Grid item>
                  <Typography
                    data-testid="consent-post-error"
                    className={classes.required}>
                    Unable to connect. Please try again.
                  </Typography>
                </Grid>
              }
              {countrySelected === constants.CONSENT_COUTRY_USA && <ShowButtons/>}
              <FooterComponent
                onNext={handleNext}
                nextTestId="consent-post-button"
                showNext
                disableNext={!enableNext}
              />
            </Grid>
          </>
        }
      </Grid>
    );
  }
;

Consent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Consent);
