import React from "react";
import styles from "./styles/PhotosSelectionStyles";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ShowNeedHelpInUploadingPhotos from "../commons/ShowNeedHelpInUploadingPhotos";
import FooterComponent from "../commons/components/FooterComponent";

const PhotosSelection =
    ({
         headerText,
         mandatoryFileStateSelector,
         children,
         handlePrev,
         handleNext,
         showPhotoSelection
     }) => {
        const classes = styles();

        const mandatoryFile = useSelector(mandatoryFileStateSelector);

        return (
            <Grid container direction="column" spacing={5}>
                <Grid item>
                    <Typography
                        variant="h5"
                        data-testid="upload-portrait-header"
                        className={classes.header}>
                        <b>{headerText}</b>
                        <b className={classes.required}>{" *"}</b>
                    </Typography>
                </Grid>
                {children}
                <ShowNeedHelpInUploadingPhotos/>
                <FooterComponent
                    onNext={handleNext}
                    onPrevious={handlePrev}
                    showNext={showPhotoSelection}
                    showPrevious
                    nextTestId="upload-portrait-next-button"
                    disableNext={!mandatoryFile}
                />
            </Grid>
        );
    };

PhotosSelection.propTypes = {
    headerText: PropTypes.string.isRequired,
    mandatoryFileStateSelector: PropTypes.func.isRequired,
    children: PropTypes.object.isRequired,
    handlePrev: PropTypes.func,
    handleNext: PropTypes.func,
    showPhotoSelection: PropTypes.bool.isRequired
};

export default PhotosSelection;
