import FileUploadVerification from "../commons/FileUploadVerification";
import React from "react";
import config from "../../config/config";
import {
  POLLING_PORTRAIT_FAILED,
  POLLING_UPLOADED_PORTRAIT,
  PORTRAIT_UPLOAD_VERIFIED,
  UPLOADING_PORTRAIT,
  UPLOADING_PORTRAIT_FAILED,
} from "../../store/reducers/StoreConstant";
import {
  POLLING_PORTRAIT_FAILURE_MESSAGE,
  POLLING_SUCCESSFUL_DESCRIPTION_PORTRAIT_MESSAGE,
  PORTRAIT_UPLOAD_FAILED,
  SUCCESS_PORTRAIT_UPLOAD,
  UPLOADING_YOUR_PORTRAIT,
  PORTRAIT
} from "../../constants";
import postPortrait from "../../store/api/postPortrait";
import {
  pollingPortraitFailure,
  pollingPortraitSuccessful,
  portraitUploadBeginAfterFailure, setPollingStatus,
  unableToReachIdentityServerPortrait
} from "../../store/reducers/userDetails";
import {
  decrementActiveStepPortraitUpload,
  incrementActiveStepPortraitUpload
} from "../../store/reducers/userProgress";
import {pollUploadedDocument} from "../../store/api/pollUploadedDocument";
import {logMessage} from "../../store/api/logMessage";
import { filePollingFailure, filePollingSuccessful } from "../commons/Validators";

const PortraitUploadVerification = () => {

  const portraitUploadVerificationProps = {
    uploadStages: {
      uploadingFile: {
        name: UPLOADING_PORTRAIT,
        title: UPLOADING_YOUR_PORTRAIT,
      },
      getLiveCaptureChallengeResult: {
        name: '',
        title:''
      },
      processLiveCaptureBestPortrait: {
        name: '',
        title: ''
      },
      pollingUploadedFile: {
        name: POLLING_UPLOADED_PORTRAIT,
        title: SUCCESS_PORTRAIT_UPLOAD,
      },
      uploadingFileFailed: {
        name: UPLOADING_PORTRAIT_FAILED,
        title: PORTRAIT_UPLOAD_FAILED,
      },
      fileUploadVerified: {
        name: PORTRAIT_UPLOAD_VERIFIED,
        description: POLLING_SUCCESSFUL_DESCRIPTION_PORTRAIT_MESSAGE,
      },
      pollingFailed: {
        name: POLLING_PORTRAIT_FAILED,
        title: POLLING_PORTRAIT_FAILURE_MESSAGE,
      },
    },
    uploadThunks: {
      uploadFile: postPortrait,
      pollUploadedFile: pollUploadedDocument(PORTRAIT),
    },
    uploadStateSelectors: {
      posting: state => state.userDetails.apiStatus.postingPortrait,
      postingLiveCaptureBestPortrait: (state) =>
        state.userDetails.apiStatus.postingLiveCaptureBestPortrait,
      polling: state => state.userDetails.apiStatus.polling,
      uploadProgress: state => state.userDetails.apiData.portraitUpload.uploadProgress,
      isCurrentUploadVerified: state => state.userDetails.apiData.isCurrentUploadVerified,
      documentId: state => state.userDetails.apiData.portraitUpload.id,
      invalidOrNotVerifiedDocument:(state)=>state.userDetails.apiData.invalidOrNotVerifiedDocument
    },
    uploadActions: {
      setPolling: setPollingStatus(false),
      setPollingTimedOut: unableToReachIdentityServerPortrait,
      decrementActiveStepFileAction: decrementActiveStepPortraitUpload(),
      fileUploadBeginAfterFailure: portraitUploadBeginAfterFailure,
      nextAction: incrementActiveStepPortraitUpload,
      pollingSuccessAction: pollingPortraitSuccessful,
      pollingFailureAction: pollingPortraitFailure,
      pollingSuccessfulValidator: filePollingSuccessful,
      pollingFailureValidator: filePollingFailure,
    },
    logger:{
      log: logMessage
    },
    config:{
      pollingTimeout: Number(config.httpRequestParams.pollingTimeout)
    },
  };

  return (
    <FileUploadVerification {...portraitUploadVerificationProps} />
  );
};

export default PortraitUploadVerification;
