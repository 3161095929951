import { useHistory, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import { logMessage } from "../store/api/logMessage";
import { getHostnameFromUrl } from './utils';
import { useDispatch } from "react-redux";
import { SOURCE_ANALYTICS } from "../logger/logConstants";
import LogUtil from "../logger/logUtil";

const VALID_AGENCIES = ['OESC', 'OKDHS', 'NONE'];

const queryString = require('query-string');

const StoreReturnURL = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const returnURL = queryString.parse(location.search).return_url;
  const afterVerifyURL = queryString.parse(location.search).after_verify_url;
  const agency = queryString.parse(location.search).agency;

  console.log("Found returnURL " + returnURL);

  console.log("Found agency in URL " + agency);

  function storeAgencyInSessionStorage(url) {
      
      switch (true){
        case url.toLowerCase().includes('dhs'):
          sessionStorage.setItem('agency', "OKDHS");
          break;
        case url.toLowerCase().includes('oesc'):
          sessionStorage.setItem('agency', "OESC");
          break;
        default:
          sessionStorage.setItem('agency', "NONE");
      }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('return_url') || queryParams.has('after_verify_url')) {
      queryParams.delete('return_url')
      queryParams.delete('after_verify_url')
      history.replace({
        search: queryParams.toString(),
      });
    }
    if (queryParams.has('agency')) {
      queryParams.delete('agency')
      history.replace({
        search: queryParams.toString(),
      });
    }
  },[location.search, history, location]);

  const isValidUrl = (url) => {
    // eslint-disable-next-line
    const regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    let res = url.match(regex);
    return (res !== null)
  };

  if (returnURL && (isValidUrl(returnURL) || getHostnameFromUrl(returnURL) === "localhost")) {
    sessionStorage.setItem("returnURL", returnURL.toString());
    storeAgencyInSessionStorage(returnURL);
    dispatch(logMessage(SOURCE_ANALYTICS, LogUtil.INFORMATION, `Return URL set to ${returnURL}`));
  }

  if (afterVerifyURL && isValidUrl(afterVerifyURL)) {
    sessionStorage.setItem("afterVerifyURL", afterVerifyURL.toString());
    dispatch(logMessage(SOURCE_ANALYTICS, LogUtil.INFORMATION, `After verify URL set to ${afterVerifyURL}`));
  }

  if (VALID_AGENCIES.includes(agency)) {
    sessionStorage.setItem('agency', agency);
    console.log(`Agency '${agency}' is valid and saved in session storage.`);
  } else {
    console.log(`Agency '${agency}' is invalid.`);
  }

  return (
    <>
    </>
  );
};

export default StoreReturnURL;
