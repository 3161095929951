import { getRequest } from "./api";
import { setCurrentUploadVerified, } from "../reducers/userDetails";
import * as constants from "../../constants";
import store from "../store";
import { logMessage } from "./logMessage";
import { SOURCE_USER_FLOW } from "../../logger/logConstants";
import LogUtil from "../../logger/logUtil";
import { getEvidence } from "./getEvidence";

export const pollUploadedDocument = (idType) => () => {
  return async (documentId) => {
    const url = `documents/${idType}/${documentId}/status`;
    return getRequest(url)
      .then((response) => {
        if (response.data.status === constants.VERIFIED_UPLOAD_STATUS) {
          store.dispatch(setCurrentUploadVerified(true));
        } else if (response.data.status === constants.NOT_VERIFIED_UPLOAD_STATUS ||
          response.data.status === constants.INVALID_UPLOAD_STATUS)
        {
          store.dispatch(getEvidence());
        }
          return response;
      })
      .catch((error) => {
        let errorMessage = {
          msg: error.message,
          method: "Poll uploaded Document"
        }
        store.dispatch(logMessage(SOURCE_USER_FLOW, LogUtil.ERROR, JSON.stringify(errorMessage)));
        return { error: error };
      });
  }
}
