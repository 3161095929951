import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { LOGIN as LOGIN_TEXT } from "../constants";

const LoginButton = (props) => {
  let history = useHistory();

  const handleLoginClick = () => {
    history.push('/login');
  }

  return (
    <Button onClick={handleLoginClick} variant="outlined" data-testid="login-button">
      {LOGIN_TEXT}
    </Button>
  );
};

export default LoginButton;
