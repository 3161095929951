import {LOA_STATUS_NOT_VERIFIED, LOA_STATUS_TIMEOUT, LOA_STATUS_VERIFIED} from "../../store/reducers/StoreConstant";
import * as constants from "../../constants";

export const loaStatusVerifiedOrNotVerified = (data) => {
  return (data.status === LOA_STATUS_VERIFIED || data.status === LOA_STATUS_NOT_VERIFIED)
};

export const loaStatusTimeout = (data) => {
  return (data.status === LOA_STATUS_TIMEOUT)
};

export const filePollingSuccessful = (data) => {
  return (data.status === constants.VERIFIED_UPLOAD_STATUS)
};

export const filePollingFailure = (data) => {
  return (data.status === constants.INVALID_UPLOAD_STATUS || data.status === constants.NOT_VERIFIED_UPLOAD_STATUS)
};

export const liveCapturePollingSuccessful = (data) => {
  return (data.status === constants.VERIFIED_UPLOAD_STATUS);
};

export const liveCapturePollingFailure = (data) => {
  return (data.status === constants.NOT_VERIFIED_UPLOAD_STATUS || data.status === constants.INVALID_UPLOAD_STATUS);
};
