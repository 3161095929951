import { API_PROCESS_LIVE_CAPTURE_BEST_PORTRAIT } from "../../constants";
import { getRequest } from "./api";
import {
  setCurrentUploadVerified,
  setPostingLiveCaptureBestPortrait,
  processLiveCaptureBestPortraitFailure,
  processLiveCaptureBestPortraitSuccess,
  liveCaptureBestPortraitFailure
} from "../reducers/userDetails";
import { logMessage } from "./logMessage";
import { SOURCE_USER_FLOW } from "../../logger/logConstants";
import LogUtil from "../../logger/logUtil";
import { checkAttemptsAndRestartLiveCaptureVideoSession } from "../../components/liveCapture/RestartLiveCaptureVideoSession";
import * as constants from "../../constants";


const restartLiveCaptureProps = {
  attemptsFailureReducer: processLiveCaptureBestPortraitFailure({
    status: constants.NOT_VERIFIED_UPLOAD_STATUS,
  }),
  setPostingFlagReducer: setPostingLiveCaptureBestPortrait(false),
  pendingAttemptLogMessage: constants.LIVE_CAPTURE_PROCESS_BEST_PORTRAIT_FAILED_WITH_PENDING_RETRY_ATTEMPT,
  attemptsOverLogMessage: constants.LIVE_CAPTURE_PROCESS_BEST_PORTRAIT_AFTER_RETRY_ATTEMPTS
};

export const processLiveCaptureBestPortrait = () => () => {
  return async (dispatch, getState) => {
      dispatch(setPostingLiveCaptureBestPortrait(true));

      const url = `${API_PROCESS_LIVE_CAPTURE_BEST_PORTRAIT}`;
      return getRequest(url)
        .then(() => {
          dispatch(processLiveCaptureBestPortraitSuccess());
          dispatch(setCurrentUploadVerified(true));
          dispatch(setPostingLiveCaptureBestPortrait(false));
        })
        .catch((error) => {
          let errorMessage = {
            msg: error.message,
            method: "Get Process Live Capture Best Portrait",
          };

          dispatch(
            liveCaptureBestPortraitFailure("Live Capture Best Portrait failed to fetch. Please try again")
          );
          dispatch(logMessage(SOURCE_USER_FLOW, LogUtil.ERROR, JSON.stringify(errorMessage)));

          checkAttemptsAndRestartLiveCaptureVideoSession(dispatch, getState, restartLiveCaptureProps);
        });
  };
};
