export const POLLING_FILE_ID = "Please wait while a response is received from our identity authentication provider.";
export const POLLING_SUCCESSFUL_MESSAGE = "Authentication successful!";

const CurrentDateTimeInCST = () => new Date(new Date().toLocaleString("en-US", {timeZone: "America/Chicago"})); 

export const PROCESSING_UPLOAD_STATUS = "PROCESSING";
export const VERIFIED_UPLOAD_STATUS = "VERIFIED";
export const NOT_VERIFIED_UPLOAD_STATUS = "NOT_VERIFIED";
export const INVALID_UPLOAD_STATUS = "INVALID";
export const ADJUDICATION_UPLOAD_STATUS = "ADJUDICATION";
export const SUPPORTED_FILE_FORMATS = "Supported formats: png, jpeg, jpg, jpe";
export const BROWSER_COMPATIBILITY = "Browser compatibility";
export const BROWSER_COMPATIBILITY_LIST = "Latest versions of Chrome, Firefox, Safari, and Samsung Internet";
export const COPYRIGHT = () => `Copyright ©${CurrentDateTimeInCST().getFullYear()} State of Oklahoma`;
export const ACCESSIBILITY = "Accessibility";
export const POLICY = "Policies";
export const ABOUT_OESC = "About OESC";
export const ABOUT_DHS = "About DHS";
export const CONTACT = "Contact Us";
export const FAQ_TEXT = "FAQ";
export const SERVICES = "Services";
export const LOGOUT = "Logout";
export const LOGIN = "Login";
export const OKLAHOMA_WORKS_OFFICE_TEXT = "Oklahoma Works office";
export const TIMEOUT_MESSAGE = "Your session has timed out. Please login and try again."

//Header & Footer Links
export const ACCESSIBILITY_LINK = "https://oklahoma.gov/about/accessibility-policy.html";
export const POLICIES_LINK = "https://oklahoma.gov/about/policy-disclaimers.html";
export const OK_LOGO_LINK = "https://ui.ok.gov/en";
//OESC
export const CONTACT_US_LINK = "https://oklahoma.gov/oesc/about/contact-oesc.html";
export const ABOUT_OESC_LINK = "https://oklahoma.gov/oesc/about.html";
export const FAQ_LINK = "https://oklahoma.gov/oesc/individuals/faqs.html";
//DHS
export const ABOUT_DHS_LINK = "https://oklahoma.gov/okdhs.html"; 
export const CONTACT_US_DHS_LINK = "https://oklahoma.gov/okdhs/contact-us/dhshotlines.html";
export const SERVICES_DHS_LINK = "https://oklahoma.gov/okdhs/services.html";