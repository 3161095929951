const preCaptureLiveInstructionStyles = (theme) => ({
  liveCaptureSessionError: {
    color: "#F00",
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
});

export default preCaptureLiveInstructionStyles;
