import { makeStyles } from "@material-ui/core";

const PictureUploadStyles = makeStyles((theme) => ({
  header: {
    marginBottom: "50px",
  },
  howToSection: {
    marginBottom: "20px",
  },
  uploadSection: {
    marginTop: "18px",
    marginBottom: "71px",
  },
  photoSection: {
    marginBottom: "70px",
  },
  selectDifferentIDButton: {
    marginBottom: "47px",
  },
  navigationButtons: {
    marginTop: "47px",
  },
  required: {
    color: "#F00",
  },
  ol: {
    marginLeft: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },  
  exampleText: {
    marginBottom: "14px",
  },
  correctPhotoSpecs: {
    marginBottom: "10px",
  },
  correctImageMockUp: {
    borderColor: "green",
  },
  incorrectImageMockUp: {
    borderColor: "red",
  },
}));

export default PictureUploadStyles;
