import { createSlice } from "@reduxjs/toolkit";
import { PRIMARY_ID } from "../../constants";

const initialState = {
  consent: {
    country: "",
    consentChecked: false,
  },
  enableDocumentUpload: false,
  uploadDocument: {
    primaryId: {
      documentType: "",
      documentFront: {
        file: null,
        fileName: "",
        preview: "",
      },
      documentBack: {
        file: null,
        fileName: "",
        preview: "",
      },
      documentFrontError: null,
      documentBackError: null,
      activeStep: 1,
    },
    secondaryId: {
      documentType: "",
      documentFront: {
        file: null,
        fileName: "",
        preview: "",
      },
      documentBack: {
        file: null,
        fileName: "",
        preview: "",
      },
      documentFrontError: null,
      documentBackError: null,
      activeStep: 1,
    },
  },
  uploadPortrait: {
    photo: {
      file: null,
      fileName: "",
      preview: "",
    },
    uploadError: null,
    activeStep: 1,
  },
  activeStep: 0,
  secondaryDocumentRequired: false,
  idType: PRIMARY_ID,
  liveCapture: {
    activeStep: 1,
    start: false,
    showPreLiveCaptureInstruction: false,
    startLiveCaptureVideoSession: false,
    isLiveCaptureChallengeFinished: false,
    isLiveCaptureRecenterFaceOutlineEnabled: false,
    liveCaptureInstruction: "",
    isStreaming: false,
  },
};

const clearDocumentFilesReducer = (state, { payload }) => {
  const { idType } = payload;
  state.uploadDocument[idType].documentFront = {
    file: null,
    preview: "",
  };
  state.uploadDocument[idType].documentBack = {
    file: null,
    preview: "",
  };
};

const clearDocumentUploadErrorsReducer = (state, { payload }) => {
  const { idType } = payload;
  state.uploadDocument[idType].documentFrontError = null;
  state.uploadDocument[idType].documentBackError = null;
};

const userProgress = createSlice({
  name: "userProgress",
  initialState: initialState,
  reducers: {
    setEnableDocumentUpload: {
      reducer: (state, action) => {
        state.enableDocumentUpload = action.payload;
        return state;
      },
    },
    setCountry: {
      reducer: (state, action) => {
        state.consent.country = action.payload;
        return state;
      },
    },
    setConsentChecked: {
      reducer: (state, action) => {
        state.consent.consentChecked = action.payload;
        return state;
      },
    },
    incrementActiveStep: {
      reducer: (state) => {
        state.activeStep = state.activeStep + 1;
        return state;
      },
    },
    decrementActiveStep: {
      reducer: (state) => {
        if (state.activeStep !== 0) state.activeStep = state.activeStep - 1;
        return state;
      },
    },
    incrementActiveStepDocumentUpload: {
      reducer: (state, { payload }) => {
        const idType = payload.idType;
        state.uploadDocument[idType].activeStep =
          state.uploadDocument[idType].activeStep + 1;
        return state;
      },
    },
    decrementActiveStepDocumentUpload: {
      reducer: (state, { payload }) => {
        const idType = payload.idType;
        if (state.uploadDocument[idType].activeStep !== 1)
          state.uploadDocument[idType].activeStep =
            state.uploadDocument[idType].activeStep - 1;
        return state;
      },
    },
    setDocumentFront: {
      reducer: (state, { payload }) => {
        const idType = payload.idType;
        state.uploadDocument[idType].documentFront = payload.fileData;
      },
    },
    setDocumentBack: {
      reducer: (state, { payload }) => {
        const idType = payload.idType;
        state.uploadDocument[idType].documentBack = payload.fileData;
      },
    },
    clearDocumentFiles: {
      reducer: clearDocumentFilesReducer,
    },
    setDocumentFrontError: {
      reducer: (state, { payload }) => {
        const idType = payload.idType;
        state.uploadDocument[idType].documentFrontError = payload.uploadError;
      },
    },
    setDocumentBackError: {
      reducer: (state, { payload }) => {
        const idType = payload.idType;
        state.uploadDocument[idType].documentBackError = payload.uploadError;
      },
    },
    clearDocumentUploadErrors: {
      reducer: clearDocumentUploadErrorsReducer,
    },
    setDocumentType: {
      reducer: (state, { payload }) => {
        clearDocumentFilesReducer(state, { payload });
        clearDocumentUploadErrorsReducer(state, { payload });
        const idType = payload.idType;
        state.uploadDocument[idType].documentType = payload.documentType;
      },
    },
    incrementActiveStepPortraitUpload: {
      reducer: (state) => {
        state.uploadPortrait.activeStep = state.uploadPortrait.activeStep + 1;
      },
    },
    decrementActiveStepPortraitUpload: {
      reducer: (state) => {
        if (state.uploadPortrait.activeStep !== 1)
          state.uploadPortrait.activeStep = state.uploadPortrait.activeStep - 1;
      },
    },
    setPortraitFile: {
      reducer: (state, { payload }) => {
        const { file, preview, fileName } = payload.fileData;
        state.uploadPortrait.photo.file = file;
        state.uploadPortrait.photo.preview = preview;
        state.uploadPortrait.photo.fileName = fileName;
      },
    },
    setPortraitUploadError: {
      reducer: (state, { payload }) => {
        state.uploadPortrait.uploadError = payload.uploadError;
      },
    },
    setSecondaryDocumentUploadRequired: {
      reducer: (state, action) => {
        state.secondaryDocumentRequired = action.payload;
      },
    },
    setIdType: {
      reducer: (state, { payload }) => {
        state.idType = payload;
      },
    },
    startLiveCapture: {
      reducer: (state, action) => {
        state.liveCapture.start = action.payload;
        return state;
      },
    },
    setPreLiveCaptureInstruction: {
      reducer: (state, action) => {
        state.liveCapture.showPreLiveCaptureInstruction = action.payload;
        return state;
      },
    },
    setLiveCaptureVideoSession: {
      reducer: (state, action) => {
        state.liveCapture.startLiveCaptureVideoSession = action.payload;
        return state;
      },
    },
    setLiveCaptureChallengeFinished: {
      reducer: (state, action) => {
        state.liveCapture.isLiveCaptureChallengeFinished = action.payload;
        return state;
      },
    },
    setLiveCaptureRecenterFaceOutline: {
      reducer: (state, action) => {
        state.liveCapture.isLiveCaptureRecenterFaceOutlineEnabled =
          action.payload;
        return state;
      },
    },
    setLiveCaptureInstruction: {
      reducer: (state, action) => {
        state.liveCapture.liveCaptureInstruction = action.payload;
        return state;
      },
    },
    incrementActiveStepLiveCapturePortrait: {
      reducer: (state) => {
        state.liveCapture.activeStep = state.liveCapture.activeStep + 1;
      },
    },
    decrementActiveStepLiveCapturePortrait: {
      reducer: (state) => {
        if (state.liveCapture.activeStep !== 1)
          state.liveCapture.activeStep = state.liveCapture.activeStep - 1;
      },
    },
    setLiveCaptureIsStreaming: {
      reducer: (state, action) => {
        state.liveCapture.isStreaming = action.payload;
        return state;
      },
    },
  },
});

export const {
  setEnableDocumentUpload,
  setCountry,
  setConsentChecked,
  incrementActiveStep,
  decrementActiveStep,
  incrementActiveStepDocumentUpload,
  decrementActiveStepDocumentUpload,
  setDocumentFront,
  setDocumentBack,
  clearDocumentFiles,
  setDocumentFrontError,
  setDocumentBackError,
  clearDocumentUploadErrors,
  setDocumentType,
  incrementActiveStepPortraitUpload,
  decrementActiveStepPortraitUpload,
  setPortraitFile,
  setPortraitUploadError,
  setSecondaryDocumentUploadRequired,
  setIdType,
  startLiveCapture,
  setPreLiveCaptureInstruction,
  setLiveCaptureVideoSession,
  setLiveCaptureChallengeFinished,
  setLiveCaptureRecenterFaceOutline,
  setLiveCaptureInstruction,
  incrementActiveStepLiveCapturePortrait,
  decrementActiveStepLiveCapturePortrait,
  setLiveCaptureIsStreaming,
} = userProgress.actions;

export default userProgress.reducer;
