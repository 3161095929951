export const PRIMARY_ID_SELECTION_QUESTION =
  "What type of photo ID will you use?";

export const DRIVING_LICENCE =
  "Driver’s License  from any US State (Recommended)";
export const DRIVING_LICENCE_VERBOSITY =
  "*If you’re using a Driver’s License issued by the following states, you will need to submit two (2) types of photo identification.";
export const DRIVING_LICENCE_EXCEPTION =
  "California, Louisiana, New York, South Carolina or Alaska";

export const IDENTIFICATION_CARD = "Identification Card  from any US State";

export const RESIDENT_GREEN_CARD = "Resident / Green Card";

export const UPLOADING_YOUR_DOCUMENTS = "Uploading your ID...";

export const SUCCESS_DOCUMENTS_UPLOAD = "Thank you for uploading your ID.";

export const SUCCESS_SECONDARY_DOCUMENTS_UPLOAD = "Thank you for uploading your secondary ID.";

export const MAX_FILE_SIZE_ERROR_MESSAGE = "File too large. Max file size is";
export const MEGA_BYTES_ABBREVIATION = "MB.";

export const DOCUMENT_UPLOAD_FAILED = "Your ID failed to upload. Please try again.";

export const SECONDARY_DOCUMENT_UPLOAD_FAILED = "Secondary Document ID upload failed. Please try again";

export const POLLING_SUCCESSFUL_DESCRIPTION_DOCUMENT_MESSAGE = "Your ID is valid.";

export const UNABLE_TO_REACH_IDENTITY_SERVICE = "Due to network issues, your submission could not be processed. Please try again.";

export const POLLING_DOCUMENT_FAILURE_MESSAGE = "We were not able to authenticate your ID.";

export const PASSPORT = "US Passport";

export const PRIMARY_ID = "primaryId";
export const SECONDARY_ID = "secondaryId";
export const PORTRAIT = "portrait";

export const PASSPORT_OPTION_VALUE = "passport";
export const DL_OPTION_VALUE = "DL";
export const RESIDENT_OPTION_VALUE = "resident";
export const IDENTITY_OPTION_VALUE = "stateID";

export const SECONDARY_ID_SELECTION_QUESTION = "What type of secondary photo ID will you use?";
