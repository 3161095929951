import React, { useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import { PreLiveCaptureAnimation } from "./PreLiveCaptureAnimation";
import { Button, Typography, withStyles } from "@material-ui/core";
import * as constants from "../../constants";
import { initLiveCaptureSession } from "../../store/api/initLiveCaptureSession";
import { useDispatch, useSelector } from "react-redux";
import {
  setPreLiveCaptureInstruction,
  startLiveCapture,
} from "../../store/reducers/userProgress";
import store from "../../store/store";
import {
  gettingSessionId,
  liveCaptureSessionFailure,
} from "../../store/reducers/userDetails";
import PropTypes from "prop-types";
import preCaptureLiveInstructionStyles from "./styles/preCaptureLiveInstructionStyles";

const PreLiveCaptureInstruction = ({ classes }) => {
  const dispatch = useDispatch();
  const getLiveCaptureSessionError = useSelector(
    (state) => state.userDetails.errors.liveCaptureSession
  );

  const isGettingSessionId = useSelector(
    (state) => state.userDetails.apiStatus.gettingSessionId
  );

  const handleClickImReadyButton = () => {
    dispatch(gettingSessionId());
    store.dispatch(initLiveCaptureSession());
  };

  const handleGoBackButton = () => {
    dispatch(setPreLiveCaptureInstruction(false));
    dispatch(startLiveCapture(false));
    dispatch(liveCaptureSessionFailure(null));
  };

  const errorMessageRef = useRef(null);

  useEffect(() => {
    getLiveCaptureSessionError &&
      errorMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
  });

  return (
    <Grid container direction="column" alignItems="flex-start" spacing={2}>
      <Grid item>
        <Typography variant="h5" data-testid="pre_live_capture_info">
          <b>{constants.PRE_LIVE_CAPTURE_ANIMATION_INFO}</b>
        </Typography>
      </Grid>

      <Grid
        item
        container
        data-testid="pre-live-capture-animation"
        justifyContent="center">
        <PreLiveCaptureAnimation />
      </Grid>

      <Grid container justifyContent="space-between">
        <Grid item>
          <Button
            data-testid="go_back_live_capture"
            variant="contained"
            color="primary"
            onClick={handleGoBackButton}>
            {constants.GO_BACK_BUTTON}
          </Button>
        </Grid>

        <Grid item>
          <Button
            data-testid="ready_for_live_capture"
            variant="contained"
            color="primary"
            disabled={isGettingSessionId}
            onClick={handleClickImReadyButton}>
            {constants.READY_LIVE_CAPTURE_BUTTON}
          </Button>
        </Grid>
        {getLiveCaptureSessionError && (
          <Grid
            item
            container
            direction="column"
            spacing={2}
            ref={errorMessageRef}>
            <Typography
              data-testid="live-capture-session-get-error"
              className={classes.liveCaptureSessionError}>
              {getLiveCaptureSessionError}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

PreLiveCaptureInstruction.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(preCaptureLiveInstructionStyles, { withTheme: true })(
  PreLiveCaptureInstruction
);
