import { Button, Grid } from "@material-ui/core";
import * as constants from "../../constants";
import HelpUploadingPhotos from "../consent/HelpUploadingPhotos";
import React, { useState } from "react";

const ShowNeedHelpInUploadingPhotos = () => {
  const [helpUploadingPhotos, setHelpUploadButton] = useState(false);

  const toggleHelpButton = () => {
    setHelpUploadButton(!helpUploadingPhotos);
  };

  return (
    <Grid item container direction="column">
      <Button
        data-testid="button-help-uploading-photos"
        variant="outlined"
        onClick={toggleHelpButton}>
        {constants.CONSENT_BUTTON_HELP_UPLOADING_PHOTOS}
      </Button>
      {
        helpUploadingPhotos && (
          <Grid item>
            <HelpUploadingPhotos />
          </Grid>
        )
      }
    </Grid>
  );
};

export default ShowNeedHelpInUploadingPhotos;
