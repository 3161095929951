import { documentUploadFailure, documentUploadSuccessful, postingDocument } from "../reducers/userDetails";
import { postRequest } from "./api";
import * as constants from "../../constants";
import {logMessage} from "./logMessage";
import LogUtil from "../../logger/logUtil";
import {SOURCE_USER_FLOW} from "../../logger/logConstants";

export const postDocument = (idType) => () => {
  return async (dispatch, getState) => {
    const documentFront = getState().userProgress.uploadDocument[idType].documentFront.file;
    const documentFrontFilename = getState().userProgress.uploadDocument[idType].documentFront.fileName;
    const documentBack = getState().userProgress.uploadDocument[idType].documentBack.file;
    const documentBackFilename = getState().userProgress.uploadDocument[idType].documentBack.fileName;
    const documentType = getState().userProgress.uploadDocument[idType].documentType;

    dispatch(postingDocument());
    let bodyFormData = new FormData();
    let documentFrontFile = new File([documentFront], documentFrontFilename, {type: documentFront.type});
    let documentBackFile;
    if (documentBack !== null) {
      documentBackFile = new File([documentBack], documentBackFilename, { type: documentBack.type });
    }

    bodyFormData.append('documentFront', documentFrontFile);
    if (documentBack !== null) {
      bodyFormData.append('documentBack', documentBackFile);
    }
    bodyFormData.append('documentType', idType);
    bodyFormData.append('idType', documentType);

    return postRequest(constants.API_DOCUMENT_UPLOAD_URL, bodyFormData, constants.API_CONTENT_MULTIPART_FORM_DATA)
      .then((response) => {
        dispatch(documentUploadSuccessful(response.data));
      })
      .catch((error) => {
        let errorMessage = {
          msg: error.message,
          method: "Post Document"
        }
        dispatch(logMessage(SOURCE_USER_FLOW, LogUtil.ERROR, JSON.stringify(errorMessage)));
        dispatch(documentUploadFailure("Your ID failed to upload. Please try again."));
      });
  };
};
