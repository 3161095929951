const ConsentStyles = (theme) => ({
  content: {
    marginTop: theme.spacing(2),
  },
  header: {
    color: theme.palette.text.primary,
    paddingBottom: theme.spacing(1.6),
  },
  invalidDocument: {
    paddingBottom: theme.spacing(1),
  },
  note: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(3),
    fontSize: theme.typography.body2,
  },
  ul: {
    marginTop:theme.spacing(1),
    marginLeft: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  ol: {
    marginTop:theme.spacing(1),
    marginLeft: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  required: {
    color: "#F00",
  },
  a: {
    color: "#F00",
    textDecoration: "underline"
  },
  selectCountryHeader: {
    marginBottom: "10px",
  },
  embeddedVideo: {
    marginBottom: theme.spacing(3),
  },
  countryOptions: {
    marginBottom: theme.spacing(0),
  },
  idFormsHeader: {
    marginBottom: theme.spacing(2),
  },
});

export default ConsentStyles;
