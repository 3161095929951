import { Hidden } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const HideOnMobile = ({ children }) => {
  return (
    <Hidden xsDown>
      {children}
    </Hidden>
  );
};

HideOnMobile.propTypes = {
  children: PropTypes.any.isRequired,
};

export default HideOnMobile;
