import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import * as constants from "../../constants";
import MessageComponent from "./components/MessageComponent";
import { useDispatch, useSelector } from "react-redux";
import FooterComponent from "../commons/components/FooterComponent";
import PropTypes from "prop-types";
import { UNABLE_TO_REACH_IDENTITY_SERVER } from "../../store/reducers/StoreConstant";
import { setCurrentUploadVerified, setPollingStatus } from "../../store/reducers/userDetails";
import poll from "./poll";
import { SOURCE_USER_FLOW, TIMEOUT_ERROR } from "../../logger/logConstants";
import LogUtil from "../../logger/logUtil";

const FileUploadVerification =
  ({
     uploadStages,
     uploadThunks,
     uploadStateSelectors,
     uploadActions,
     logger,
     config
   }) => {
    const dispatch = useDispatch();
    const posting = useSelector(uploadStateSelectors.posting);
    const postingLiveCaptureBestPortrait = useSelector(uploadStateSelectors.postingLiveCaptureBestPortrait);
    const polling = useSelector(uploadStateSelectors.polling);
    const documentId = useSelector(uploadStateSelectors.documentId);
    const isCurrentUploadVerified = useSelector(uploadStateSelectors.isCurrentUploadVerified);
    const invalidOrNotVerifiedDocument = useSelector(uploadStateSelectors.invalidOrNotVerifiedDocument);

    const validationParameterSuccess = {
      validate: uploadActions.pollingSuccessfulValidator,
      validatorFunction: uploadActions.pollingSuccessAction
    };
    const validationParameterFailure = {
      validate: uploadActions.pollingFailureValidator,
      validatorFunction: uploadActions.pollingFailureAction
    };
    const getCurrentComponent = (step) => {
      let titleText;
      let isUploading;
      let descriptionText;
      let isPolling;
      let isFailure;
      let isSuccess;
      let invalidOrNVDocument;

      switch (step) {
        case uploadStages.uploadingFile.name :
          if (posting === false) {
            dispatch(uploadThunks.uploadFile());
          }
          titleText = uploadStages.uploadingFile.title;
          isUploading = true;
          break;
        case uploadStages.getLiveCaptureChallengeResult.name :
          if (posting === false) {
            dispatch(uploadThunks.getLiveCaptureChallengeResult());
          }
          titleText = uploadStages.getLiveCaptureChallengeResult.title;
          isPolling = true;
          break;
        case uploadStages.processLiveCaptureBestPortrait.name :
          if (postingLiveCaptureBestPortrait === false) {
            dispatch(uploadThunks.processLiveCaptureBestPortrait());
          }
          titleText = uploadStages.processLiveCaptureBestPortrait.title;
          isPolling = true;
          break;
        case uploadStages.pollingUploadedFile.name :
          if (polling === false) {
            dispatch(setPollingStatus(true));
            poll(uploadThunks.pollUploadedFile(), [validationParameterSuccess, validationParameterFailure],
              documentId, config.pollingTimeout)
              .then((responseData) => {
                dispatch(responseData.executeFunc(responseData.data))
              })
              .catch((errorStatus) => {
                let pollingError = {
                  msg: errorStatus.message,
                  method: "Poll uploaded file. " + TIMEOUT_ERROR
                }
                dispatch(uploadActions.setPollingTimedOut(errorStatus));
                dispatch(logger.log(SOURCE_USER_FLOW, LogUtil.ERROR, JSON.stringify(pollingError)));
              })
          }
          titleText = uploadStages.pollingUploadedFile.title;
          descriptionText = constants.POLLING_FILE_ID;
          isPolling = true;
          break;
        case uploadStages.uploadingFileFailed.name :
          titleText = uploadStages.uploadingFileFailed.title;
          isFailure = true;
          break;
        case uploadStages.fileUploadVerified.name :
          titleText = constants.POLLING_SUCCESSFUL_MESSAGE;
          isSuccess = true;
          descriptionText = uploadStages.fileUploadVerified.description;
          break;
        case uploadStages.pollingFailed.name:
          titleText = uploadStages.pollingFailed.title;
          isFailure = true;
          invalidOrNVDocument = invalidOrNotVerifiedDocument;
          break;
        case UNABLE_TO_REACH_IDENTITY_SERVER:
          let errorMessage = {
            msg: TIMEOUT_ERROR,
            method: "Poll uploaded file"
          }
          dispatch(logger.log(SOURCE_USER_FLOW, LogUtil.ERROR, JSON.stringify(errorMessage)));
          titleText = constants.UNABLE_TO_REACH_IDENTITY_SERVICE;
          isFailure = true;
          break;
        default:
          titleText = "Coming soon...";
      }
      return (
        <MessageComponent
          titleText={titleText}
          isFailure={isFailure}
          invalidOrNotVerifiedDocument={invalidOrNVDocument}
          descriptionText={descriptionText}
          isSuccess={isSuccess}
          isPolling={isPolling}
          isUploading={isUploading}
        />)
    };

    const uploadProgress = useSelector(uploadStateSelectors.uploadProgress);
    const handlePrevious = () => {
      dispatch(uploadActions.decrementActiveStepFileAction);
      dispatch(uploadActions.fileUploadBeginAfterFailure());
    };


    useEffect(() => {
      if (isCurrentUploadVerified) {
        setTimeout(() => dispatch(uploadActions.nextAction()), 1000);
        dispatch(setCurrentUploadVerified(false))
      }
    })

    return (
      <Grid container direction="column" alignItems="flex-start">
        {getCurrentComponent(uploadProgress)}
        <FooterComponent onPrevious={handlePrevious}
                         showPrevious={
                           (
                             uploadProgress === uploadStages.uploadingFileFailed.name ||
                             uploadProgress === UNABLE_TO_REACH_IDENTITY_SERVER
                           )
                         }
                         previousTestId={"upload-file-identity-server-previous-button"}
                         showNext={false}
                         isHalfWidth/>
      </Grid>
    );
  };

FileUploadVerification.propTypes = {
  uploadStages: PropTypes.object.isRequired,
  uploadThunks: PropTypes.object.isRequired,
  uploadStateSelectors: PropTypes.object.isRequired,
  uploadActions: PropTypes.object.isRequired,
  logger: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired
};

export default FileUploadVerification;

