import config from "../../config/config";
import {logMessage} from "../../store/api/logMessage";
import {SOURCE_USER_FLOW, TIMEOUT_ERROR} from "../../logger/logConstants";
import LogUtil from "../../logger/logUtil";

const poll = async (fn, validators, parameter, timeout) => {
  const endTime = (Number(new Date()) + timeout);
  let isPolling = true;
  const checkCondition = async (resolve, reject) => {
    let result = (parameter === undefined ? await fn() : await fn(parameter));
    if (result === undefined || result.error !== undefined){
      let errorMessage = {
        msg: TIMEOUT_ERROR,
        method: "Poll uploaded file."
      }
      logMessage(SOURCE_USER_FLOW, LogUtil.ERROR, JSON.stringify(errorMessage))
      return reject(new Error('Unable to reach identity id'));
    }
    await validators.forEach((validator) => {
      if (validator.validate(result.data)) {
        const responseData = {
          data: result.data,
          executeFunc: validator.validatorFunction
        };
        isPolling = false
        return resolve(responseData);
      }
    });
    if (isPolling) {
      if (Number(new Date()) < endTime) {
        setTimeout(checkCondition, Number(config.httpRequestParams.pollingInterval), resolve, reject);
      } else {
        logMessage(SOURCE_USER_FLOW, LogUtil.ERROR, "Verification status poll timed out");
        return reject(new Error('Timed out'));
      }
    }
  };

  return new Promise(checkCondition);
}

export default poll;
