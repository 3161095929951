import config from "../config/config";

export const PREVENT_FRAUD_DESCRIPTION = "To further prevent fraud, identity verification now requires a “live capture” image of your face " +
    "instead of a scanned or uploaded photo.";

export const NEW_REQUIREMENT_DESCRIPTION = "Due to this new requirement, identity verification must be done from a mobile device with a " +
    "built-in camera.";

export const ONLINE_VERIFICATION_HEADER = "For online verification, please login from your mobile device.";

export const SCAN_QR_CODE_TEXT = "Scan the QR code to the right, or visit the link below on your mobile device to verify your identity.";

export const UVOK_WEBSITE_LINK = config.auth.redirect_uri;

export const POST_VERIFICATION_STEPS_TEXT = "After you have verified your identity, return to this screen to continue.";

export const GO_TO_DASHBOARD_BUTTON_TEXT = "Continue";

export const CANT_VERIFY_ON_MOBILE_HEADER = "What if I can’t verify my identity on a mobile device?";

export const CONTACT_US_FOR_HELP = "If you are unable to verify your identity on a mobile device, please ";
export const CONTACT_US = "contact us";
export const CONTACT_US_LINK = "https://oklahoma.gov/oesc/about/contact-oesc.html";
export const PLEASE = "Please"
export const FOR_FURTHER_ASSISTANCE = " for further assistance."

export const VISIT_FREQUENTLY_ASKED_QUESTIONS = " For information about what’s required when verifying in-person, visit our "
export const FREQUENTLY_ASKED_QUESTIONS = "Frequently Asked Questions page.";
export const FREQUENTLY_ASKED_QUESTIONS_LINK = "https://oklahoma.gov/oesc/individuals/faqs.html#identity-verification";

