import * as constants from "../../constants";
import { getRequest } from "./api";
import { logMessage } from "./logMessage";
import { SOURCE_USER_FLOW } from "../../logger/logConstants";
import LogUtil from "../../logger/logUtil";
import {
  disableGettingLiveCaptureSessionId,
  liveCaptureSessionFailure, setLiveCapturePortraitId,
  setLiveCaptureSessionId,
} from "../reducers/userDetails";
import {
  setLiveCaptureVideoSession,
  setPreLiveCaptureInstruction,
} from "../reducers/userProgress";

export function initLiveCaptureSession() {
  return async (dispatch) => {
    return getRequest(constants.API_INIT_LIVE_SESSION_URL)
      .then((response) => {
        dispatch(setLiveCaptureSessionId(response.data));
        dispatch(setLiveCapturePortraitId(response.data));
        dispatch(setLiveCaptureVideoSession(true));
        dispatch(disableGettingLiveCaptureSessionId());
        dispatch(liveCaptureSessionFailure(null));
        dispatch(setPreLiveCaptureInstruction(false));
      })
      .catch((error) => {
        let errorMessage = {
          msg: error.message,
          method: "Get Live Capture Session Id",
        };
        dispatch(disableGettingLiveCaptureSessionId());
        dispatch(
          liveCaptureSessionFailure("Unable to connect. Please try again.")
        );
        dispatch(setPreLiveCaptureInstruction(true));
        dispatch(
          logMessage(
            SOURCE_USER_FLOW,
            LogUtil.ERROR,
            JSON.stringify(errorMessage)
          )
        );
        return { error: error };
      });
  };
}
