import React from "react";
import {Button,Container} from "@material-ui/core";
import styles from "../commons/styles/HeaderStyles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import * as constants from "../../constants";
import {Navbar,Nav} from 'react-bootstrap';
import OKLogo from './assets/OKLogo.png';
import { useHistory } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import {HeaderDHS} from "./HeaderDHS";
import {HeaderOESC} from "./HeaderOESC";
export const Header = () => {
  const classes = styles();
  const logoutLinkClasses = `${classes.navLink} ${classes.logoutLink}`;
  const isAuthenticated = useIsAuthenticated();
  let history = useHistory();

  const logout = () => {
    history.push('/logout');
  };

  const AGENCY = sessionStorage.getItem("agency") ?? "NONE";
  console.log("session storage agency = " + AGENCY);
  if (AGENCY === "OKDHS") {
    console.log("using DHS header");
    return <HeaderDHS />
  } else if (AGENCY === "OESC") {
    console.log("using OESC header");
    return <HeaderOESC />
  }

  return (
    <div id="header" className={classes.header}>
      <Container className={classes.container}>
        <Navbar collapseOnSelect expand="lg" className={classes.navbar}>
          <Navbar.Brand href="https://ui.ok.gov/en" className={classes.navbarBrand}>
            <img width="250" height="50" src={OKLogo} title="Oklahoma Employment Security Commission" alt="Oklahoma Employment Security Commission" data-testid="OKLogo"/>
          </Navbar.Brand>
          <Navbar.Toggle data-testid="navbar-toggler-icon" className={classes.navbarToggler} aria-controls="navbar-responsive-collapse" />
            <Navbar.Collapse id="navbar-responsive-collapse">
             <Nav className={classes.navbarNav}>
               { isAuthenticated &&
                <Nav.Link data-testid="logout-link" onClick={logout} className={logoutLinkClasses}>
                  <span>{constants.LOGOUT}</span>
                </Nav.Link>
                }
             </Nav>
             { isAuthenticated &&
              <Button className={classes.logout}
                      onClick={logout}
                      data-testid="logout-button"
                      endIcon={<ExitToAppIcon/>}>
                {constants.LOGOUT}
              </Button>
             }
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </div>
  );
};
