import store from "../store/store";

export default class LogUtil {
  static get DEBUG() {
    return "Debug";
  }

  static get INFORMATION() {
    return "Information";
  }

  static get TRACE() {
    return "Trace";
  }

  static get CRITIICAL() {
    return "Critical";
  }

  static get WARNING() {
    return "Warning";
  }

  static get ERROR() {
    return "Error";
  }

  static getConsentNextLog() {
    return JSON.stringify({
      countrySelected: store.getState().userProgress.consent.country,
      consentGiven: store.getState().userProgress.consent.consentChecked
    });
  }

  static getDocumentUploadLog() {
    const documentLogs = [
      {
        fileName: store.getState().userProgress.uploadDocument[store.getState().userProgress.idType].documentFront.fileName,
        fileType: store.getState().userProgress.uploadDocument[store.getState().userProgress.idType].documentFront.file.type,
        fileSize: store.getState().userProgress.uploadDocument[store.getState().userProgress.idType].documentFront.file.size,
        documentType: store.getState().userProgress.uploadDocument[store.getState().userProgress.idType].documentType,
        idType: store.getState().userProgress.idType
      }
    ]
    if ( store.getState().userProgress.uploadDocument[store.getState().userProgress.idType].documentBack.file !== null){
      documentLogs.push(
      {
        fileName: store.getState().userProgress.uploadDocument[store.getState().userProgress.idType].documentBack.fileName,
        fileType: store.getState().userProgress.uploadDocument[store.getState().userProgress.idType].documentBack.file.type,
        fileSize: store.getState().userProgress.uploadDocument[store.getState().userProgress.idType].documentBack.file.size,
        documentType: store.getState().userProgress.uploadDocument[store.getState().userProgress.idType].documentType,
        idType: store.getState().userProgress.idType
      });
    }
    return JSON.stringify(documentLogs);
  }

  static getIdSelectionLog() {
    return JSON.stringify({
      selectedId: store.getState().userProgress.idType
    });
  }

  static getPortraitUploadLog() {
    let portraitLog =
      {
        fileName: store.getState().userProgress.uploadPortrait.photo.fileName,
        fileType: store.getState().userProgress.uploadPortrait.photo.file.type,
        fileSize: store.getState().userProgress.uploadPortrait.photo.file.size,
      };
    return JSON.stringify(portraitLog);
  }
}
