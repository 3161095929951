import { getRequest } from "./api";
import {
  liveCapturePortraitStatusFailure,
} from "../reducers/userDetails";
import * as constants from "../../constants";
import store from "../store";
import { logMessage } from "./logMessage";
import { SOURCE_USER_FLOW } from "../../logger/logConstants";
import LogUtil from "../../logger/logUtil";
import { restartLiveCaptureVideoSession } from "../../components/liveCapture/RestartLiveCaptureVideoSession";
import { getEvidence } from "./getEvidence";

const checkAttemptsAndRestartLiveCapture = () => {
  const isLiveCaptureAttemptPending = store.getState().userDetails.apiData
    .liveCapture.isAttemptPending;

  if (isLiveCaptureAttemptPending) {
    restartLiveCaptureVideoSession();
    store.dispatch(
      logMessage(
        SOURCE_USER_FLOW,
        LogUtil.ERROR,
        constants.LIVE_CAPTURE_PORTRAIT_STATUS_FAILED_WITH_PENDING_RETRY_ATTEMPT
      ));
  } else {
    store.dispatch(
      logMessage(
        SOURCE_USER_FLOW,
        LogUtil.ERROR,
        constants.LIVE_CAPTURE_PORTRAIT_STATUS_AFTER_RETRY_ATTEMPTS
      ));
    store.dispatch(getEvidence());
  }
};

export const pollLiveCapturePortraitStatus = (idType) => () => {
  return async (documentId) => {

    const url = `documents/${idType}/${documentId}/status`;
    return getRequest(url)
      .then((response) => {
        if (response.data.status === constants.INVALID_UPLOAD_STATUS ||
          response.data.status === constants.NOT_VERIFIED_UPLOAD_STATUS) {
          checkAttemptsAndRestartLiveCapture();
        }
        return response;
      })
      .catch((error) => {
        let errorMessage = {
          msg: error.message,
          method: "Poll Live Capture Portrait Status"
        }
        store.dispatch(
          liveCapturePortraitStatusFailure(
            "Live Capture Portrait Status Result failed to fetch. Please try again"
          )
        );
        checkAttemptsAndRestartLiveCapture();
        store.dispatch(logMessage(SOURCE_USER_FLOW, LogUtil.ERROR, JSON.stringify(errorMessage)));

        return {
          data: {
            status: constants.INVALID_UPLOAD_STATUS,
          },
        };
      });
  }
}
