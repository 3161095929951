import React from "react";
import FooterStyles from "./styles/FooterStyles";
import {Container} from "@material-ui/core";
import * as constants from "../../constants";

export const Footer = () => {
  const classes = FooterStyles();
  return (
    <div>
      <footer id="global-footer" className={classes.footer}>
        <Container className={classes.container}>
        <div className={classes.sokContainer}>
          <div className={classes.footerRow}>
            <div className={classes.footerContent}>
              <a data-testid="accessibility"
                className={classes.footerLink}
                href={constants.ACCESSIBILITY_LINK}
                title="OK Accessibility Policy"
              >
                <span>{constants.ACCESSIBILITY}</span>
              </a>
              <a data-testid="policy"
                className={classes.footerLink}
                href={constants.POLICIES_LINK}
                title="Oklahoma Policies"
              >
                <span>{constants.POLICY}</span>
              </a>
            </div>
            <p data-testid="copyright" className={classes.footerCopyright}>{constants.COPYRIGHT()}</p>
          </div>
          <p data-testid="browser-compatibility" className={classes.browserCompatibility}>
            {constants.BROWSER_COMPATIBILITY}
            <br/>
            {constants.BROWSER_COMPATIBILITY_LIST}
          </p>
        </div>
        </Container>
      </footer>
    </div>
  );
};
