import store from "../../store/store";
import {
  liveCaptureBeginAfterFailure,
  setPollingStatus, setPostingLiveCaptureBestPortrait,
  setPostingLiveCaptureChallengeResult
} from "../../store/reducers/userDetails";
import {
  decrementActiveStepLiveCapturePortrait,
  setLiveCaptureChallengeFinished,
  setLiveCaptureVideoSession
} from "../../store/reducers/userProgress";
import { initLiveCaptureSession } from "../../store/api/initLiveCaptureSession";
import * as constants from "../../constants";
import { logMessage } from "../../store/api/logMessage";
import { SOURCE_USER_FLOW } from "../../logger/logConstants";
import LogUtil from "../../logger/logUtil";
import { getEvidence } from "../../store/api/getEvidence";


export const checkAttemptsAndRestartLiveCaptureVideoSession = (dispatch, getState, restartLiveCaptureProps) => {
  const isLiveCaptureAttemptPending = getState().userDetails.apiData
    .liveCapture.isAttemptPending;
  dispatch(restartLiveCaptureProps.attemptsFailureReducer);

  if (isLiveCaptureAttemptPending) {
    restartLiveCaptureVideoSession();
    dispatch(
      logMessage(
        SOURCE_USER_FLOW,
        LogUtil.ERROR,
        restartLiveCaptureProps.pendingAttemptLogMessage
      ));
  } else {
    dispatch(
      logMessage(
        SOURCE_USER_FLOW,
        LogUtil.ERROR,
        restartLiveCaptureProps.attemptsOverLogMessage
      ));
    dispatch(restartLiveCaptureProps.setPostingFlagReducer);
    dispatch(getEvidence());
  }
};

export const restartLiveCaptureVideoSession = () => {
  setTimeout(() => {
    store.dispatch(setPollingStatus(false));
    store.dispatch(setLiveCaptureChallengeFinished(false));
    store.dispatch(setLiveCaptureVideoSession(false));
    store.dispatch(initLiveCaptureSession());
    store.dispatch(decrementActiveStepLiveCapturePortrait());
    store.dispatch(setPostingLiveCaptureChallengeResult(false));
    store.dispatch(setPostingLiveCaptureBestPortrait(false));
    store.dispatch(liveCaptureBeginAfterFailure());
  }, constants.LIVE_CAPTURE_RETRY_DELAY_MS);
};
