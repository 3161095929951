const livenessSelectionStyles = () => ({
  captureLiveButton: {
    padding: "11px 23px",
    backgroundColor: "#DF933A",
    color: "white",
    fontFamily: "Arial",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  displayNoneFadeout: {
    opacity: "0",
    transition: "opacity 500ms ease-out",
  },
  videoOverlay:{
    position: "absolute",
    top: "0" , /* high of header */
    zIndex: "99",
    display: "flex",
    justifyContent: "center",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    transition: "opacity 1s ease-in"
  }
});

export default livenessSelectionStyles;
