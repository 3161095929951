import { makeStyles } from "@material-ui/core";

const VerifyStyles = makeStyles((theme) => ({
    header: {
        color: theme.palette.text.primary,
    },
    container: {
        padding: theme.spacing(3),
        [theme.breakpoints.up("sm")]: {
            minHeight: "calc(92vh - 122px)"
        },
        minHeight: "calc(100vh - 152px)",
    },
}));

export default VerifyStyles;