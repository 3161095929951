import React from 'react';
import { Header } from "./commons/Header";
import { Footer } from "./commons/Footer";
import { Container } from "@material-ui/core";
import styles from "./verify/styles/VerifyStyles";
import * as constants from "../constants";
import LoginButton from "./LoginButton";


const Timeout = () => {
  const classes = styles();
  return (
    <div data-testid="timeout">
      <Header />
      <Container className={classes.container}>
        <p>{constants.TIMEOUT_MESSAGE}</p>
        <LoginButton />
      </Container>
      <Footer />
    </div>
  );
}

export default Timeout;
