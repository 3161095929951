import * as constants from "../../constants";
import { getRequest } from "./api";
import { logMessage } from "./logMessage";
import { SOURCE_USER_FLOW } from "../../logger/logConstants";
import LogUtil from "../../logger/logUtil";

export function getEvidence() {
  return async (dispatch) => {
    return await getRequest(constants.API_EVIDENCE_URL)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errorMessage = {
          msg: error.message,
          method: "Get Evidence"
        }
        dispatch(logMessage(SOURCE_USER_FLOW, LogUtil.ERROR, JSON.stringify(errorMessage)));
        return { error: error };
      });
  }
}
