import { createSlice } from "@reduxjs/toolkit";
import {
  DOCUMENT_UPLOAD_VERIFIED,
  POLLING_LOA_STATUS,
  POLLING_LOA_STATUS_FAILED,
  POLLING_PORTRAIT_FAILED,
  POLLING_UPLOADED_DOCUMENT,
  POLLING_UPLOADED_DOCUMENT_FAILED,
  POLLING_UPLOADED_PORTRAIT,
  UNABLE_TO_REACH_IDENTITY_SERVER,
  UPLOADING_DOCUMENT,
  UPLOADING_PORTRAIT,
  ADEQUATE_LOA,
  INADEQUATE_LOA,
  UPLOADING_DOCUMENT_FAILED,
  UPLOADING_PORTRAIT_FAILED,
  PORTRAIT_UPLOAD_VERIFIED,
  LOA_STATUS_VERIFIED,
  POLLING_LIVE_CAPTURE_PORTRAIT,
  POLLING_LIVE_CAPTURE_CHALLENGE_FAILED,
  GET_LIVE_CAPTURE_CHALLENGE_RESULT,
  PROCESS_LIVE_CAPTURE_BEST_PORTRAIT,
  POLLING_LIVE_CAPTURE_CHALLENGE_SUCCESSFUL,
} from "./StoreConstant";

const initialState = {
  verificationToken: null,
  unauthorized: null,
  errors: {
    consent: null,
    documentUpload: null,
    portraitUpload: null,
    polling: null,
    pollingPortrait: null,
    pollingLOAStatus: null,
    consentText: null,
    liveCaptureSession: null,
    liveCaptureChallengeResult: null,
    pollingLiveCaptureChallenge: null,
    liveCapturePortraitStatus: null,
    liveCaptureBestPortrait: null,
  },
  apiStatus: {
    postingConsent: false,
    postingDocument: false,
    postingPortrait: false,
    polling: false,
    gettingSessionId: false,
    gettingLiveCaptureChallengeResult: false,
    postingLiveCaptureChallengeResult: false,
    postingLiveCaptureBestPortrait: false,
  },
  apiData: {
    documentUpload: {
      id: null,
      status: null,
      uploadProgress: UPLOADING_DOCUMENT,
    },
    portraitUpload: {
      id: null,
      status: null,
      uploadProgress: UPLOADING_PORTRAIT,
    },
    LOA: {
      status: null,
      progress: POLLING_LOA_STATUS,
    },
    liveCapture: {
      status: null,
      verificationProgress: GET_LIVE_CAPTURE_CHALLENGE_RESULT,
      isLiveCaptureChallengeSuccess: false,
      sessionId: null,
      portraitId: null,
      numberOfAttempts: 0,
      isAttemptPending: false,
    },
    externalConfig: {
      consentText: null,
      maxImageUploadSizeInMb: null,
      maxImageUploadSizeInBytes: null,
    },
    isCurrentUploadVerified: false,
    invalidOrNotVerifiedDocument: true,
  },
};

const userDetails = createSlice({
  name: "userDetails",
  initialState: initialState,
  reducers: {
    isUnauthorized: (state) => {
      state.unauthorized = true;
    },
    postingConsent: (state) => {
      state.apiStatus.postingConsent = true;
    },
    consentSuccessful: (state, { payload }) => {
      state.verificationToken = payload.verificationToken;
      state.apiStatus.postingConsent = false;
    },
    consentFailure: (state, { payload }) => {
      state.apiStatus.postingConsent = false;
      state.errors.consent = payload;
    },
    postingDocument: (state) => {
      state.apiStatus.postingDocument = true;
    },
    postingPortrait: (state) => {
      state.apiStatus.postingPortrait = true;
    },
    gettingSessionId: (state) => {
      state.apiStatus.gettingSessionId = true;
    },
    documentUploadBeginAfterFailure: (state) => {
      state.apiData.documentUpload.uploadProgress = UPLOADING_DOCUMENT;
    },
    portraitUploadBeginAfterFailure: (state) => {
      state.apiData.portraitUpload.uploadProgress = UPLOADING_PORTRAIT;
    },
    documentUploadSuccessful: (state, { payload }) => {
      state.apiData.documentUpload.id = payload.documentId;
      state.apiStatus.postingDocument = false;
      state.apiData.documentUpload.uploadProgress = POLLING_UPLOADED_DOCUMENT;
    },
    documentUploadFailure: (state, { payload }) => {
      state.apiStatus.postingDocument = false;
      state.errors.documentUpload = payload;
      state.apiData.documentUpload.uploadProgress = UPLOADING_DOCUMENT_FAILED;
    },
    portraitUploadSuccessful: (state, { payload }) => {
      state.apiData.portraitUpload.id = payload.documentId;
      state.apiStatus.postingPortrait = false;
      state.apiData.portraitUpload.uploadProgress = POLLING_UPLOADED_PORTRAIT;
    },
    portraitUploadFailure: (state, { payload }) => {
      state.apiStatus.postingPortrait = false;
      state.errors.portraitUpload = payload;
      state.apiData.portraitUpload.uploadProgress = UPLOADING_PORTRAIT_FAILED;
    },
    pollingSuccessFul: (state, { payload }) => {
      state.apiStatus.polling = false;
      state.apiData.documentUpload.status = payload.status;
      state.apiData.documentUpload.uploadProgress = DOCUMENT_UPLOAD_VERIFIED;
    },
    pollingPortraitSuccessful: (state, { payload }) => {
      state.apiStatus.polling = false;
      state.apiData.portraitUpload.status = payload.status;
      state.apiData.portraitUpload.uploadProgress = PORTRAIT_UPLOAD_VERIFIED;
    },
    pollingFailure: (state, { payload }) => {
      state.apiStatus.polling = false;
      state.errors.polling = payload;
      state.apiData.documentUpload.status = payload.status;
      state.apiData.documentUpload.uploadProgress = POLLING_UPLOADED_DOCUMENT_FAILED;
      state.apiData.invalidOrNotVerifiedDocument = true;
    },
    pollingPortraitFailure: (state, { payload }) => {
      state.apiStatus.polling = false;
      state.errors.polling = payload;
      state.apiData.portraitUpload.uploadProgress = POLLING_PORTRAIT_FAILED;
      state.apiData.portraitUpload.status = payload.status;
      state.apiData.invalidOrNotVerifiedDocument = true;
    },
    unableToReachIdentityServer: (state, { payload }) => {
      state.apiStatus.polling = false;
      state.errors.polling = payload;
      state.apiData.documentUpload.uploadProgress = UNABLE_TO_REACH_IDENTITY_SERVER;
    },
    unableToReachIdentityServerPortrait: (state, { payload }) => {
      state.apiStatus.polling = false;
      state.errors.pollingPortrait = payload;
      state.apiData.portraitUpload.uploadProgress = UNABLE_TO_REACH_IDENTITY_SERVER;
    },
    unableToReachIdentityServerLiveCapture: (state, { payload }) => {
      state.apiStatus.polling = false;
      state.errors.pollingLiveCaptureChallenge = payload;
      state.apiData.liveCapture.verificationProgress = UNABLE_TO_REACH_IDENTITY_SERVER;
    },
    pollingLOAStatusSuccessful: (state, { payload }) => {
      state.apiStatus.polling = false;
      state.apiData.LOA.status = payload.status;
      state.apiData.LOA.progress =
        payload.status === LOA_STATUS_VERIFIED ? ADEQUATE_LOA : INADEQUATE_LOA;
    },
    pollingLOAStatusFailure: (state, { payload }) => {
      state.apiStatus.polling = false;
      state.errors.pollingLOAStatus = payload;
      state.apiData.LOA.progress = POLLING_LOA_STATUS_FAILED;
    },
    resetLoaStatus: (state, { payload }) => {
      state.apiData.LOA = {
        status: null,
        progress: POLLING_LOA_STATUS,
      };
      state.apiStatus.polling = false;
      state.errors.pollingLOAStatus = null;
    },
    resetDocumentUploadData: (state) => {
      state.apiData.documentUpload = {
        id: null,
        status: null,
        uploadProgress: UPLOADING_DOCUMENT,
      };
      state.apiStatus.polling = false;
      state.apiStatus.postingDocument = false;
      state.errors.documentUpload = null;
    },
    setCurrentUploadVerified: (state, { payload }) => {
      state.apiData.isCurrentUploadVerified = payload;
    },
    setPollingStatus: (state, { payload }) => {
      state.apiStatus.polling = payload;
    },
    setConsentText: (state, { payload }) => {
      state.apiData.externalConfig.consentText = payload.text;
    },
    consentTextFailure: (state, { payload }) => {
      state.errors.consentText = payload;
    },
    setLiveCaptureSessionId: (state, { payload }) => {
      state.apiStatus.gettingSessionId = false;
      state.apiData.liveCapture.sessionId = payload.sessionId;
    },
    setLiveCapturePortraitId: (state, { payload }) => {
      state.apiData.liveCapture.portraitId = payload.id;
    },
    liveCaptureSessionFailure: (state, { payload }) => {
      state.apiStatus.gettingSessionId = false;
      state.errors.liveCaptureSession = payload;
    },
    disableGettingLiveCaptureSessionId: (state) => {
      state.apiStatus.gettingSessionId = false;
    },
    setGettingLiveCaptureChallengeResult: (state, { payload }) => {
      state.apiStatus.gettingLiveCaptureChallengeResult = payload;
    },
    setPostingLiveCaptureChallengeResult: (state, { payload }) => {
      state.apiStatus.postingLiveCaptureChallengeResult = payload;
    },
    setPostingLiveCaptureBestPortrait: (state, { payload }) => {
      state.apiStatus.postingLiveCaptureBestPortrait = payload;
    },
    liveCaptureChallengeFailure: (state, { payload }) => {
      state.apiStatus.gettingLiveCaptureChallengeResult = false;
      state.errors.liveCaptureChallengeResult = payload;
      state.apiData.liveCapture.isLiveCaptureChallengeSuccess = false;
    },
    liveCapturePortraitStatusFailure: (state, {payload}) => {
      state.errors.liveCapturePortraitStatus = payload;
    },
    setIsLiveCaptureChallengeSuccess: (state, { payload }) => {
      state.apiData.liveCapture.isLiveCaptureChallengeSuccess = payload;
    },
    setLiveCaptureChallengeResultSuccess: (state) => {
      state.apiData.liveCapture.verificationProgress = POLLING_LIVE_CAPTURE_PORTRAIT;
    },
    setLiveCaptureChallengeResultFailure: (state, {payload}) => {
      state.apiData.liveCapture.verificationProgress = POLLING_LIVE_CAPTURE_CHALLENGE_FAILED;
      state.apiData.liveCapture.status = payload.status;
      state.apiData.invalidOrNotVerifiedDocument = !state.apiData.liveCapture
        .isAttemptPending;
    },
    pollingLiveCaptureChallengeSuccess: (state, { payload }) => {
      state.apiStatus.polling = false;
      state.apiData.liveCapture.status = payload.status;
      state.apiData.liveCapture.verificationProgress = PROCESS_LIVE_CAPTURE_BEST_PORTRAIT;
    },
    pollingLiveCaptureChallengeFailure: (state, { payload }) => {
      state.apiStatus.polling = false;
      state.errors.polling = payload;
      state.apiData.liveCapture.verificationProgress = POLLING_LIVE_CAPTURE_CHALLENGE_FAILED;
      state.apiData.liveCapture.status = payload.status;
      state.apiData.invalidOrNotVerifiedDocument = !state.apiData.liveCapture
        .isAttemptPending;
    },
    processLiveCaptureBestPortraitSuccess: (state) => {
      state.apiData.liveCapture.verificationProgress = POLLING_LIVE_CAPTURE_CHALLENGE_SUCCESSFUL;
    },
    processLiveCaptureBestPortraitFailure: (state, {payload}) => {
      state.apiData.liveCapture.verificationProgress = POLLING_LIVE_CAPTURE_CHALLENGE_FAILED;
      state.apiData.liveCapture.status = payload.status;
      state.apiData.invalidOrNotVerifiedDocument = !state.apiData.liveCapture
        .isAttemptPending;
    },
    liveCaptureBestPortraitFailure: (state, {payload}) => {
      state.errors.liveCaptureBestPortrait = payload;
    },
    liveCaptureBeginAfterFailure: (state) => {
      state.apiData.liveCapture.verificationProgress = GET_LIVE_CAPTURE_CHALLENGE_RESULT;
    },
    incrementLiveCaptureAttempts: (state) => {
      state.apiData.liveCapture.numberOfAttempts =
        state.apiData.liveCapture.numberOfAttempts + 1;
    },
    setIsLiveCaptureAttemptPending: (state, { payload }) => {
      state.apiData.liveCapture.isAttemptPending = payload;
    },
    setUploadConfig: (state, { payload }) => {
      state.apiData.externalConfig.maxImageUploadSizeInMb =
        payload.maxImageUploadSizeInMb;
      state.apiData.externalConfig.maxImageUploadSizeInBytes =
        payload.maxImageUploadSizeInBytes;
    },
  },
});

export const {
  postingConsent,
  consentSuccessful,
  consentFailure,
  postingDocument,
  documentUploadFailure,
  documentUploadBeginAfterFailure,
  documentUploadSuccessful,
  pollingSuccessFul,
  pollingFailure,
  unableToReachIdentityServer,
  unableToReachIdentityServerPortrait,
  unableToReachIdentityServerLiveCapture,
  postingPortrait,
  portraitUploadSuccessful,
  portraitUploadFailure,
  portraitUploadBeginAfterFailure,
  pollingPortraitSuccessful,
  pollingPortraitFailure,
  setPollingStatus,
  pollingLOAStatusSuccessful,
  pollingLOAStatusFailure,
  resetDocumentUploadData,
  resetLoaStatus,
  setCurrentUploadVerified,
  setConsentText,
  consentTextFailure,
  setLiveCaptureSessionId,
  setLiveCapturePortraitId,
  liveCaptureSessionFailure,
  gettingSessionId,
  disableGettingLiveCaptureSessionId,
  setGettingLiveCaptureChallengeResult,
  setPostingLiveCaptureChallengeResult,
  setPostingLiveCaptureBestPortrait,
  processLiveCaptureBestPortraitSuccess,
  processLiveCaptureBestPortraitFailure,
  liveCaptureBestPortraitFailure,
  liveCaptureChallengeFailure,
  liveCapturePortraitStatusFailure,
  setIsLiveCaptureChallengeSuccess,
  setLiveCaptureChallengeResultSuccess,
  setLiveCaptureChallengeResultFailure,
  pollingLiveCaptureChallengeSuccess,
  pollingLiveCaptureChallengeFailure,
  liveCaptureBeginAfterFailure,
  incrementLiveCaptureAttempts,
  setIsLiveCaptureAttemptPending,
  setUploadConfig,
  isUnauthorized
} = userDetails.actions;

export default userDetails.reducer;
