import { consentFailure, consentSuccessful, postingConsent, } from "../reducers/userDetails";
import { incrementActiveStep, setEnableDocumentUpload, } from "../reducers/userProgress";
import { postRequest } from "./api";
import store from "../store";
import * as constants from "../../constants";
import { logMessage } from "./logMessage";
import LogUtil from "../../logger/logUtil";
import { SOURCE_USER_FLOW } from "../../logger/logConstants";

export function postConsent() {
  return async () => {
    store.dispatch(postingConsent());
    const url = constants.API_CONSENT_URL;
    return postRequest(url, null, constants.API_CONTENT_APPLICATION_JSON)
      .then((response) => {
        store.dispatch(consentSuccessful(response.data));
        store.dispatch(consentFailure(null));
        store.dispatch(incrementActiveStep());
      })
      .catch((error) => {
        let errorMessage = {
          msg: error.message,
          method: "Post Consent"
        }
        store.dispatch(logMessage(SOURCE_USER_FLOW, LogUtil.ERROR, JSON.stringify(errorMessage)));
        store.dispatch(consentFailure("something went wrong"));
        store.dispatch(setEnableDocumentUpload(true));
      });
  };
}
