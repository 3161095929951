const TextStyles = ((theme) => ({
  greenText: {
    color: "#50AF05",
    fontWeight: "bold",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  redText: {
    color: "#BC0B00",
    fontWeight: "bold",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  successText: {
    fontWeight: "bold",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  descriptionText: {
    fontWeight: "bold",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}));

export default TextStyles;
