import React from "react";
import { useSelector } from "react-redux";
import styles from "./styles/liveCaptureInstruction";
import { Typography, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const LiveCaptureInstruction = ({ classes }) => {
  const liveCaptureInstruction = useSelector(
    (state) => state.userProgress.liveCapture.liveCaptureInstruction
  );
  const isStreaming = useSelector((state) => state.userProgress.liveCapture.isStreaming);

  return (
    <>
      {liveCaptureInstruction && isStreaming && (
        <div
          data-testid="live-capture-instruction"
          className={classes.videoOverlay}>
          <Typography className={classes.videoMessageCenter}>
            {liveCaptureInstruction}
          </Typography>
        </div>
      )}
    </>
  );
};

LiveCaptureInstruction.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(LiveCaptureInstruction);
