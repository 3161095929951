import { Button, withStyles } from "@material-ui/core";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import React from "react";
import livenessSelectionStyles from "./styles/livenessSelectionStyles";
import PropTypes from "prop-types";
import {
  setPreLiveCaptureInstruction,
  startLiveCapture
} from "../../store/reducers/userProgress";
import { useDispatch } from "react-redux";
import * as constants from "../../constants";

const LiveCaptureSelection = ({classes}) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(startLiveCapture(true));
    dispatch(setPreLiveCaptureInstruction(true));
  }

  return (
      <Button
        variant="outlined"
        className={classes.captureLiveButton}
        aria-label="capture live picture"
        startIcon={<CameraAltIcon/>}
        data-testid="capture-live-photo-button"
        component="span"
        onClick={handleClick}
      >
        {constants.CAPTURE_LIVE_BUTTON}
      </Button>
  );
};

LiveCaptureSelection.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(livenessSelectionStyles, { withTheme: true })(LiveCaptureSelection);
