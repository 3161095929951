import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest } from "./../authConfig"

export const b2cLogin = async (pca) => {
  return await pca.loginRedirect(loginRequest);
}

const Login = () => {
  const { instance, inProgress } = useMsal();

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      b2cLogin(instance);
    }
  }, [instance, inProgress]);

  return null;
}

export default Login;
