import { Grid, Typography, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import styles from "../styles/TextStyles";
import { PollingAnimation } from "./PollingAnimation";
import FailureLogo from '../assets/failure.png';
import SuccessLogo from '../assets/success.png';
import WarningLogo from '../assets/warning.png';
import { UploadAnimation } from "./UploadAnimation";
import UnverifiedUpload from "./UnverifiedUpload";

function getImageFor(props) {
  if (props.isFailure) {
    return FailureLogo;
  } else if (props.isSuccess) {
    return SuccessLogo;
  } else {
    return WarningLogo;
  }
}

function getAccessoryView(props) {
  if (props.isPolling || props.isPollingLoa) {
    return (<Grid item data-testid="file-polling-animation" md={6} sm={12}>
      <PollingAnimation />
    </Grid>)
  } else if (props.isUploading) {
    return (<Grid item data-testid="file-polling-animation" md={6} sm={12}>
      <UploadAnimation /></Grid>)
  } else if (props.isFailure || props.isSuccess || props.isWarning) {
    return (<Grid item data-testid="content-test-image">
      <img
        src={getImageFor(props)}
        width="53"
        height="53"
        alt="failure"
      />
    </Grid>)
  }
}

function getTextStyles(props) {
  if (props.isPolling || props.isSuccess) {
    return props.classes.greenText;
  } else if (props.isFailure || props.isWarning) {
    return props.classes.redText
  }
}

export const MessageComponent = (props) => {
  return (
    <div data-testid="polling-component">
      <Grid item>
        {props.isUploading ? (
          <Typography data-testid="title-text">
            {props.titleText}
          </Typography>
        ) : (
          <h6 className={getTextStyles(props)} data-testid="title-text">
            {props.titleText}
          </h6>
        )}
        {
          props.descriptionText != null ?
            (<Typography data-testid="description-text" className={props.classes.descriptionText}>
              {props.descriptionText}
            </Typography>)
            : null
        }
      </Grid>
      <br />
      <div>
        {getAccessoryView(props)}
      </div>
      <br />
      <div>
        {props.invalidOrNotVerifiedDocument ? (
          <UnverifiedUpload />
        ) : null}
      </div>
    </div>
  )
};

MessageComponent.propTypes = {
  isPolling: PropTypes.bool,
  isPollingLoa: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isFailure: PropTypes.bool,
  isWarning: PropTypes.bool,
  isUploading: PropTypes.bool,
  invalidOrNotVerifiedDocument: PropTypes.bool,
  titleText: PropTypes.string.isRequired,
  descriptionText: PropTypes.string,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(MessageComponent);
