import { portraitUploadFailure, portraitUploadSuccessful, postingPortrait } from "../reducers/userDetails";
import { postRequest } from "./api";
import * as constants from "../../constants";
import { logMessage } from "./logMessage";
import { SOURCE_ANALYTICS, SOURCE_USER_FLOW } from "../../logger/logConstants";
import LogUtil from "../../logger/logUtil";

const postPortrait = () => {
  return async (dispatch, getState) => {
    const portrait = getState().userProgress.uploadPortrait.photo.file;
    const portraitFilename = getState().userProgress.uploadPortrait.photo.fileName;

    dispatch(postingPortrait());
    logMessage(SOURCE_ANALYTICS, LogUtil.INFORMATION, JSON.stringify(LogUtil.getPortraitUploadLog()))
    let portraitFile = new File([portrait], portraitFilename, {type: portrait.type});
    let bodyFormData = new FormData();

    bodyFormData.append('portrait', portraitFile);

    return postRequest(constants.API_PORTRAIT_UPLOAD_URL, bodyFormData, constants.API_CONTENT_MULTIPART_FORM_DATA)
      .then((response) => {
        dispatch(portraitUploadSuccessful(response.data));
      })
      .catch((error) => {
        let errorMessage = {
          msg: error.message,
          method: "Post Portrait"
        }
        dispatch(logMessage(SOURCE_USER_FLOW, LogUtil.ERROR, JSON.stringify(errorMessage)));
        dispatch(portraitUploadFailure("Photo upload failed. Please try again"));
      });
  };
};

export default postPortrait;
