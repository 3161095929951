import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { LOGOUT as LOGOUT_TEXT } from "../constants";

const LogoutButton = (props) => {
  let history = useHistory();

  const handleLogoutClick = () => {
    history.push('/logout');
  }

  return (
    <Button onClick={handleLogoutClick} variant="outlined" data-testid="logout-button">
      {LOGOUT_TEXT}
    </Button>
  );
};

export default LogoutButton;
