export const CONSENT_HEADER = "HOW TO VERIFY YOUR IDENTITY";
export const CONSENT_VERIFY_IDENTITY = [
  "Upload a photo of the front and back of your ID[s]. Be sure to use the accepted forms of ID listed below.",
  "Upload a photo of your face to validate the photo in your ID matches. This can be a profile picture as long as it includes just your face.​",
  "In some cases, you may need to upload an additional ID.",
];
export const CONSENT_SOFTWARE_UPDATE_REMINDER = "For the best experience and to avoid potential issues, please use the latest version of your operating system and browser.";
export const CONSENT_ACCEPTED_FORMS_HEADER = "ACCEPTED FORMS OF ID";
export const CONSENT_ACCEPTED_IDS = [
  "Driver’s License from any US State",
  "State Issued ID",
  "Resident / Green Card",
  "US Passport (only as an additional ID)",
];
export const CONSENT_UNSUPPORTED_FORMS_HEADER = "UNSUPPORTED FORMS OF ID";
export const CONSENT_UNSUPPORTED_IDS = [
  "ID not issued by the United States",
  "Letter of introduction",
  "ID without a photo",
  "Military IDs ",
];
export const CONSENT_NOTE = "Note: ";
export const CONSENT_DOCUMENTS_NOTE =
  "Documents used to verify your employment will not be accepted as proof of identification.";
export const CONSENT_SELECT_COUNTRY = "What country is the identification from?";
export const CONSENT_COUTRY_USA = "USA";
export const CONSENT_COUTRY_OTHER = "Other";
export const CONSENT_COUNTRY_OPTIONS = [CONSENT_COUTRY_USA, CONSENT_COUTRY_OTHER];
export const CONSENT_VERBOSE_HEADER = "Consent";
export const CONSENT_VERBOSE_TERMS =
  "The online claims filing platform you are using to file your unemployment claim has been developed and maintained by Thoughtworks, Inc. All information you provide in creating your on-line unemployment claimant’s account and in filing your unemployment claim with the Oklahoma Employment Security Commission will be shared with Granicus, Inc. In the process of filing your claim, you will be asked to provide personally identifiable information (PII).";
export const CONSENT_BUTTON_VERIFY_IN_PERSON = "I want to verify in person";
export const CONSENT_BUTTON_HELP_UPLOADING_PHOTOS =
  "I need help uploading photos";

export const CONSENT_NON_US_ID = "Sorry, we can only verify US documents at this time. Please ";
export const CONTACT_US = "contact us";
export const FOR_FURTHER_ASSISTANCE = " for further assistance"
export const CONTACT_US_LINK = "https://oklahoma.gov/oesc/about/contact-oesc.html";
export const PLEASE = "Please "

export const VISIT_FREQUENTLY_ASKED_QUESTIONS = ". For information about what’s required when verifying in-person, visit our "
export const FREQUENTLY_ASKED_QUESTIONS = "Frequently Asked Questions page.";
export const FREQUENTLY_ASKED_QUESTIONS_LINK = "https://oklahoma.gov/oesc/individuals/faqs.html#identity-verification";

