export const PRE_LIVE_CAPTURE_ANIMATION_INFO = "For this task, you will need to center your face";
export const POLLING_RETRY_LIVE_CAPTURE_CHALLENGE_RESULT = "Your identity could not be verified based on the live capture provided. Please try again.";
export const POLLING_FAILED_LIVE_CAPTURE_CHALLENGE_RESULT = "Your identity could not be verified based on the live capture provided.";
export const CAPTURE_LIVE_BUTTON = "Take Picture";
export const READY_LIVE_CAPTURE_BUTTON = "I'M READY";
export const GO_BACK_BUTTON = "GO BACK";
export const SUCCESS_LIVE_CAPTURE_CHALLENGE_FINISHED = "Thank you.";
export const LIVE_CAPTURE_ATTEMPTS_THRESHOLD = 2;
export const LIVE_CAPTURE_SESSION_NOT_FOUND_ERROR_CODE = 404;
export const LIVE_CAPTURE_RETRY_DELAY_MS = 2000;
export const LIVE_CAPTURE_BIO_SERVER_TIMEOUT = 42000;
export const LIVE_CAPTURE_RENDER_STREAM_AFTER_MS = 2000;
export const LIVE_CAPTURE_CHALLENGE_RESULT_ERROR_WITH_PENDING_RETRY_ATTEMPT = "Live capture challenge result failed. New live stream was initiated.";
export const LIVE_CAPTURE_PORTRAIT_STATUS_FAILED_WITH_PENDING_RETRY_ATTEMPT = "Live capture portrait status failed. New live stream was initiated.";
export const LIVE_CAPTURE_PROCESS_BEST_PORTRAIT_FAILED_WITH_PENDING_RETRY_ATTEMPT = "Live capture process best portrait failed. New live stream was initiated.";
export const LIVE_CAPTURE_CHALLENGE_RESULT_ERROR_AFTER_RETRY_ATTEMPTS = "Live capture challenge result failed. User was directed to office.";
export const LIVE_CAPTURE_PORTRAIT_STATUS_AFTER_RETRY_ATTEMPTS = "Live capture portrait status failed. User was directed to office.";
export const LIVE_CAPTURE_PROCESS_BEST_PORTRAIT_AFTER_RETRY_ATTEMPTS = "Live capture process best portrait failed. User was directed to office.";
export const LIVE_CAPTURE_VIDEO_PERMISSION_DENIED_ERROR = "NotAllowedError"
export const LIVE_CAPTURE_VIDEO_PERMISSION_DENIED_MESSAGE = "To continue, you'll need to allow camera access in your device settings."
export const LIVE_CAPTURE_BIO_SERVER_CONNECTION_SEVERED_MESSAGE = "Due to network issues, your submission could not be processed. Please try again."
