const liveCaptureVideoSession = () => ({
  mirrorImage: {
    transform: "scaleX(-1)",
    background: "white",
  },
  dimensions: {
    width: window.innerWidth,
    height: window.innerHeight,
  }
});

export default liveCaptureVideoSession;
