export const GetOperatingSystem = () => {
  const userAgent = window.navigator.userAgent;
  let os = undefined;

  if (/iPhone/.test(userAgent)) {
    os = 'iOS';
  } else if (/Android/.test(userAgent)) {
    os = 'android';
  }
  return os;
};