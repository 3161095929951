import React from "react";
import { Button, Grid, Typography, withStyles } from "@material-ui/core";
import VerifiedLogo from "./assets/verified.png"
import styles from "../commons/styles/TextStyles";
import FooterComponent from "../commons/components/FooterComponent";
import { CLICK_FINISH_TO_CONTINUE_TEXT, FINISH_TEXT, DEFAULT_RETURN_URL, USER_VERIFIED_DESCRIPTION_TEXT, USER_VERIFIED_TITLE_TEXT } from "../../constants/userVerified";
import { useDispatch } from "react-redux";
import { incrementActiveStep } from "../../store/reducers/userProgress";
import PropTypes from "prop-types";

const UserVerified = (classes) => {
  const dispatch = useDispatch();

  const handleNext = () => {
    dispatch(incrementActiveStep());
  };

  const AFTER_VERIFY_URL = sessionStorage.getItem("afterVerifyURL") ?? (sessionStorage.getItem("returnURL") ?? DEFAULT_RETURN_URL);

  return (
    <Grid item container direction="column" spacing={2} data-testid="user_verified">
      <br/>
      <Grid item>
        <Typography data-testid="title-text">
          {USER_VERIFIED_TITLE_TEXT}
        </Typography>
      </Grid>
      <br/>
      <Grid item data-testid="verified_content_test_image">
        <img
          src={VerifiedLogo}
          width="87"
          height="87"
          alt="verified"
        />
      </Grid>
      <br/>
      <Grid item>
        <Typography className={classes.greenText} variant={"h5"} data-testid="description_bold_text">
          {USER_VERIFIED_DESCRIPTION_TEXT}
        </Typography>
      </Grid>
      <Grid item>
        <Typography data-testid="click-finish-text">
          {CLICK_FINISH_TO_CONTINUE_TEXT}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          data-testid="finish-button"
          variant="outlined"
          href={AFTER_VERIFY_URL}>
          {FINISH_TEXT}
        </Button>
      </Grid>
      <br/>
      <FooterComponent showPrevious={false}
                       showNext={true}
                       isLastStep={true}
                       nextTestId={"verified_next_test_id"}
                       onNext={handleNext}
                       isHalfWidth/>
    </Grid>
  );
};

UserVerified.propTypes ={
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(UserVerified);

