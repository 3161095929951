import * as animationData from "./animations/PreCaptureInstruction.json";
import Lottie from "react-lottie";
import React from "react";

export const PreLiveCaptureAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return <Lottie options={defaultOptions} height={510} width={412}/>;
};
