export const PICTURE_UPLOAD_HEADER = "Upload your ID";

export const HOW_TO_TAKE_PHOTO_OF_ID = "How to take a photo of your ID";

export const EXAMPLE_OF_HOW_TO_TAKE_PHOTO_OF_ID = "Below are examples of how best to take the photos";

export const HOW_TO_TAKE_PHOTO_OF_ID_STEPS = [
  "Place the ID in your hand or on a contrasting background to take the photo",
  "Ensure the photo is not blurry and is clearly visible",
  "Eliminate any glare, smudges, or shadow on the ID",
  "Ensure all four (4) corners are visible",
  "Take the photo in a well-lit area",
];

export const DOCUMENT_FRONT = "Front of ID";
export const DOCUMENT_BACK = "Back of ID";

export const CORRECT_PHOTO_SPECS = [
    "No glare",
    "No shadows",
    "No smudges",
    "All 4 corners are visible",
  ];

export const INCORRECT_PHOTO_SPECS = [
    "Glare",
    "All 4 corners are not visible​",
  ];
