import React from "react";
import * as constants from "../../constants";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import styles from "./styles/PictureUploadStyles";
import store from "../../store/store";
import CorrectDocFrontExample from "./assets/CorrectDocFrontExample.png";
import IncorrectDocFrontExample from "./assets/IncorrectDocFrontExample.png";
import CorrectDocBackExample from "./assets/CorrectDocBackExample.png";
import IncorrectDocBackExample from "./assets/IncorrectDocBackExample.png";
import {
  clearDocumentFiles,
  clearDocumentUploadErrors,
  decrementActiveStepDocumentUpload,
  incrementActiveStepDocumentUpload,
  setDocumentBack,
  setDocumentBackError,
  setDocumentFront,
  setDocumentFrontError,
} from "../../store/reducers/userProgress";
import { useSelector } from "react-redux";
import PhotoSection from "../commons/PhotoSection";
import CreditCardFront from "./assets/CreditCardFront.svg"
import CreditCardBack from "./assets/CreditCardBack.svg"
import DocumentUploadInstructions from "./DocumentUploadInstructions";
import ShowNeedHelpInUploadingPhotos from "../commons/ShowNeedHelpInUploadingPhotos";
import {logMessage} from "../../store/api/logMessage";
import LogUtil from "../../logger/logUtil";
import {SOURCE_ANALYTICS} from "../../logger/logConstants";

const HowToTakePictureOfID = () => {
  const classes = styles();

  return constants.HOW_TO_TAKE_PHOTO_OF_ID_STEPS.map((item, index) => {
    return (
      <Typography key={index}>
        <li key={index} className={classes.ol}>
          {item}
        </li>
      </Typography>
    );
  });
};

const DocumentPictureSelection = ({ idType }) => {
  const classes = styles();

  const documentSelected = useSelector((state) => state.userProgress.uploadDocument[idType].documentType);
  const documentFrontUploaded = useSelector(
    (state) => state.userProgress.uploadDocument[idType].documentFront
  );
  const documentBackUploaded = useSelector(
    (state) => state.userProgress.uploadDocument[idType].documentBack
  );

  const isPassportSelected = () => {
    return (documentSelected === constants.PASSPORT_OPTION_VALUE);
  }

  const enableNext = () => {
    return isPassportSelected() ? documentFrontUploaded.file :
      (documentFrontUploaded.file && documentBackUploaded.file);
  }

  const handleDifferentIdSelection = () => {
    store.dispatch(clearDocumentFiles({ idType: idType }));
    handlePrev();
  };

  const handlePrev = () => {
    store.dispatch(clearDocumentUploadErrors({ idType: idType }));
    store.dispatch(decrementActiveStepDocumentUpload({ idType: idType }));
  };

  const handleNext = () => {
    store.dispatch(incrementActiveStepDocumentUpload({ idType: idType }));
    store.dispatch(logMessage(SOURCE_ANALYTICS, LogUtil.INFORMATION, LogUtil.getDocumentUploadLog()));
  };

  const documentFrontSectionProps = {
    uploadHeader: "Front photo",
    uploadIcon: CreditCardFront,
    sectionInstructions: <DocumentUploadInstructions
      documentCorrectExample={CorrectDocFrontExample}
      documentIncorrectExample={IncorrectDocFrontExample}
      instructionsHeader= {constants.DOCUMENT_FRONT} />,
    photoStateSelector: (state) => state.userProgress.uploadDocument[idType].documentFront,
    photoErrorStateSelector: (state) => state.userProgress.uploadDocument[idType].documentFrontError,
    setPhotosAction: setDocumentFront,
    setPhotoUploadErrorAction: setDocumentFrontError,
    idType: idType,
    showPhotoSelection: true,
  };

  const documentBackSectionProps = {
    uploadHeader: "Back photo",
    uploadIcon: CreditCardBack,
    sectionInstructions: <DocumentUploadInstructions
      documentCorrectExample={CorrectDocBackExample}
      documentIncorrectExample={IncorrectDocBackExample}
      instructionsHeader={constants.DOCUMENT_BACK} />,
    photoStateSelector: (state) => state.userProgress.uploadDocument[idType].documentBack,
    photoErrorStateSelector: (state) => state.userProgress.uploadDocument[idType].documentBackError,
    setPhotosAction: setDocumentBack,
    setPhotoUploadErrorAction: setDocumentBackError,
    idType: idType,
    showPhotoSelection: true,
  };

  return (
    <Grid container direction="column" alignItems="flex-start">
      <Grid item>
        <Typography
          variant="h5"
          data-testid="upload-picture-header"
          className={classes.header}>
          <b>{constants.PICTURE_UPLOAD_HEADER}</b>
          <b className={classes.required}>{" *"}</b>
        </Typography>
      </Grid>
      <Grid item container data-testid="how-to-take-picture-of-id-section" className={classes.howToSection}>
        <Typography data-testid="how-to-take-picture-of-id-description">
          <b>{constants.HOW_TO_TAKE_PHOTO_OF_ID}</b>
        </Typography>
        <Grid item xs={12}>
          <ol
            data-testid="how-to-take-picture-of-id-steps"
            className={classes.ol}>
            <HowToTakePictureOfID />
          </ol>
        </Grid>
      </Grid>
      <Container maxWidth="xl" spacing={2} className={classes.uploadSection}>
        <Typography data-testid="how-to-take-picture-of-id-example-header">
          <b>{constants.EXAMPLE_OF_HOW_TO_TAKE_PHOTO_OF_ID}</b>
        </Typography>
        <br/>
        <Grid item container data-testid="front-photo-section" className={classes.photoSection}>
          <Grid
            item
            container
            direction="column"
            data-testid="front-photo-specs">
            <PhotoSection {...documentFrontSectionProps} />
          </Grid>
        </Grid>
        {!isPassportSelected() && (
          <Grid item container data-testid="back-photo-section">
            <Grid
              item
              container
              direction="column"
              data-testid="back-photo-specs">
              <PhotoSection {...documentBackSectionProps} />
            </Grid>
          </Grid>)}
      </Container>
      <Grid item container direction="column" className={classes.selectDifferentIDButton}>
        <Grid item>
          <Button
            data-testid="upload-picture-select-different-id-button"
            variant="outlined"
            color="primary"
            onClick={handleDifferentIdSelection}>
            Select different ID
          </Button>
        </Grid>
      </Grid>
      <ShowNeedHelpInUploadingPhotos />
      <Grid item container justifyContent="space-between" className={classes.navigationButtons}>
        <Grid item>
          <Button
            data-testid="upload-picture-prev-button"
            variant="outlined"
            color="primary"
            onClick={handlePrev}>
            Previous
          </Button>
        </Grid>
        <Grid item>
          <Button
            data-testid="upload-picture-next-button"
            variant="contained"
            color="primary"
            disabled={!enableNext()}
            onClick={handleNext}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DocumentPictureSelection;
