import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  ADEQUATE_LOA,
  INADEQUATE_LOA,
  POLLING_LOA_STATUS,
  POLLING_LOA_STATUS_FAILED,
} from "../../store/reducers/StoreConstant";
import MessageComponent from "../commons/components/MessageComponent";
import {
  INADEQUATE_LOA_DESCRIPTION,
  INADEQUATE_LOA_FINAL_STATUS,
  INADEQUATE_LOA_TITLE,
  POLLING_LOA_STATUS_DESCRIPTION,
  POLLING_LOA_STATUS_TITLE,
  INADEQUATE_LOA_FINAL_DESCRIPTION
} from "../../constants/identityStatus";
import { pollingLOAStatus } from "../../store/api/pollingLOAStatus";
import { incrementActiveStep, setSecondaryDocumentUploadRequired } from "../../store/reducers/userProgress";
import FooterComponent from "../commons/components/FooterComponent";
import {
  pollingLOAStatusFailure,
  pollingLOAStatusSuccessful,
  resetDocumentUploadData,
  resetLoaStatus, setPollingStatus
} from "../../store/reducers/userDetails";
import * as constants from "../../constants";
import poll from "../commons/poll";
import { loaStatusTimeout, loaStatusVerifiedOrNotVerified } from "../commons/Validators";
import store from "../../store/store";
import UserVerified from "../UserVerified/UserVerified";
import config from "../../config/config";

const IdentityStatus = () => {
  const dispatch = useDispatch();
  const progress = useSelector((state) => state.userDetails.apiData.LOA.progress);
  const polling = useSelector((state) => state.userDetails.apiStatus.polling);
  const idType = useSelector((state) => state.userProgress.idType);
  const validationParameterSuccess = {
    validate: loaStatusVerifiedOrNotVerified,
    validatorFunction: pollingLOAStatusSuccessful
  }
  const validationParameterFailure = {
    validate: loaStatusTimeout,
    validatorFunction: pollingLOAStatusFailure
  }
  const getCurrentComponent = (step) => {
    let titleText;
    let descriptionText;
    let isPollingLoa;
    let isWarning;
    let isFailure;
    let invalidOrNotVerifiedDocument;
    switch (step) {
      case POLLING_LOA_STATUS:
        if (polling === false) {
          dispatch(setPollingStatus(true));
          poll(pollingLOAStatus(), [validationParameterSuccess, validationParameterFailure], idType,
            Number(config.httpRequestParams.pollingTimeout))
            .then((responseData) => {
              dispatch(responseData.executeFunc(responseData.data))
            })
            .catch((errorStatus) => {
              dispatch(pollingLOAStatusFailure(errorStatus))
            })
        }
        titleText = POLLING_LOA_STATUS_TITLE;
        descriptionText = POLLING_LOA_STATUS_DESCRIPTION;
        isPollingLoa = true;
        break;
      case INADEQUATE_LOA:
        if (store.getState().userProgress.idType === constants.SECONDARY_ID) {
          titleText = INADEQUATE_LOA_FINAL_STATUS;
          descriptionText = INADEQUATE_LOA_FINAL_DESCRIPTION;
          invalidOrNotVerifiedDocument = true;
          isFailure = true;
          break;
        } else {
          titleText = INADEQUATE_LOA_TITLE;
          descriptionText = INADEQUATE_LOA_DESCRIPTION;
          isWarning = true;
          break;
        }
      case ADEQUATE_LOA:
        if (store.getState().userProgress.idType === constants.SECONDARY_ID)
          return <UserVerified/>;
        else
          dispatch(incrementActiveStep());
        break;
      case POLLING_LOA_STATUS_FAILED:
        titleText = constants.UNABLE_TO_REACH_IDENTITY_SERVICE;
        isFailure = true;
        break;
      default:
        titleText = "Coming soon...";
        break;
    }
    return (
      <MessageComponent
        titleText={titleText}
        isFailure={isFailure}
        invalidOrNotVerifiedDocument={invalidOrNotVerifiedDocument}
        descriptionText={descriptionText}
        isPollingLoa={isPollingLoa}
        isWarning={isWarning}
      />)
  }

  const handleNext = () => {
    dispatch(resetLoaStatus());
    dispatch(incrementActiveStep());
    dispatch(resetDocumentUploadData());
  };

  const handleTryAgain = () => {
    dispatch(resetLoaStatus());
  };

  useEffect(() => {
    if (progress === INADEQUATE_LOA) {
      dispatch(setSecondaryDocumentUploadRequired(true));
    }
  });

  return (
    <Grid container direction="column" alignItems="flex-start" data-testid="identityStatus">
      {getCurrentComponent(progress)}
      <FooterComponent showPrevious={false}
                       showNext={(progress === INADEQUATE_LOA && store.getState().userProgress.idType !== constants.SECONDARY_ID)}
                       onNext={handleNext} nextTestId={"inadequate_loa_level"}
                       onTryAgain={handleTryAgain}
                       showTryAgain={(progress === POLLING_LOA_STATUS_FAILED)}

                       tryAgainTestId={"polling_loa_failed"}
                       isHalfWidth/>
    </Grid>
  );
};

export default IdentityStatus;
