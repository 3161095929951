import React from "react";
import {
  FETCH_LOA_STEPPER_LABEL, LIVE_CAPTURE_INSTRUCTION_STEPPER_LABEL, LIVE_CAPTURE_VERIFICATION_STEPPER_LABEL,
  PORTRAIT_SELECTION_HEADER,
  PORTRAIT_SELECTION_UPLOAD_HEADER,
  SELECT_PORTRAIT_STEPPER_LABEL,
  UPLOAD_PORTRAIT_STEPPER_LABEL,
} from "../../constants";
import {
  incrementActiveStepPortraitUpload,
  setPortraitFile,
  setPortraitUploadError,
} from "../../store/reducers/userProgress";
import InternalStepper from "../commons/InternalStepper";
import { Grid } from "@material-ui/core";
import PhotosSelection from "./PhotosSelection";
import { useDispatch, useSelector } from "react-redux";
import PhotoSection from "../commons/PhotoSection";
import PortraitIcon from "./assets/Portrait.svg";
import PortraitUploadInstructions from "./PortraitUploadInstructions";
import PortraitUploadVerification from "./PortraitUploadVerification";
import IdentityStatus from "./IdentityStatus";
import { isSupportedEnvironment } from "../liveCapture/LiveCaptureHandler";
import { LiveCapture } from "../liveCapture/LiveCapture";
import LiveCaptureVerification from "../liveCapture/LiveCaptureVerification";
import {isMobile} from 'react-device-detect';

const UploadPortrait = () => {
  const dispatch = useDispatch();

  const portraitUploadSectionProps = {
    setPhotoUploadErrorAction: setPortraitUploadError,
    uploadHeader: PORTRAIT_SELECTION_UPLOAD_HEADER,
    photoStateSelector: (state) => state.userProgress.uploadPortrait.photo,
    photoErrorStateSelector: (state) => state.userProgress.uploadPortrait.uploadError,
    setPhotosAction: setPortraitFile,
    uploadIcon: PortraitIcon,
    showLivenessSelection: isSupportedEnvironment(),
    sectionInstructions: <PortraitUploadInstructions/>,
    showPhotoSelection: !isMobile,
  };

  const portraitSelectionProps = {
    headerText: PORTRAIT_SELECTION_HEADER,
    mandatoryFileStateSelector: (state) =>
      state.userProgress.uploadPortrait.photo.file,
    handleNext: () => {
      dispatch(incrementActiveStepPortraitUpload());
    },
    showPhotoSelection: !isMobile,
  };

  const isLiveCaptureEnabled = useSelector((state) => state.userProgress.liveCapture.start);

  const staticPortraitSteps = [
    {
      label: SELECT_PORTRAIT_STEPPER_LABEL,
      content:
        <PhotosSelection {...portraitSelectionProps}>
          <PhotoSection {...portraitUploadSectionProps} />
        </PhotosSelection>,
    },
    {
      label: UPLOAD_PORTRAIT_STEPPER_LABEL,
      content: <PortraitUploadVerification/>,
    },
    {
      label: FETCH_LOA_STEPPER_LABEL,
      content: <IdentityStatus/>,
    },
    {
      label: "Coming soon",
      content: "Coming soon...",
    },
  ];

  const liveCapturePortraitSteps = [
    {
      label: LIVE_CAPTURE_INSTRUCTION_STEPPER_LABEL,
      content: <LiveCapture/>,
    },
    {
      label: LIVE_CAPTURE_VERIFICATION_STEPPER_LABEL,
      content: <LiveCaptureVerification/>,
    },
    {
      label: FETCH_LOA_STEPPER_LABEL,
      content: <IdentityStatus/>,
    },
    {
      label: "Coming soon",
      content: "Coming soon...",
    },
  ];

  return (
    <Grid item container>
      {isLiveCaptureEnabled ?
        <InternalStepper
        steps={liveCapturePortraitSteps}
        activeStepStateSelector={(state) =>
          state.userProgress.liveCapture.activeStep
        }
      /> :
        <InternalStepper
        steps={staticPortraitSteps}
        activeStepStateSelector={(state) =>
          state.userProgress.uploadPortrait.activeStep
        }
      />}
    </Grid>
  );
};

export default UploadPortrait;
