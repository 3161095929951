import React from "react";
import {Button, Grid} from "@material-ui/core";
import PropTypes from "prop-types";

const FooterComponent =
    ({
         showPrevious,
         showNext,
         previousTestId,
         nextTestId,
         onNext,
         onPrevious,
         isHalfWidth,
         disableNext,
         showTryAgain,
         onTryAgain,
         isLastStep,
         tryAgainTestId,
     }) => {
        const mediumWidth = isHalfWidth ? 6 : 12;

        return (
            <Grid item container justifyContent="space-between" md={mediumWidth}>
                {onPrevious && showPrevious &&
                <Grid item>
                    {
                        <Button
                            data-testid={previousTestId}
                            variant="outlined"
                            color="primary"
                            onClick={onPrevious}>
                            Previous
                        </Button>
                    }
                </Grid>}
                <Grid item>
                    {
                        onTryAgain && showTryAgain &&
                        <Button
                            data-testid={tryAgainTestId}
                            variant="outlined"
                            color="primary"
                            onClick={onTryAgain}>
                            Try Again
                        </Button>
                    }
                </Grid>
                <Grid item>
                    {
                        onNext && showNext && !isLastStep &&
                        <Button
                            data-testid={nextTestId}
                            variant="contained"
                            color="primary"
                            onClick={onNext}
                            disabled={disableNext}>
                            Next
                        </Button>
                    }
                </Grid>
            </Grid>
        );
    };

FooterComponent.propTypes = {
    showPrevious: PropTypes.bool,
    onPrevious: PropTypes.func,
    showNext: PropTypes.bool,
    onNext: PropTypes.func,
    previousTestId: PropTypes.string,
    nextTestId: PropTypes.string,
    isHalfWidth: PropTypes.bool,
    disableNext: PropTypes.bool,
    showTryAgain: PropTypes.bool,
    onTryAgain: PropTypes.func,
    tryAgainTestId: PropTypes.string,
    isLastStep: PropTypes.bool,
};
export default FooterComponent;
