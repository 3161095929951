import Firefox from "../commons/assets/FirefoxBrowserLogo.svg";
import Safari from "../commons/assets/SafariBrowserLogo.svg";
import Chrome from "../commons/assets/ChromeBrowserLogo.svg";
import Opera from "../commons/assets/OperaBrowserLogo.svg";
import SamsungInternet from "../commons/assets/SamsungInternetBrowserLogo.svg";

const SupportedBrowsers = {
  android:[
    {
      name:"Chrome",
      version:"57+",
      logo: Chrome
    },
    {
      name:"Firefox",
      version:"52+",
      logo: Firefox
    },
    {
      name:"Opera",
      version:"47+",
      logo: Opera
    },
    {
      name:"Samsung Internet",
      version:"9+",
      logo: SamsungInternet
    },
  ],
  iOS:[
    {
      name:"Safari",
      version:"11+",
      logo: Safari
    }
  ]

}

export default SupportedBrowsers;
