import React, { useEffect } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import store from "../store/store";
import { setProtected } from "../store/reducers/routes";
import { Spinner } from "./commons";
import Login from "./Login";

const ProtectedRoute = ({ component }) => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const Component = component;

  useEffect(() => {
    store.dispatch(setProtected(true))
  }, []);

  const content = () => {
    if (isAuthenticated) {
      return <Component />;
    }

    if (inProgress === InteractionStatus.StartUp || inProgress === InteractionStatus.Logout) {
      return <Spinner data-testid="protected-spinner" />;
    }

    return <Login />;
  };

  return content();
};

export default ProtectedRoute;
