import { useDispatch, useSelector } from "react-redux";
import FileUploadStyles from "./styles/FileUploadStyles";
import { Button, Card, CardActions, CardMedia, Grid, Typography, withStyles } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React from "react";
import PropTypes from "prop-types";

const PreviewPhoto =
  ({
     uploadHeader,
     photoStateSelector,
     setPhotosAction,
     idType,
     classes,
   }) => {
    const dispatch = useDispatch();

    const handleUploadDelete = () => {
      dispatch(setPhotosAction({ idType: idType, fileData: { file: null, preview: "" } }));
    };

    const file = useSelector(photoStateSelector);

    return (
      <Grid item container spacing={2} direction="column"
            justifyContent="center"
            alignItems="center" className={classes.content}>
        <Grid item style={{ paddingLeft: "0px" }}>
          <Typography data-testid="front-photo-preview" className={classes.uploadHeader}>
            <b>{uploadHeader}</b>
          </Typography>
        </Grid>
        <Grid>
          <Card classes={{
            root: classes.previewImage
          }}>
            <CardMedia
              component="img"
              alt="Document Preview"
              src={file.preview}
            />
            <CardActions className={classes.deleteAction}>
              <Button
                className={classes.deleteButton}
                variant="outlined"
                startIcon={<Delete />}
                onClick={handleUploadDelete}
                data-testid="delete-preview-image"
              >
                Delete
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    );
  };

PreviewPhoto.propTypes = {
  uploadHeader: PropTypes.string.isRequired,
  photoStateSelector: PropTypes.func.isRequired,
  setPhotosAction: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(FileUploadStyles, { withTheme: true })(PreviewPhoto);
