import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import * as constants from "../../constants";
import PropTypes from "prop-types";
import greenTick from "./assets/GreenTick.svg";
import incorrectIcon from "./assets/IncorrectIcon.svg";
import _ from "lodash";

const DocumentPhotoSpecs = ({ documentExample, specs, isCorrectExample }) => {
  const styles = makeStyles(() => ({
    correct: {
      border: "3px solid #50AF05",
    },
    incorrect: {
      border: "3px solid #BC0B00",
    },
    instructions: {
      width: "203px",
    },
  }));

  const correctnessProps = isCorrectExample ?
    { bulletingIcon: greenTick, imageStyle: "correct" } :
    { bulletingIcon: incorrectIcon, imageStyle: "incorrect" };

  const classes = styles();

  return (
    <Grid item container spacing={2}>
      <Grid item>
        <img
          src={documentExample}
          width="130"
          height="108"
          alt="correct example"
          className={classes[correctnessProps.imageStyle]}
        />
      </Grid>
      <Grid item className={classes.instructions}>
        {
          _.map(specs, (spec, index) => {
            return (
              <Typography variant="body2" key={index}>
                <span role="img" aria-label="correct photo spec">
                  <img src={correctnessProps.bulletingIcon} alt="." /> {spec}
                </span>
              </Typography>
            );
          })
        }
      </Grid>
    </Grid>
  );
};

const DocumentUploadInstructions =
  ({
     instructionsHeader,
     documentCorrectExample,
     documentIncorrectExample,
   }) => {
    return (
      <Grid item container direction="column" spacing={2}>
        <Grid item xs={12}>
          <Typography data-testid="front-photo-eg-text">
            <b>{instructionsHeader}</b>
          </Typography>
        </Grid>
        <DocumentPhotoSpecs
          isCorrectExample={true}
          documentExample={documentCorrectExample}
          specs={constants.CORRECT_PHOTO_SPECS}
        />
        <DocumentPhotoSpecs
          isCorrectExample={false}
          documentExample={documentIncorrectExample}
          specs={constants.INCORRECT_PHOTO_SPECS}
        />
      </Grid>
    );
  };

DocumentUploadInstructions.propTypes = {
  instructionsHeader: PropTypes.string.isRequired,
  documentCorrectExample: PropTypes.string.isRequired,
  documentIncorrectExample: PropTypes.string.isRequired,
};

DocumentPhotoSpecs.propTypes = {
  documentExample: PropTypes.string.isRequired,
  specs: PropTypes.array.isRequired,
  isCorrectExample: PropTypes.bool.isRequired,
};

export default DocumentUploadInstructions;
