import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  protected: false
}
const routes = createSlice({
  name: "routes",
  initialState: initialState,
  reducers: {
    setProtected: {
      reducer: (state, action) => {
        state.protected = action.payload; //NOSONAR
        return state;
      }
      /*prepare: (protected) => { //NOSONAR
        return {payload: protected};
      }*/
    }
  }
});

export const {setProtected} = routes.actions;

export default routes.reducer;