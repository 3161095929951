import { combineReducers } from "redux";
import userProgress from "./reducers/userProgress";
import userDetails from "./reducers/userDetails";
import routes from "./reducers/routes"

const rootReducer = combineReducers({
  userProgress,
  userDetails,
  routes,
});

export default rootReducer;
