import config from "../../config/config";
import axios from "axios";
import { loginRequest } from "../../authConfig";
import store from "../store";
import { isUnauthorized } from "../reducers/userDetails";
import { msalInstance } from "../../App";

const publicIp = require("react-public-ip");

export const getAccessToken = async () => {
  try {
    const account = msalInstance.getActiveAccount();

    if(!account) {
      throw new Error("No active account");
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account
    });

    return response.accessToken;
  } catch (error) {
    store.dispatch(isUnauthorized());
    throw error;
  }
};

export const postRequest = async (url, body, contentType) => {
  const verificationToken = store.getState().userDetails.verificationToken;
  const forwardedFor = await publicIp.v4() || "";
  const accessToken = await getAccessToken();

  const instance = axios.create({
    baseURL: config.api.baseUrl,
    headers: {
      'Content-Type': contentType,
      'Authorization': `Bearer ${accessToken}`,
      'X-Forwarded-For': forwardedFor,
      'VERIFICATION_TOKEN': verificationToken,
      'X-Tenant': sessionStorage.getItem('agency')
    }
  });

  try {
    return await instance.post(url, body);
  } catch (error) {
    if (error.response.status === 401) {
      store.dispatch(isUnauthorized())
    } else {
      throw error;
    }
  }
};

export const getRequest = async (url) => {
  const verificationToken = store.getState().userDetails.verificationToken;
  const forwardedFor = await publicIp.v4() || "";
  const accessToken = await getAccessToken();

  const instance = axios.create({
    baseURL: config.api.baseUrl,
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'X-Forwarded-For': forwardedFor,
      'VERIFICATION_TOKEN': verificationToken,
      'X-Tenant': sessionStorage.getItem('agency')
    }
  });

  try {
    return await instance.get(url);
  } catch (error) {
    if (error.response.status === 401) {
      store.dispatch(isUnauthorized())
    } else {
      throw error;
    }
  }
};
