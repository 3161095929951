import React, { useState } from "react";
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography, withStyles } from "@material-ui/core";
import * as constants from "../../constants";
import styles from "./styles/UploadStyles";
import { useSelector } from "react-redux";
import {
  decrementActiveStep,
  decrementActiveStepDocumentUpload,
  incrementActiveStepDocumentUpload,
  setDocumentType,
  setEnableDocumentUpload,
} from "../../store/reducers/userProgress";
import store from "../../store/store";
import FooterComponent from "../commons/components/FooterComponent";
import { logMessage } from "../../store/api/logMessage";
import * as logConstants from "../../logger/logConstants";
import LogUtil from "../../logger/logUtil";
import PropTypes from "prop-types";

const IDSelection = ({ idType, classes }) => {
  let value;

  const primaryIdSelected = useSelector((state) => state.userProgress.uploadDocument.primaryId.documentType);
  const [primaryID, setPrimaryID] = useState(
    useSelector((state) => state.userProgress.uploadDocument[idType].documentType)
  );

  const enableNext = useSelector((state) => state.userProgress.uploadDocument[idType].documentType) !== "";

  const isSecondaryId = (idType === constants.SECONDARY_ID);

  const onPrimaryIDSelected = (event) => {
    value = event.target.value;
    setPrimaryID(value);
    store.dispatch(setDocumentType({
      idType: idType,
      documentType: value,
    }));
  };

  const handleNext = () => {
    store.dispatch(incrementActiveStepDocumentUpload({ idType: idType }));
    store.dispatch(logMessage(logConstants.SOURCE_ANALYTICS, LogUtil.INFORMATION, LogUtil.getIdSelectionLog()));
  };

  const handlePrevious = () => {
    store.dispatch(setDocumentType({
      idType: idType,
      documentType: "",
    }));
    store.dispatch(decrementActiveStepDocumentUpload({ idType: idType }));
    store.dispatch(decrementActiveStep());
    store.dispatch(setEnableDocumentUpload(true));
  };

  const customiseRadioLabelsForDL = (text, index) => {
    let coloredText = text.split("from")[0];
    let uncoloredText = text.split("from")[1];
    return (
      <div className={classes.responsiveText}>
        <span
          className={classes.radioTextColored}
          data-testid={"colored-text" + index}>
          {coloredText}
        </span>
        <span
          className={classes.radioTextUncolored}
          data-testid={"uncolored-text" + index}>
          {"from" + uncoloredText}
        </span>
      </div>
    );
  };

  const customiseRadioLabelsForIdentificationCard = (text, index) => {
    let coloredText = text.split("from")[0];
    let uncoloredText = text.split("from")[1];
    return (
      <div className={classes.responsiveTextID}>
        <span
          className={classes.radioTextColored}
          data-testid={"colored-text" + index}>
          {coloredText}
        </span>
        <span
          className={classes.radioTextUncolored}
          data-testid={"uncolored-text" + index}>
          {"from" + uncoloredText}
        </span>
      </div>
    );
  };

  const customiseRadioLabels = (text, index) => {
    return (
      <span
        className={classes.radioTextColored}
        data-testid={"colored-text" + index}>
        {text}
      </span>
    );
  };

  const DrivingLicenseOption = () => {
    return (<>
      <FormControlLabel
        key="primary-id-options-0"
        data-testid="primary-id-options-0"
        value={constants.DL_OPTION_VALUE}
        control={<Radio color="primary" />}
        label={customiseRadioLabelsForDL(constants.DRIVING_LICENCE, "1")}
        className={classes.radioLabelDL}
      />
      <div className={classes.form}>
        <Typography>{constants.DRIVING_LICENCE_VERBOSITY}</Typography>
      </div>
      <h6 className={classes.boldForm}>
        {constants.DRIVING_LICENCE_EXCEPTION}
      </h6></>)
  };

  const IdentityCardOption = () => {
    return (<>
      <FormControlLabel
        key="primary-id-options-1"
        data-testid="primary-id-options-1"
        value={constants.IDENTITY_OPTION_VALUE}
        control={<Radio color="primary" />}
        label={customiseRadioLabelsForIdentificationCard(
          constants.IDENTIFICATION_CARD,
          "2"
        )}
      /></>)
  };

  const ResidentCardOption = () => {
    return (<>
      <FormControlLabel
        key="primary-id-options-2"
        data-testid="primary-id-options-2"
        value={constants.RESIDENT_OPTION_VALUE}
        control={<Radio color="primary" />}
        label={customiseRadioLabels(
          constants.RESIDENT_GREEN_CARD,
          "3"
        )}
      /></>)
  };

  const PassportOption = () => {
    return (<>
      <FormControlLabel
        key="primary-id-options-3"
        data-testid="primary-id-options-3"
        value={constants.PASSPORT_OPTION_VALUE}
        control={<Radio color="primary" />}
        label={customiseRadioLabels(
          constants.PASSPORT,
          "4"
        )}
      /></>)
  };

  const getIds = () => {
    if (idType === constants.SECONDARY_ID) {
      return (<>
        {primaryIdSelected !== constants.DL_OPTION_VALUE && <DrivingLicenseOption />}
        {primaryIdSelected !== constants.IDENTITY_OPTION_VALUE && <IdentityCardOption />}
        {primaryIdSelected !== constants.RESIDENT_OPTION_VALUE && <ResidentCardOption />}
        <PassportOption />
      </>)
    }
    return (<>
      <DrivingLicenseOption />
      <IdentityCardOption />
      <ResidentCardOption /></>)
  };

  return (
    <Grid item container spacing={3}>
      <Grid item>
        <FormControl component="fieldset">
          <Typography
            variant={"h5"}
            data-testid="document-accepted-ids-header"
            className={classes.header}>
            <b>{isSecondaryId ? constants.SECONDARY_ID_SELECTION_QUESTION : constants.PRIMARY_ID_SELECTION_QUESTION}</b>
            <b className={classes.required}>{"  *"}</b>
          </Typography>
          <RadioGroup
            value={primaryID}
            onChange={onPrimaryIDSelected}
            data-testid="radio-group">
            {getIds()}
          </RadioGroup>
        </FormControl>
      </Grid>
      <FooterComponent
        onPrevious={handlePrevious}
        onNext={handleNext}
        showNext
        showPrevious={!isSecondaryId}
        disableNext={!enableNext}
        previousTestId="primary-id-selection-previous-button"
        nextTestId="primary-id-selection-next-button"
      />
    </Grid>
  );
};

IDSelection.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(IDSelection);

