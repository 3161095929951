
export const POLLING_LOA_STATUS_TITLE = "Please wait while we compare your photos and verify your identity.";

export const INADEQUATE_LOA_TITLE = "Your identity could not be verified based on the photos provided.";

export const POLLING_LOA_STATUS_DESCRIPTION = "This may take up to 60 seconds.";

export const INADEQUATE_LOA_DESCRIPTION = "Please upload an additional ID.";

export const INADEQUATE_LOA_FINAL_STATUS = "We were not able to verify your identity based on the photos provided.";

export const INADEQUATE_LOA_FINAL_DESCRIPTION = "Please try again with new photos, or verify your identity in person.";
