import { Grid, withStyles } from "@material-ui/core";
import styles from "./styles/unsupportedBrowser";
import OKLogo from "../commons/assets/OKLogo.png";
import React from "react";
import Typography from "@material-ui/core/Typography";

const UnsupportedOS = ({ classes }) => {

  return (
    <>
      <Grid item container direction="row" justifyContent="center" alignItems="center"
            className={classes.logoContainer}>
        <Grid item className={classes.logoContainerItem}>
          <img width="250" height="50" src={OKLogo} title="Oklahoma Employment Security Commission"
               alt="Oklahoma Employment Security Commission" data-testid="OKLogo"/>
        </Grid>
      </Grid>
      <Grid item className={classes.browserNotSupportedOnDeviceGridItem}>
        <Typography data-testid="unsupported-os-header">
          Your OS is not supported for use with this application.
        </Typography>
      </Grid>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(UnsupportedOS);
