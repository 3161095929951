import React from "react";
import { useSelector } from "react-redux";
import styles from "./styles/liveCaptureRecenterFaceOutline";
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import recenterFaceOutlineWhitePng from "./assets/recenter_face_outline_white.png";
import Grid from "@material-ui/core/Grid";

const LiveCaptureRecenterFaceOutline = ({ classes }) => {
  const isLiveCaptureRecenterFaceOutlineEnabled = useSelector(
    (state) =>
      state.userProgress.liveCapture.isLiveCaptureRecenterFaceOutlineEnabled
  );
  const isStreaming = useSelector((state) => state.userProgress.liveCapture.isStreaming);

  return (
    <>
      {isStreaming && isLiveCaptureRecenterFaceOutlineEnabled && (
        <Grid item container justifyContent="center">
          <img
            id="recenter-face-outline-white"
            data-testid="recenter-face-outline-white"
            src={recenterFaceOutlineWhitePng}
            className={classes.videoOverlay}
            alt="recenter face outline white"
          />
        </Grid>
      )}
    </>
  );
};

LiveCaptureRecenterFaceOutline.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(
  LiveCaptureRecenterFaceOutline
);
