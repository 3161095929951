import React from 'react';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import {
  UnprotectedRoute,
  ProtectedRoute,
  Login,
  Logout,
  Timeout,
  Verify
} from "./components";
import StoreReturnURL from "./components/StoreReturnURL";
import { msalConfig } from "./authConfig";
import theme from "./theme";
import "./App.css";

export let msalInstance = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount()) {
  const accounts = msalInstance.getAllAccounts();
  if (accounts && accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MsalProvider instance={msalInstance}>
        <StoreReturnURL />
        <Switch>
          <Route exact path="/">
            <Redirect to={{ pathname: "/login" }} />
          </Route>
          <ProtectedRoute path="/logout" component={Logout} />
          <UnprotectedRoute exact path="/login" component={Login}/>
          <UnprotectedRoute exact path="/timeout" component={Timeout}/>
          <ProtectedRoute path="/verification" component={Verify} />
        </Switch>
      </MsalProvider>
    </ThemeProvider>
  );
}

export default App;
