import React from "react";
import {Button,Container} from "@material-ui/core";
import styles from "../commons/styles/HeaderStyles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import * as constants from "../../constants";
import {Navbar,Nav} from 'react-bootstrap';
import OKDHSLogo from './assets/OKDHSLogo.png';
import { useHistory } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

export const HeaderDHS = () => {
  const classes = styles();
  const logoutLinkClasses = `${classes.navLink} ${classes.logoutLink}`;
  const isAuthenticated = useIsAuthenticated();
  let history = useHistory();

  console.log("DHS header in action");

  const logout = () => {
    history.push('/logout');
  };

  return (
    <div id="header" className={classes.header}>
      <Container className={classes.container}>
        <Navbar collapseOnSelect expand="lg" className={classes.navbar}>
          <Navbar.Brand href="https://ui.ok.gov/en" className={classes.navbarBrand}>
            <img width="250" height="50" src={OKDHSLogo} title="Oklahoma Department of Human Services" alt="Oklahoma Department of Human Services" data-testid="OKLogo"/>
          </Navbar.Brand>
          <Navbar.Toggle data-testid="navbar-toggler-icon" className={classes.navbarToggler} aria-controls="navbar-responsive-collapse" />
            <Navbar.Collapse id="navbar-responsive-collapse">
             <Nav className={classes.navbarNav}>
               <Nav.Link href= {constants.CONTACT_US_DHS_LINK} className={classes.navLink}>
                 <span>{constants.CONTACT}</span>
               </Nav.Link>
               <Nav.Link href= {constants.ABOUT_DHS_LINK} className={classes.navLink}>
                 <span>{constants.ABOUT_DHS}</span>
               </Nav.Link>
               <Nav.Link href= {constants.SERVICES_DHS_LINK} className={classes.navLink}>
                 <span>{constants.SERVICES}</span>
               </Nav.Link>
               { isAuthenticated &&
                <Nav.Link data-testid="logout-link" onClick={logout} className={logoutLinkClasses}>
                  <span>{constants.LOGOUT}</span>
                </Nav.Link>
                }
             </Nav>
             { isAuthenticated &&
              <Button className={classes.logout}
                      onClick={logout}
                      data-testid="logout-button"
                      endIcon={<ExitToAppIcon/>}>
                {constants.LOGOUT}
              </Button>
             }
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </div>
  );
};
