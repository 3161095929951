import React, { useEffect } from "react";
import { StartLiveCapture } from "./LiveCaptureHandler";
import { useSelector } from "react-redux";
import styles from "./styles/liveCaptureVideoSession";
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import LiveCaptureRecenterFaceOutline from "./LiveCaptureRecenterFaceOutline";
import LiveCaptureInstruction from "./LiveCaptureInstruction";

const LiveCaptureVideoSession = ({ classes }) => {
  const liveCaptureSessionId = useSelector(
    (state) => state.userDetails.apiData.liveCapture.sessionId
  );

  useEffect(() => {
    StartLiveCapture(liveCaptureSessionId).then();
  }, [liveCaptureSessionId]);

  return (

    <div
      data-testid="live-capture-video-session"
      className={classes.dimensions}>
      <video
        data-testid="live-capture-video-stream"
        id="user-video"
        className={classes.mirrorImage}
        height="100%"
        width="100%"
        style={{ objectFit: "cover" }}
        autoPlay
        playsInline
      />
      <LiveCaptureRecenterFaceOutline />
      <LiveCaptureInstruction />

    </div>
  );
};

LiveCaptureVideoSession.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(LiveCaptureVideoSession);
