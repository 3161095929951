const FileUploadStyles = (theme) => ({
  previewImage: {
    width: "410px",
    height: "270px",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      width: "82vw",
      height: "175px",
    },
  },
  deleteAction: {
    position: "absolute",
    bottom: "0px",
    backgroundColor: "rgba(0,0,0,0.65)",
    width: "100%",
    padding: "0px",
    justifyContent: "flex-end",
  },
  deleteButton: {
    margin: "12px 21px 12px 0px",
    color: "white",
    borderColor: "white",
  },
  upload: {
    border: "2px dashed rgb(241, 161, 12)",
    width: "410px",
    height: "270px",
    backgroundColor: "#FEF9F7",
    [theme.breakpoints.down("xs")]: {
      border: "2px solid rgb(241, 161, 12)",
      width: "82vw",
      height: "175px",
    },
  },

  input: {
    display: "none",
  },

  uploadButton: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0),
    },
  },

  required: {
    color: "#F00",
  },

  imageFormatInfo: {
    paddingLeft: "17px",
  },

  imageUploadButton: {
    color: "rgb(241, 161, 12)",
    borderColor: "rgb(241, 161, 12)",
    padding: "11px 23px",
  },
  content: {
    [theme.breakpoints.down("xs")]: {
      alignContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      alignItems: "flex-start",
    },
  },
  uploadHeader: {
    [theme.breakpoints.between("xs", "sm")]: {
      marginTop: "20px",
    },
  },
  photoHeader: {
    paddingLeft: "0px",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      justifyContent: "center",
    },
  },
});

export default FileUploadStyles;
